















































import NavItem from './NavItem.vue';
import * as action from '@/store/actions';

import { mainBus } from '@/main';

export default {
  data: () => ({
    menuIconColorLocal: '',
    menuFontColorLocal: '',
  }),
  components: {
    NavItem,
  },
  props: {
    isCollapsed: Boolean,
    items: Array,
    activeSubItem: String,
    computedHover: String,
    vuetifyThemeDark: Boolean,
  },
  methods: {
    handleItemClick(route) {
      this.$emit('itemClick', route);
    },
  },
  async mounted() {
    mainBus.$on(action.RELOAD_LOOK_AND_FEEL, () => {
      this.menuIconColorLocal = this.menuIconColor;
      this.menuFontColorLocal = this.menuFontColor;
    });

    console.log(this.isCollapsed);
    console.log(this.items);
    console.log(this.activeSubItem);
    console.log(this.computedHover);
    console.log(this.vuetifyThemeDark);
  },
};
