import { ActiveCall, PanelState, RootState } from '@/types/store';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Panel from '@/plugins/panelHub';
import { AstQueue, Extension, Queue } from '@/types/conf';
import { ExtenStatus } from '@/types/enums';
import { getElapsedTime } from '@/services/clock';
import store from '@/store';
import * as action from '@/store/actions';
import i18n from '@/plugins/i18n';
import moment from 'moment-timezone';

const statusMapper = (status: any) => {
  let extStatus = ExtenStatus[status];
  return extStatus;
};
const parseExtenToAstQueue = (obj: Extension, queue: any) => {
  let parsedObj: AstQueue = {
    membername: `PJSIP/${store.state.auth.user.corpId}_${obj.number}`,
    _interface: `PJSIP/${store.state.auth.user.corpId}_${obj.number}`,
    penalty: 1,
    paused: 0,
    queue_name: `${store.state.auth.user.corpId}_${queue.id}`,
  };
  return parsedObj;
};

const state: PanelState = {
  extenList: [] as Extension[],
  queueList: [] as Queue[],
  callTimes: [] as { interval: null | number; time: string }[],
  currentCallList: [] as { caller?: string; receiver?: string }[],
  channelList: [],
  callHistory: [],
  dashExtensions: [],

  activeCalls: [] as ActiveCall[],
};
const getters: GetterTree<PanelState, RootState> = {
  extenList(state): Extension[] {
    return state.extenList;
  },
  queueList(state): Queue[] {
    return state.queueList;
  },
  callTimes(state): { interval: null | number; time: string }[] {
    return state.callTimes;
  },
  currentCallList(state): { caller?: string; receiver?: string }[] {
    return state.currentCallList;
  },
  channelList(state): any[] {
    return state.channelList;
  },
  callHistory(state): any[] {
    return state.callHistory;
  },
  dashExtensions(state): any[] {
    return state.dashExtensions;
  },
  getActiveCalls(state): ActiveCall[] {
    return state.activeCalls;
  },
};
const mutations: MutationTree<PanelState> = {
  setDashExtensions(state, payload) {
    state.dashExtensions = payload;
  },
  setExtensionList(state, payload) {
    state.extenList = payload;
  },
  setQueueList(state, payload) {
    state.queueList = payload;
  },
  setChannelList(state, payload) {
    state.channelList = payload;
  },
  setExtenStatus(state, payload) {
    let ext = state.extenList[payload.id];
    if (ext) {
      ext.status = payload.status;
    }
  },
  mapExtenUsers(state, payload) {
    state.extenList.forEach((exten: Extension, index) => {
      const user = payload.find((user: any) => user.id == exten.userId);
      state.extenList[index].userName =
        user?.name || store.state.auth.user.nick;
    });
  },
  addQueueMember(state, payload) {
    state.queueList[payload.queueIndex].objects.push(
      panel.state.extenList[payload.extIndex]
    );
  },
  setQueueMembers(state, payload) {
    state.queueList[payload.id].objects = state.queueList[
      payload.id
    ].objects.filter((e: Extension) => e.number != payload.number);
  },
  setQueueMemberStatus(state, payload) {
    state.queueList.forEach((queue) => {
      const index = queue.objects.findIndex(
        (item: any) => item.number == payload.number
      );
      if (index > -1) {
        queue.objects[index].status = payload.status;
      }
    });
  },
  setInterval(state, payload) {
    state.callTimes[payload.id].interval = payload.interval;
  },
  clearInterval(state, payload) {
    clearInterval(state.callTimes[payload].interval as number);
    state.callTimes[payload].interval = null;
  },
  resetTimeAndStatus(state, payload) {
    state.extenList[payload].status = ExtenStatus.Online;
    state.callTimes[payload].time = '00:00';
  },
  loadCallTimes(state) {
    state.extenList.forEach((e) => {
      state.callTimes.push({ interval: null, time: '00:00' });
    });
  },
  // addActiveCall(state, payload) {
  //   state.currentCallList.push({
  //     caller: payload.caller,
  //     receiver: payload.receiver,
  //   });
  // },
  removeActiveCall(state, payload) {
    state.currentCallList = state.currentCallList.filter((e: any) =>
      /* !Object.values(e).includes(payload.caller.number) && !Object.values(e).includes(payload.receiver.number) */
      (Object.values(e) as Extension[]).forEach((element: Extension) => {
        return (
          element.number != payload.caller.number ||
          element.number != payload.receiver.number
        );
      })
    );
  },
  removeActiveCallTransfer(state, payload) {
    state.currentCallList = state.currentCallList.filter(
      (e: any) => !Object.values(e).includes(payload.caller)
    );
  },
  addCallToHistory(state, payload) {
    state.callHistory.push({
      dateTime: payload.dateTime,
      caller: payload.caller,
      receiver: payload.receiver,
      duration: payload.duration,
    });
    if (state.callHistory.length > 5) {
      state.callHistory.shift();
    }
  },

  addActiveCall(state, payload) {
    state.activeCalls.push({
      corpId: payload.corpId,
      time: payload.time,
      profile: payload.profile,
      callId: payload.callId,
      caller: payload.caller,
      destination: payload.destination,
      dialed: payload.dialed,
    });

    let cache = state.activeCalls;

    // Filtro los elementos duplicados por callId
    state.activeCalls = cache.filter((call, index, self) =>
      index === self.findIndex((c) => (
        c.callId.split('.')[0] == call.callId.split('.')[0]
      ))
    );
  },

  hangupCall(state, payload) {
    state.activeCalls = state.activeCalls.filter(
      (e) => e.callId.split('.')[0] != payload.split('.')[0]
    );
  },
};
const actions: ActionTree<PanelState, RootState> = {
  async initPanel({ commit }) {
    await Panel.start();

    // Load extensions
    await Panel.client
      .invoke('GetAllExtensions', store.state.auth.user.corpId)
      .then(
        await Panel.client.invoke(
          'GetAllChannels',
          store.state.auth.user.corpId
        )
      );
    commit('loadCallTimes');

    // Map users
    let uList = await this.dispatch(
      'crudService/getList',
      action.USER_CONTROLLER
    );

    uList = uList
      ?.filter((user: any) => user.corpId == store.state.auth.user.corpId)
      ?.map((user: any) => ({ id: user.id, name: user.name }));

    if (!!uList) commit('mapExtenUsers', uList);

    // Load channels
    await Panel.client.invoke('GetAllChannels', store.state.auth.user.corpId);

    // Load queues
    let qList = await this.dispatch(
      'crudService/getList',
      action.QUEUE_CONTROLLER
    );

    if (qList?.length > 0) {
      qList = qList.map((queue: Queue) => ({
        ...queue,
        objects: [],
      }));
    }
    commit('setQueueList', qList);

    // Load queue members
    const astQueueMembers: any[] = await this.dispatch(
      'crudService/getList',
      action.AST_QUEUES
    );

    if (astQueueMembers?.length > 0 && qList?.length > 0) {
      astQueueMembers
        .sort((a, b) => {
          if (a.extenNumber < b.extenNumber) {
            return -1;
          }
          if (a.extenNumber > b.extenNumber) {
            return 1;
          }
          return 0;
        })
        .forEach((member: AstQueue) => {
          const queueIndex = panel.state.queueList.findIndex(
            (queue) => queue.id === member.queueId
          );
          const extIndex = panel.state.extenList.findIndex(
            (ext) => ext.number === member.extenNumber
          );
          if (queueIndex > -1 && extIndex > -1) {
            commit('addQueueMember', {
              queueIndex: queueIndex,
              extIndex: extIndex,
            });
          }
        });
    }
  },

  async getAndSetQueues({ commit }) {
    let qList = await this.dispatch(
      'crudService/getList',
      action.QUEUE_CONTROLLER
    );
        
    if (qList?.length > 0) {
      qList = qList.map((queue: Queue) => ({
        ...queue,
        objects: [],
      }));
    }
    commit('setQueueList', qList);

    // Load queue members
    const astQueueMembers: any[] = await this.dispatch(
      'crudService/getList',
      action.AST_QUEUES
    );

    if (astQueueMembers?.length > 0 && qList?.length > 0) {
      astQueueMembers
        .sort((a, b) => {
          if (a.extenNumber < b.extenNumber) {
            return -1;
          }
          if (a.extenNumber > b.extenNumber) {
            return 1;
          }
          return 0;
        })
        .forEach((member: AstQueue) => {
          const queueIndex = panel.state.queueList.findIndex(
            (queue) => queue.id === member.queueId
          );
          const extIndex = panel.state.extenList.findIndex(
            (ext) => ext.number === member.extenNumber
          );
          if (queueIndex > -1 && extIndex > -1) {
            commit('addQueueMember', {
              queueIndex: queueIndex,
              extIndex: extIndex,
            });
          }
        });
    }
  },
  async deleteQueueMember({ commit }, { obj, queueListIndex, datoMisterioso }) {
    const ext = panel.state.queueList[queueListIndex].objects.find(
      (item: Extension) => item.number == obj.number
    );

    if (datoMisterioso && ext != null) {
      await this.dispatch('crudService/deleteObject', {
        action: action.AST_QUEUES,
        id: datoMisterioso,
      }).then(() => {
        commit('setQueueMembers', { id: queueListIndex, number: obj.number });
      });
    }

    if (ext != null) {
      await this.dispatch('crudService/deleteObject', {
        action: action.AST_QUEUES,
        id: ext,
      }).then(() => {
        commit('setQueueMembers', { id: queueListIndex, number: obj.number });
      });
    }
  },
  async addQueueMember({ commit }, { id, item }) {
    let arr: Extension[] = [];

    panel.state.queueList.forEach((queue: any) => {
      arr.push(...queue.objects);
    });

    // condición Innecesaria
    // if (arr.includes(item)) return;

    // condición Innecesaria

    // const condition = !!panel.state.queueList.find((q: Queue) =>
    //   q.objects.find((e: Extension) => e.number == item.number)
    // );

    const condition = true;

    const queue = panel.state.queueList.find((queue) => queue.id === id);

    if (condition) {
      let parsedObj = parseExtenToAstQueue(item, queue);
      await this.dispatch('crudService/newObject', {
        action: action.AST_QUEUES,
        obj: parsedObj,
      }).then(() => {
        const index = panel.state.queueList.findIndex(
          (queue) => queue.id === id
        );
        panel.state.queueList[index].objects.push(item);
      });
    }
  },
  async stopPanel({ commit }) {
    commit('panel/setExtensionList', []);

    await Panel.stop();
  },
};

export const panel = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

Panel.client.on('AllChannels', async (channels: any) => {
  const list = await channels.map((channel: any) => {
    const regExp = /[-_]+/;
    const number = channel.name.split(regExp);
    return {
      id: channel.id,
      number: number[1],
    };
  });
  store.commit('panel/setChannelList', list);
  panel.state.extenList = panel.state.extenList.map(
    (exten: Extension, index) => {
      panel.state.callTimes[index].time =
        list.filter((channel: any) => channel.number == exten.number).length > 0
          ? '--:--'
          : '00:00';
      return {
        ...exten,
        channelList: list.filter(
          (channel: any) => channel.number == exten.number
        ),
        status:
          list.filter((channel: any) => channel.number == exten.number).length >
          0
            ? ExtenStatus.InUse
            : exten.status,
      };
    }
  );
});
Panel.client.on('AllExtensions', async (extensions: any) => {
  const list = await extensions.map((exten: any) => {
    return {
      number: exten.number,
      pass: exten.pass,
      userId: exten.userId,
      trunkId: exten.trunkId,
      outer: exten.outer,
      enabled: exten.enabled,
      action: exten.action,
      onbusy: exten.onbusy,
      description: exten.description,
      spy: exten.spy,
      callLimit: exten.callLimit,
      portType: exten.portType,
      vmpass: exten.vmpass,
      maxContacts: exten.maxContacts,
      callgroup: exten.callgroup,
      channelList: exten.channelList,
      status:
        exten.channelList != null && exten.channelList.length > 0
          ? 2
          : statusMapper(ExtenStatus[exten.state]),
    };
  });
  store.commit('panel/setExtensionList', list);
});
Panel.client.on('Ringing', async (data: any) => {
  if (store.state.auth.user.corpId != data.corpId) return;

  // Change status in exten list
  const indexCaller = panel.state.extenList.findIndex(
    (item) => item.number == data.caller
  );
  const indexReceiver = panel.state.extenList.findIndex(
    (item) => item.number == data.receiver
  );

  if (indexCaller > -1) {
    store.commit('panel/setExtenStatus', {
      id: indexCaller,
      status: ExtenStatus.Ringing,
    });
  }
  if (indexReceiver > -1 && panel.state.extenList[indexReceiver].status > 0) {
    store.commit('panel/setExtenStatus', {
      id: indexReceiver,
      status: ExtenStatus.Ringing,
    });
  }

  // Change status in queue list
  if (panel.state.queueList?.length > 0) {
    store.commit('panel/setQueueMemberStatus', {
      number: data.caller,
      status: ExtenStatus.Ringing,
    });

    if (panel.state.extenList[indexReceiver].status > 0) {
      store.commit('panel/setQueueMemberStatus', {
        number: data.receiver,
        status: ExtenStatus.Ringing,
      });
    }
  }
});
Panel.client.on('Hangup', async (data: any) => {
  store.commit('panel/hangupCall', data.channelId);

  if (store.state.auth.user.corpId != data.corpId) return;

  const indexCaller = panel.state.extenList.findIndex(
    (item) => item.number == data.caller
  );
  const indexReceiver = panel.state.extenList.findIndex(
    (item) => item.number == data.receiver
  );
  // Reset call
  if (indexCaller > -1 || indexReceiver > -1) {
    let index = indexReceiver > -1 ? indexReceiver : indexCaller;
    let time = panel.state.callTimes[index].time.slice();
    store.commit('panel/addCallToHistory', {
      dateTime: new Date().toLocaleString(),
      caller: data.receiver || i18n.t('misc.unknown'),
      receiver: data.caller || i18n.t('misc.unknown'),
      duration: time || '00:00',
    });
  }
  if (indexCaller > -1) {
    store.commit('panel/clearInterval', indexCaller);
    store.commit('panel/resetTimeAndStatus', indexCaller);
  }
  if (indexReceiver > -1) {
    store.commit('panel/clearInterval', indexReceiver);
    store.commit('panel/resetTimeAndStatus', indexReceiver);
  }
  if (indexCaller > -1 && indexReceiver > -1) {
    store.commit('panel/removeActiveCall', {
      caller: panel.state.extenList[indexCaller],
      receiver: panel.state.extenList[indexReceiver],
    });
  } else if (indexCaller > -1) {
    store.commit('panel/removeActiveCallTransfer', {
      caller: panel.state.extenList[indexCaller],
    });
  }
});
Panel.client.on('CallConnected', async (data: any) => {
  if (store.state.auth.user.corpId != data.corpId) return;

  let startTime = new Date();
  // Change status in exten list
  const indexCaller = panel.state.extenList.findIndex(
    (item) => item.number == data.caller
  );
  const indexReceiver = panel.state.extenList.findIndex(
    (item) => item.number == data.receiver
  );

  // Change status in queue list
  if (panel.state.queueList?.length > 0) {
    store.commit('panel/setQueueMemberStatus', {
      number: data.caller,
      status: ExtenStatus.InUse,
    });
    store.commit('panel/setQueueMemberStatus', {
      number: data.receiver,
      status: ExtenStatus.InUse,
    });
  }

  if (indexCaller > -1) {
    store.commit('panel/setExtenStatus', {
      id: indexCaller,
      status: ExtenStatus.InUse,
    });
    if (panel.state.callTimes[indexCaller].interval == null) {
      store.commit('panel/setInterval', {
        id: indexCaller,
        interval: setInterval(() => {
          state.callTimes[indexCaller].time = getElapsedTime(startTime);
        }, 500),
      });
    }
  }
  if (indexReceiver > -1) {
    store.commit('panel/setExtenStatus', {
      id: indexReceiver,
      status: ExtenStatus.InUse,
    });
    if (panel.state.callTimes[indexReceiver].interval == null) {
      store.commit('panel/setInterval', {
        id: indexReceiver,
        interval: setInterval(() => {
          state.callTimes[indexReceiver].time = getElapsedTime(startTime);
        }, 500),
      });
    }
  }
});

Panel.client.on('DeviceStateChanged', async (data: any) => {
  if (store.state.auth.user.corpId != data.corpId) return;

  // Change status in exten list
  const indexExt = panel.state.extenList.findIndex(
    (item: any) => item.number == data.number
  );
  if (
    indexExt > -1 &&
    panel.state.extenList[indexExt].status == ExtenStatus.InUse &&
    ((ExtenStatus[data.state] as unknown) as number) != ExtenStatus.MOH
  ) {
    store.commit('panel/clearInterval', indexExt);
    store.commit('panel/resetTimeAndStatus', indexExt);
    store.commit('panel/removeActiveCallTransfer', {
      caller: panel.state.extenList[indexExt],
    });
  }
  if (indexExt > -1) {
    store.commit('panel/setExtenStatus', {
      id: indexExt,
      status: ExtenStatus[data.state],
    });
  }
  // Change status in queue list
  if (panel.state.queueList?.length > 0) {
    store.commit('panel/setQueueMemberStatus', {
      number: data.number,
      status: ExtenStatus[data.state],
    });
  }
});

Panel.client.on('AllDash', async (data: any) => {
  store.commit('panel/setDashExtensions', data);
});

Panel.client.on(
  'ActiveCalls',
  async ({
    corpId,
    callId,
    profile,
    caller,
    destination,
    time,
    dialed,
  }: ActiveCall) => {
    if (!callId || !profile || !time) return;
    if (store.state.auth.user.corpId != corpId) return;
    
    time = moment.utc(time).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');

    store.commit('panel/addActiveCall', {
      corpId,
      callId,
      profile,
      caller,
      destination,
      time,
      dialed,
    });
  }
);