











































import Vue from "vue";

export default Vue.extend({
  name: "VSnackbarQueue",
  data: () => ({
    processing: false,
    timeoutId: null as any,
  }),
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },

    autoHeight: {
      type: Boolean,
      default: false,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    closeButtonColor: {
      type: String,
      default: "white",
    },

    closeButtonIcon: {
      type: String,
      default: "close",
    },

    items: {
      type: Array,
      required: true,
    },

    left: {
      type: Boolean,
      default: false,
    },

    multiLine: {
      type: Boolean,
      default: false,
    },

    nextButtonColor: {
      type: String,
      default: "white",
    },

    nextButtonCountText: {
      type: String,
      default: "More",
    },

    nextButtonText: {
      type: String,
      default: "Next",
    },

    right: {
      type: Boolean,
      default: false,
    },

    timeout: {
      type: Number,
      default: 6000,
    },

    top: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    processItems() {
      this.processing = true;

      if (this.items && Array.isArray(this.items) && this.items.length > 0) {
        const item = this.items[0];
        return (this.timeoutId = setTimeout(() => {
          this.removeItem(item.id);
        }, this.timeout));
      }

      this.processing = false;
    },
    removeItem(id: number) {
      clearTimeout(this.timeoutId);

      this.$emit("remove", id);

      if (this.items.length > 0) {
        return this.processItems();
      }
    },
  },
  watch: {
    items() {
      this.processItems();
    },
  },
});
