import {
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel,
} from "@microsoft/signalr";
import config from "../config.js";

class Panel {
  client;
  constructor() {
    this.client = new HubConnectionBuilder()
      .withUrl(config.panel, {
        accessTokenFactory: () => localStorage.getItem("token"),
      } as IHttpConnectionOptions)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();
  }
  async start() {

    if (
      this.client.state != HubConnectionState.Connecting &&
      this.client.state != HubConnectionState.Connected &&
      this.client.state != HubConnectionState.Reconnecting
    ) {
      await this.client.start();
      console.log("%cStarting connection with Panel", "color: #6BC683");
    }
  }
  async stop() {
    if (
      this.client.state != HubConnectionState.Disconnected &&
      this.client.state != HubConnectionState.Disconnecting
    ) {
      await this.client.stop();
      console.log("%cClosing connection with Panel", "color: #9B4343");
    }
  }
}

export default new Panel();
