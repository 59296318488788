

















































































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import QueueInfo from "@/components/templates/QueueInfo.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import config from "../../../config.js";
import * as action from "@/store/actions";
import { Poll, Queue } from "@/types/conf";
import { HttpResponse } from "@microsoft/signalr";

export default Vue.extend({
  name: "QueueDetail",
  props: ["queueToUpdate", "isToEdit", "isToNew"],
  components: {
    Top,
    QueueInfo,
  },
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,
    showPass: false,
    polls: false,

    queue: {
      name: "",
      moh: "default",
      strategy: {name: i18n.t(`strategies.${"ringall"}`), value: "ringall"},
      timeout: null,
      failoverAction: "",
      failoverTimeout: "0",
      polls: null,
      sound: null,
      position: false,
      ringInUse: false,
      seconds: 10,
      autoFill: false,
    } as any,

    actionList: [] as any[],
    pollList: [] as Poll[],

    strategyList: [] as any[],
    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    iconsetting: true,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),

    computedActions(): { text: string; value: string; disabled: boolean; }[] {
      return this.actionList.map((item) => {
        return {
          text:  item.text,        
          value: item.value,  
          disabled: this.exceptionList.includes(item.text),
        };
      });
    },

    computedStrategies(): { name: string; value: string }[] {
      let mappedStrategies = this.strategyList.map(item => (
        {
          name: i18n.t(`strategies.${item.name}`) as string,
          value: item.name,
          disabled: !this.isNew && item.name === "linear"
        }
      ));

      return mappedStrategies;
    },
    
  },
  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      getPollList: "crudService/getList",
      getStrategyList: "crudService/getList",
      createQueue: "crudService/newObject",
      updateQueue: "crudService/updateObject",
    }),

    nameRules(text: string) {
      return text.match(/^[a-zA-Z0-9_ ]*$/) ? true : i18n.t("rules.alphanum");
    },
    timeoutRules(n: number) {
      return n >= 0 && n <= 99 ? true : i18n.t("rules.between0and99");
    },
    secondsRules(n: number) {
      return this.queue.position ? (n >= 10 && n < 61 ? true : i18n.t("rules.between10and60")) : true ;
    },
    failovertimeoutRules(n: number) {
      return n >= 0 && n < 999 ? true : i18n.t("rules.between0and999");
    },
    queueDetailHandler(queue: any) {
      this.queue.name = queue.name;
      this.queue.sound = queue.announce;
      this.queue.moh = queue.moh;
      this.queue.strategy = this.computedStrategies.find(e => e.name == queue.strategy)?.value;
      this.queue.timeout = queue.timeout;
      this.queue.failoverAction = queue.failoveraction;
      this.queue.failoverTimeout = queue.failovertimeout;
      this.queue.position = queue.position == i18n.t('misc.yes');
      this.queue.ringInUse = queue.ringInUse == i18n.t('misc.yes');
      this.queue.seconds = queue.seconds == "-" ? 0 : queue.seconds;
      this.queue.polls = queue.pollId;
      this.polls = !!queue.pollId;
      this.queue.autoFill = queue.autoFill == i18n.t('misc.yes')
    },
    async validate() {

      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let file = new FormData();
        file.append("name", this.queue.name);

        if (this.queue.strategy.name == i18n.t(`strategies.${"ringall"}`) && this.queue.strategy.value == "ringall" ) file.append("strategy", this.queue.strategy.value);
        
        else file.append("strategy", this.queue.strategy);

        if (this.queue.strategy !== "ringall" && !!this.queue.timeout) {
          file.append("timeout", this.queue.timeout.toString());
        }
        file.append("failoveraction", this.queue.failoverAction);
        file.append("failovertimeout", this.queue.failoverTimeout);
        if (this.polls) file.append("pollId", this.queue.polls);
        file.append("moh", this.queue.moh);

        file.append("sound", this.queue.sound);
        if (typeof this.queue.sound == "string") {
          file.append("announce", this.queue.sound);
        }
        
        file.append("ringinuse", this.queue.ringInUse ? "1" : "0");

        file.append("position", (+this.queue.position).toString());

        file.append("autofill", this.queue.autoFill ? "1" : "0");

        if (+this.queue.position) file.append("seconds", (+this.queue.seconds).toString());
        else file.append("seconds", "0");
        /*
        <<SIRVE PARA VER CADA UNO DE LOS CAMPOS DE file>>
        for (const pair of file.entries()) {
          console.log(pair[0], pair[1]);
        }*/
        let response;
        if (this.isNew) {
          response = await this.createQueue({
            action: action.QUEUE_CONTROLLER,
            obj: file,
          });
        } else {
          file.append("id", this.queueToUpdate.id);
          response = await this.updateQueue({
            action: action.QUEUE_CONTROLLER,
            obj: file,
          });
        }
        // This set if the form must persist or don't
        this.dialog = response >= 400

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }
      this.$emit("refreshList", this.dialog);

    },
    setPlay(item: Queue) {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[0];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound();
      }

      this.stateIcons[0] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound(val: Queue) {
      const file = val.sound;
      const url = config.url + "queue/sounds/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound.push(new Audio(url));
        this.playingSound[0].addEventListener("ended", () => {
          this.stateIcons[0] = "mdi-play";
        });
        this.playingSound[0].play();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound() {
      this.playingSound[0].pause();
    },
    resetInput() {
      // this.queue.sound = this.queueToUpdate.announce;
      this.queue.announce = null;
      this.queue.sound = null;
    },

    async updateActionList() {
      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },

  watch: {
  '$i18n.locale': {
    handler: function(newLocale, oldLocale) {
      this.updateActionList();
    },
    immediate: true, // Trigger the handler immediately on component mount
    },
    polls: function () {
      if (this.polls === false) this.queue.polls = null;
    }
  },

  async mounted() {
    this.pollList = await this.getPollList(action.POLL_CONTROLLER);
    this.strategyList = await this.getStrategyList(action.GET_STRATEGY_LIST);
    
    const isNew =
      this.queueToUpdate == null || this.queueToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.queueDetailHandler(this.queueToUpdate);
    }

    this.stateIcons.push("mdi-play");

  },
});
