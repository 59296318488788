

































































































































import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";

import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { DestinationGroup } from "@/types/conf";
import Top from "@/components/templates/Top.vue";

export default Vue.extend({
  name: "",
  props: ["destinationGroupToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    newDG: {
      name: "",
      dest: null,
      patterns: [],
    } as DestinationGroup,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  components: {
    Top,
  },
  methods: {
    ...mapActions({
      newDestinationGroup: "crudService/newObject",
      updateDestinationGroup: "crudService/updateObject",
    }),
    nameRule(v: string) {
      return v.match(/^[a-zA-Z0-9_ ]*$/) ? true : i18n.t("rules.alphanum");
    },

    destinationGroupDetailHandler(newDG: DestinationGroup) {
      this.newDG.name = newDG.name;
      this.newDG.patterns = newDG.patterns;
    },

    addButtonDisabled() {
      return this.newDG.name != null && this.newDG.dest != null;
    },

    addToOptions() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        //set
        this.newDG.patterns.push(this.newDG.dest);

        //reset
        this.newDG.dest = null;
      }
    },

    removeOption(item: DestinationGroup) {
      this.newDG.patterns.includes(item)
        ? this.newDG.patterns.splice(this.newDG.patterns.indexOf(item, 0), 1)
        : null;
    },

    deleteOption(item: DestinationGroup) {
      this.removeOption(item);
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = {
          id: null as any,
          name: this.newDG.name,
          patterns: this.newDG.patterns,
        };

        let response;
        if (this.isNew) {
          response = await this.newDestinationGroup({
            action: action.DESTINATION_GROUP_CONTROLLER,
            obj: body,
          });
        } else {
          body.id = this.destinationGroupToUpdate.id;
          response = await this.updateDestinationGroup({
            action: action.DESTINATION_GROUP_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },
  },
  async mounted() {
    const isNew =
      this.destinationGroupToUpdate == null ||
      this.destinationGroupToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.destinationGroupDetailHandler(this.destinationGroupToUpdate);
    }
  },
});
