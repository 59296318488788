var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',[_c('Top'),_c('div',{staticClass:"ma-2"},[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.ivrList,"items-per-page":10,"fixed-header":"","height":48 * 11,"search":_vm.search,"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent mb-10",attrs:{"flat":""}},[_c('v-text-field',{staticStyle:{"max-width":"35em"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{attrs:{"data":_vm.computedIvrList,"headers":_vm.csvHeaders(),"name":"ivrs"}}),_c('CopyButton',{attrs:{"headers":_vm.csvHeaders(),"copyData":_vm.ivrList}}),(_vm.canCreate)?_c('IVRsDetail',{attrs:{"isToNew":"true"},on:{"refreshList":_vm.refreshList}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.defaultConfirmation"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"icon":"","color":'blue'}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("misc.default"))+": "+_vm._s(item.option0 && _vm.translateAction(item.option0)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 1")+": "+_vm._s(item.option1 && _vm.translateAction(item.option1)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 2")+": "+_vm._s(item.option2 && _vm.translateAction(item.option2)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 3")+": "+_vm._s(item.option3 && _vm.translateAction(item.option3)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 4")+": "+_vm._s(item.option4 && _vm.translateAction(item.option4)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 5")+": "+_vm._s(item.option5 && _vm.translateAction(item.option5)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 6")+": "+_vm._s(item.option6 && _vm.translateAction(item.option6)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 7")+": "+_vm._s(item.option7 && _vm.translateAction(item.option7)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 8")+": "+_vm._s(item.option8 && _vm.translateAction(item.option8)))]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.option") + " 9")+": "+_vm._s(item.option9 && _vm.translateAction(item.option9)))])])])]}},{key:"item.sound",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.sound)?_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay(item)}}},on),[_c('v-icon',[_vm._v(" mdi-play ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.sound)?_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}},{key:"item.soundIncorrect",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay2(item)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(!!item.soundIncorrect && "mdi-play")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.soundIncorrect)?_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile2(item)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IVRsDetail',{attrs:{"isToEdit":"true","ivrToUpdate":item},on:{"refreshList":_vm.refreshList}}),(_vm.canDelete)?_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }