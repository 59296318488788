var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('Top'),_c('v-row',{staticClass:"ml-0 mr-0 mt-0"},[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"8","lg":"8"}},[_c('v-card',{staticClass:"elevation-4",class:_vm.color},[_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("misc.busyAgents"))+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-row',{staticClass:"flex justify-start ma-2"},_vm._l((_vm.paginate),function(exten){return _c('v-card',{key:exten.id,staticClass:"ma-1",class:_vm.color(exten),attrs:{"elevation":"0","min-height":"70","max-height":"70","min-width":"120","max-width":"120"}},[_c('v-card-text',{staticClass:"ml-1 py-0 px-3"},[_c('div',{staticStyle:{"display":"flex","gap":"1rem","align-items":"center"}},[_c('p',{staticClass:"text-overline my-1 font-weight-bold"},[_vm._v(" "+_vm._s(exten.number)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:(!!_vm.$vuetify.theme.dark
                            ? { color: '#f1f5f144' }
                            : { color: '#1e1e1e88' })},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.action'))+": "+_vm._s(_vm.$t(("actions." + (exten.action))))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.occupied'))+": "+_vm._s(exten.onbusy ? _vm.$t(("actions." + (exten.onbusy))) : "")+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.external'))+": "+_vm._s(exten.outer)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.group'))+": "+_vm._s(exten.callgroup)+" ")])])])],1),_c('div',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
                          position: 'relative',
                          left: '-8px',
                          top: '-6px',
                        }),attrs:{"icon":"","color":"green"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":"","disabled":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('misc.addToQueue'))+" ")])],1),_vm._l((_vm.queueList),function(queue,queueIndex){return _c('v-list-item',{key:queueIndex,attrs:{"dense":"","disabled":queue.objects.includes(exten)},on:{"click":function($event){return _vm.addToQueue({ id: queue.id, item: exten })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(queue.name)+" ")])],1)})],2)],1),(exten.status != 0)?_c('IpInfoPanel',{attrs:{"exten":exten.number}}):_vm._e()],1)])],1)}),1),_c('div',{staticClass:"mt-12 pb-5"},[_c('transition-group',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"name":"pagination-fade","mode":"out-in"}},[(_vm.currentPage > 0)?_c('v-icon',{key:"first",staticClass:"item-pagination-number rounded",on:{"click":function($event){_vm.currentPage = 0}}},[_vm._v(" mdi-page-first ")]):_vm._e(),(_vm.currentPage > 0)?_c('v-icon',{key:"previous",staticClass:"item-pagination-number rounded",on:{"click":function($event){_vm.currentPage--}}},[_vm._v(" mdi-chevron-left ")]):_vm._e(),_vm._l((_vm.totalPages),function(pageNumber,index){return [(
                    Math.abs(index - _vm.currentPage) < 3 ||
                      pageNumber == 0
                  )?_c('p',{key:pageNumber,staticClass:"my-0 item-pagination-number",class:{ currentPage: index === _vm.currentPage },on:{"click":function($event){return _vm.setPage(pageNumber - 1)}}},[_vm._v(" "+_vm._s(pageNumber)+" ")]):_vm._e()]}),(_vm.currentPage < _vm.totalPages - 1)?_c('v-icon',{key:"next",staticClass:"item-pagination-number rounded",on:{"click":function($event){_vm.currentPage++}}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),(_vm.currentPage < _vm.totalPages - 1)?_c('v-icon',{key:"last",staticClass:"item-pagination-number rounded",on:{"click":function($event){_vm.currentPage = _vm.totalPages - 1}}},[_vm._v(" mdi-page-last ")]):_vm._e()],2)],1)],1)],1),_c('v-col',{key:_vm.queueListKey,attrs:{"xs":"12","sm":"12","md":"4","lg":"4"}},[_c('v-card',{staticClass:"elevation-4",class:_vm.color},[_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('configuration.queue'))+" ")])],1),_c('v-divider'),_c('v-row',{staticClass:"flex justify-start ma-2"},[_c('hr',{staticStyle:{"height":"1px","border":"0","background-color":"darkgray"}}),_c('v-row',{attrs:{"justify":"start","no-gutters":""}},_vm._l((_vm.queueList),function(queue,queueIndex){return _c('v-list-group',{key:queue.id,staticClass:"queuesContainer",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:(_vm.$vuetify.theme.dark ? { color: '#f1f5f144' } : { color: '#1e1e1e88' })},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.strategy'))+": "+_vm._s(_vm.$t(("strategies." + (queue.strategy))))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.timeout'))+": "+_vm._s(queue.timeout)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.action'))+": "+_vm._s(queue.failoveraction != "null" ? _vm.actionList.find(function (el) { return el.value == queue.failoveraction; }).text : "")+" ")])])])],1),_c('v-list-item-content',{staticClass:"ml-4",staticStyle:{"width":"1px"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(queue.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('misc.count'))+": "+_vm._s(queue.objects.length)+" ")])],1)]},proxy:true}],null,true)},[_c('v-row',{staticClass:"ma-2"},_vm._l((queue.objects.filter(function (value, index, self) { return index == self.findIndex( function (t) { return t.number == value.number; } ); } )),function(obj,i){return _c('v-col',{key:i},[_c('v-card',{class:_vm.color(obj),attrs:{"elevation":"0","min-height":"40","max-height":"40","min-width":"130"}},[_c('v-card-text',{staticClass:" py-1 px-3"},[_c('p',{staticClass:"text-overline my-0 font-weight-bold",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(obj.number)+" ")]),_c('p',{staticClass:"text-caption my-0 font-weight-bold mr-3",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(obj.user)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:(_vm.$vuetify.theme.dark
                                  ? { color: '#f1f5f144' }
                                  : { color: '#1e1e1e88' })},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.action'))+": "+_vm._s(obj.action)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.occupied'))+": "+_vm._s(obj.onbusy)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.external'))+": "+_vm._s(obj.outer)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('misc.group'))+": "+_vm._s(obj.callgroup)+" ")])])]),_c('v-btn',{style:({
                            position: 'relative',
                            left: '15px',
                          }),attrs:{"icon":"","small":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem({
                              obj: obj,
                              queueListIndex: queueIndex,
                              datoMisterioso: ((queue.id) + "/" + (obj.number)),
                            })}}},[_c('v-icon',[_vm._v(" mdi-minus ")])],1)],1)],1)],1)}),1)],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }