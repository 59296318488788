















































































import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { SpeedDialExtension } from "@/types/conf";
import store from "@/store/index";

export default Vue.extend({
  name: "SpeedDialExtenDetail",
  props: ["speedDialToUpdate", "exten", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    newSD: {
      number: null,
      action: null,
      extenId: "",
      id: 0,
    } as SpeedDialExtension,

    rules: {
      required: (v: number) => !!v || i18n.t("loginView.required"),
      numRule: (v: number) => (v && v < 10) || i18n.t("rules.between0and9"),
      actionRule: (v: string) => v.length <= 20 || i18n.t("rules.lengthTo", { num: 20 }),
    },

    isNew: false,
  }),
  methods: {
    ...mapActions({
      newSpeedDial: "crudService/newObject",
      updateSpeedDial: "crudService/updateObject",
    }),

    speedDialDetailHandler(speedDial: SpeedDialExtension) {
      this.newSD.number = speedDial.number;
      this.newSD.action = speedDial.action;
      this.newSD.extenId = speedDial.extenId;
    },
    async validate() {
      
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = {
          id: null as any,
          Number:
            typeof this.newSD.number === "string"
              ? parseInt(this.newSD.number)
              : this.newSD.number,
          Action: this.newSD.action?.toString(),
          ExtenId: this.newSD.extenId,
        };

        let response;
        if (this.isNew) {
          response = await this.newSpeedDial({
            action: action.SPEED_DIAL_EXTENSIONS_CONTROLLER,
            obj: body,
          });
        } else {
          body.id = this.speedDialToUpdate.id;
          response = await this.updateSpeedDial({
            action: action.SPEED_DIAL_EXTENSIONS_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("lookAndFeel/setCorpName");
    const corpName = store.state.lookAndFeel.corpName as string;
    if (to.params.exten) {
      next();
    } else {
      next({
        name: "extension",
        params: { corp: corpName, lang: navigator.language.slice(0, 2) },
      });
    }
  },
  async mounted() {
    const isNew = this.speedDialToUpdate == null || this.speedDialToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.speedDialDetailHandler(this.speedDialToUpdate);
    }

    this.newSD.extenId = this.exten.id;
  },
});
