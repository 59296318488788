









































































































































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import config from "../../../config.js";
import * as action from "@/store/actions";
import { Integration } from "@/types/conf";


export default Vue.extend({
  name: "",
  props: ["integrationToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,
    
    alertType: "",
    alertMessage: "",
    valid: false,

    showPass: false,

    obj: {
      name: "",
      type: "",
      host: "",
      user: "",
      password: "",
      option1: "",
      option2: "",
      option3: "",
      sound1: null,
      sound2: null,
      sound3: null,
      sound4: null,
    } as Integration,

    fileSound1: "",
    fileSound2: "",
    fileSound3: "",
    fileSound4: "",

    loaded: false,
    actionList: [] as any[],
    typeList: ["ISP Integrador", "IXC", "SGP", "Condominios"],
    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],
    playingSound: [] as any[],
    stateIcons: [] as string[],
    iconsetting: true,
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  computed: {
    color(): string {
      return ["error", "warning", "success"][Math.floor(this.progress() / 40)];
    },

    computedActions(): { text: string; value: string; disabled: boolean; }[] {
      return this.actionList.map((item) => {
        return {
          text:  item.text,        
          value: item.value,  
          disabled: this.exceptionList.includes(item.text),
        };
      });
    },
  },
  components: {   
    Top
  },
  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      newIntegration: "crudService/newObject",
      updateIntegration: "crudService/updateObject",
      getFile: "fileGetters/getFile",
    }),

    progress(): number {
      return Math.min(100, this.obj.password.length * 10);
    },

    passwordRules(pass: string) {
      return pass.length > 3 || pass == null
        ? true
        : i18n.t("loginView.msgPass");
    },

    passwordRegexRules(pass: string) {
      return pass.match(/^[a-zA-Z0-9_+*.@ -]*$/) || pass == null
        ? true
        : i18n.t("rules.alphanumAndSpecial"); //i18n.t("")
    },
    hostRules(t: string) {
      return t.match(
        /^[0-9]{2,6}(.){1}[0-9]{1,3}(.){1}[0-9]{1,3}(.){1}[0-9]{1,3}$/
      ) || t == null
        ? true
        : i18n.t("integration.IP");
    },

    async validate() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });
      
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();
        body.append("name", this.obj.name);
        body.append("user", this.obj.user);
        body.append("password", this.obj.password);
        !!this.obj.type ? body.append("type", this.obj.type) : null;
        !!this.obj.host ? body.append("host", this.obj.host) : null;

        if (!!this.obj.option1) {
          if (actionList.queues[this.obj.option1]) body.append("option1", actionList.queues[this.obj.option1])
          else body.append("option1", this.obj.option1)
        }

        if (!!this.obj.option2) {
          if (actionList.queues[this.obj.option2]) body.append("option2", actionList.queues[this.obj.option2])
          else body.append("option2", this.obj.option2)
        }

        if (!!this.obj.option3) {
          if (actionList.queues[this.obj.option3]) body.append("option3", actionList.queues[this.obj.option3])
          else body.append("option3", this.obj.option3)
        }

        !!this.obj.sound1
          ? body.append(
              "sound1",
              !!this.fileSound1
                ? new Blob(this.blobMaker(await this.fileSound1), {
                    type: "audio/x-wav",
                  })
                : this.obj.sound1
            )
          : null;
        !!this.obj.sound2
          ? body.append(
              "sound2",
              !!this.fileSound2
                ? new Blob(this.blobMaker(await this.fileSound2), {
                    type: "audio/x-wav",
                  })
                : this.obj.sound2
            )
          : null;
        !!this.obj.sound3
          ? body.append(
              "sound3",
              !!this.fileSound3
                ? new Blob(this.blobMaker(await this.fileSound3), {
                    type: "audio/x-wav",
                  })
                : this.obj.sound3
            )
          : null;
        !!this.obj.sound4
          ? body.append(
              "sound4",
              !!this.fileSound4
                ? new Blob(this.blobMaker(await this.fileSound4), {
                    type: "audio/x-wav",
                  })
                : this.obj.sound4
            )
          : null;

        let response;
        if (this.isNew) {
          response = await this.newIntegration({
            action: action.INTEGRATION_CONTROLLER,
            obj: body,
          });
        } else {
          body.append("id", this.integrationToUpdate.id);
          response = await this.updateIntegration({
            action: action.INTEGRATION_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400
        
        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }
      this.$emit("refreshList", this.dialog)
    },

    async integrationHandler(integration: Integration) {
      this.obj.name = integration.name;
      this.obj.type = integration.type;
      this.obj.host = integration.host;
      this.obj.user = integration.user;
      this.obj.password = integration.password;
      this.obj.option1 = integration.option1;
      this.obj.option2 = integration.option2;
      this.obj.option3 = integration.option3;
      this.obj.sound1 = !!this.integrationToUpdate.sound1
        ? new File([...(await this.fileSound1)], integration.sound1, {
            type: "audio/wav",
          })
        : null;
      this.obj.sound2 = !!this.integrationToUpdate.sound2
        ? new File([...(await this.fileSound2)], integration.sound2, {
            type: "audio/wav",
          })
        : null;
      this.obj.sound3 = !!this.integrationToUpdate.sound3
        ? new File([...(await this.fileSound3)], integration.sound3, {
            type: "audio/wav",
          })
        : null;
      this.obj.sound4 = !!this.integrationToUpdate.sound4
        ? new File([...(await this.fileSound4)], integration.sound4, {
            type: "audio/wav",
          })
        : null;
      this.playingSound[0] = !!this.integrationToUpdate.sound1
        ? new Audio(await this.fileSound1 )
        : null;
      this.playingSound[1] = !!this.integrationToUpdate.sound2
        ? new Audio(await this.fileSound2)
        : null;
      this.playingSound[2] = !!this.integrationToUpdate.sound3
        ? new Audio(await this.fileSound3)
        : null;
      this.playingSound[3] = !!this.integrationToUpdate.sound4
        ? new Audio(await this.fileSound4)
        : null;
    },
    blobMaker(wavString: string) {
      let len = wavString.length;
      let buf = new ArrayBuffer(len);
      let view = new Uint8Array(buf);
      for (let i = 0; i < len; i++) {
        view[i] = wavString.charCodeAt(i) & 0xff;
      }
      return [view];
    },
    //#region sounds 1
    setPlay() {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[0];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound();
      } else {
        icon = "mdi-play";
        this.pauseSound();
      }

      this.stateIcons[0] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound() {
      

      this.playingSound[0].addEventListener("ended", () => {
        this.stateIcons[0] = "mdi-play";
      });
      if (this.playingSound[0]) {
        this.playingSound[0].type = "audio/wav";
        this.playingSound[0].play();
      }
    },

    pauseSound() {
      this.playingSound[0].pause();
    },

    //#endregion
    //#region sounds 2
    setPlay2() {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[1];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound2();
      } else {
        icon = "mdi-play";
        this.pauseSound2();
      }

      this.stateIcons[1] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound2() {
      this.playingSound[1].addEventListener("ended", () => {
        this.stateIcons[1] = "mdi-play";
      });
      if (this.playingSound[1]) {
        this.playingSound[1].type = "audio/wav";
        this.playingSound[1].play();
      }
    },
    pauseSound2() {
      if (this.playingSound[1]) {
        this.playingSound[1].pause();
      }
    },
    //#endregion
    //#region sounds 3
    setPlay3() {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[2];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound3();
      } else {
        icon = "mdi-play";
        this.pauseSound3();
      }

      this.stateIcons[2] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound3() {
      this.playingSound[2].addEventListener("ended", () => {
        this.stateIcons[2] = "mdi-play";
      });
      if (this.playingSound[2]) {
        this.playingSound[2].type = "audio/wav";
        this.playingSound[2].play();
      }
    },
    pauseSound3() {
      if (this.playingSound[2]) {
        this.playingSound[2].pause();
      }
    },
    //#endregion
    //#region sounds 4
    setPlay4() {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[3];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound4();
      } else {
        icon = "mdi-play";
        this.pauseSound4();
      }

      this.stateIcons[3] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound4() {
      this.playingSound[3].addEventListener("ended", () => {
        this.stateIcons[3] = "mdi-play";
      });
      if (this.playingSound[3]) {
        this.playingSound[3].type = "audio/wav";
        this.playingSound[3].play();
      }
    },
    pauseSound4() {
      if (this.playingSound[3]) {
        this.playingSound[3].pause();
      }
    },
    //#endregion
    resetInput() {
      if (!this.isNew) {
        this.obj.sound1 = null;
        this.playingSound[0] = null;
        this.stateIcons[0] = "";
      }
    },
    resetInput2() {
      if (!this.isNew) {
        this.obj.sound2 = null;
        this.playingSound[1] = null;
        this.stateIcons[1] = "";
      }
    },
    resetInput3() {
      if (!this.isNew) {
        this.obj.sound3 = null;
        this.playingSound[2] = null;
        this.stateIcons[2] = "";
      }
    },
    resetInput4() {
      if (!this.isNew) {
        this.obj.sound4 = null;
        this.playingSound[3] = null;
        this.stateIcons[3] = "";
      }
    },

    async updateActionList() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateActionList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },
  async mounted() {
    const isNew =
      this.integrationToUpdate == null ||
      this.integrationToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.fileSound1 = !!this.integrationToUpdate.sound1
        ? await this.getFile({
            fileName: this.integrationToUpdate.sound1,
            fileType: "getsound",
            endpointName: "integration",
          })
        : null;
      this.fileSound2 = !!this.integrationToUpdate.sound2
        ? await this.getFile({
            fileName: this.integrationToUpdate.sound2,
            fileType: "getsound",
            endpointName: "integration",
          })
        : null;
      this.fileSound3 = !!this.integrationToUpdate.sound3
        ? await this.getFile({
            fileName: this.integrationToUpdate.sound3,
            fileType: "getsound",
            endpointName: "integration",
          })
        : null;
      this.fileSound4 = !!this.integrationToUpdate.sound4
        ? await this.getFile({
            fileName: this.integrationToUpdate.sound4,
            fileType: "getsound",
            endpointName: "integration",
          })
        : null;
      await this.integrationHandler(this.integrationToUpdate);
    }

    
    
    this.stateIcons.push("mdi-play");
    this.stateIcons.push("mdi-play");
    this.stateIcons.push("mdi-play");
    this.stateIcons.push("mdi-play");

    this.loaded = true;
  },
});
