var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.detailed ? _vm.detailedHeaders() : _vm.headers(),"items":_vm.computedCDRList,"items-per-page":5,"height":48 * 6,"search":_vm.search,"footer-props":{ 'items-per-page-text': ("" + (_vm.$t('misc.rowsPP'))) },"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{key:_vm.csvKey,attrs:{"data":_vm.computedCDRList,"headers":_vm.detailed ? _vm.detailedHeaders() : _vm.headers(),"name":_vm.detailed
          ? ("cdrs_detailed_" + (new Date().toLocaleString(_vm.$i18n.locale)))
          : ("cdrs_summarized_" + (new Date().toLocaleString(_vm.$i18n.locale)))}}),_c('CopyButton',{attrs:{"headers":_vm.headers(),"copyData":_vm.computedCDRList}})],1)]},proxy:true},(_vm.detailed)?{key:"item.recordFile",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.recordFile)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-play ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.recordFile)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }