











































































































































import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Corp, SIPRoute, Trunk } from "@/types/conf";

export default Vue.extend({
  name: "SIPRoutesDetail",
  props: ["siproutesToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    siproutes: {
      comment: "",
      pattern: "",
      translation: "",
      corpId: null,
      trunkId: null,
      enabled: false,
    } as SIPRoute,
    corpList: [] as Corp[],
    trunkList: [] as Trunk[],
    filteredTrunkList: [] as Trunk[],

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },

    isNew: false,
  }),

  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },

  watch: {
    'siproutes.corpId': function(newVal, prevVal) {
      this.filteredTrunkList = this.trunkList.filter((e: any) => e.corpId == newVal);
    },
  },

  components: {
    
  },
  methods: {
    ...mapActions({
      newSipRoutes: "crudService/newObject",
      updateSipRoutes: "crudService/updateObject",
      getCorpList: "crudService/getList",
      getTrunkList: "crudService/getList",
    }),

    findCorpId(corpName: string | number | null) {
      return this.corpList.find(e => e.name == corpName)!.id;
    },

    findTrunkId(trunkNumber: string | number | null) {
      return this.trunkList.find(e => e.number == trunkNumber)!.id;
    },

    commentRules(v: string) {
      return v.length <= 50 ? true : i18n.t("rules.lengthTo", { num: 50 });
    },

    patternRules(v: string) {
      return v.match(/(^[.0-9]*){1}([T])$/g)
        ? true
        : i18n.t("rules.numberTDot");
    },

    siproutesDetailHandler(siproutes: SIPRoute) {
      this.siproutes.comment = siproutes.comment;
      this.siproutes.pattern = siproutes.pattern;
      this.siproutes.translation = siproutes.translation;
      this.siproutes.corpId = this.findCorpId(siproutes.corpId) as any;
      this.siproutes.trunkId = this.findTrunkId(siproutes.trunkId) as any;
      this.siproutes.enabled = siproutes.enabled == i18n.t('misc.yes');
    },

    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = {
          id: null as any,
          Comment: this.siproutes.comment,
          Pattern: this.siproutes.pattern,
          Translation: this.siproutes.translation,
          CorpId: this.siproutes.corpId,
          TrunkId: this.siproutes.trunkId,
          Enabled: Boolean(this.siproutes.enabled),
        };

        let response;
        if (this.isNew) {
          response = await this.newSipRoutes({
            action: action.SIP_ROUTES_NEW_CONTROLLER,
            obj: body,
          });
        } else {
          body.id = this.siproutesToUpdate.id;
          response = await this.updateSipRoutes({
            action: action.SIP_ROUTES_UPDATE_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },
  },
  async mounted() {
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);
    
    const isNew = this.siproutesToUpdate == null || this.siproutesToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) this.siproutesDetailHandler(this.siproutesToUpdate);
  },
});
