var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',[_c('Top'),_c('div',{staticClass:"ma-2"},[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.itemList,"items-per-page":_vm.computedHeight,"fixed-header":"","height":_vm.computedHeight,"search":_vm.search,"footer-props":{'items-per-page-text': ("" + (_vm.$t('misc.rowsPP')))},"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent mb-10",attrs:{"flat":""}},[_c('v-text-field',{staticStyle:{"max-width":"35em"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{attrs:{"data":_vm.itemList ? _vm.itemList : null,"headers":_vm.headers(),"name":"polls"}}),_c('CopyButton',{attrs:{"headers":_vm.headers(),"copyData":_vm.itemList ? _vm.itemList : null}}),_c('PollsDetail',{attrs:{"isToNew":"true"},on:{"refreshList":_vm.refreshList}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.defaultConfirmation"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.sound",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.sound)?_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay(item)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[_vm.itemList.indexOf(item)]))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.sound)?_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('PollsDetail',{attrs:{"isToEdit":"true","pollToUpdate":item},on:{"refreshList":_vm.refreshList}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"deep-purple"},on:{"click":function($event){return _vm.addQuestions(item)}}},on),[_c('v-icon',[_vm._v(" mdi-progress-question ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("misc.add"))+" "+_vm._s(_vm.$t("configuration.questions"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canDelete)?_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.delete")))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }