import { RootState, SnackbarState } from "@/types/store/index";
import { ActionTree, MutationTree } from "vuex";

const state: SnackbarState = { message: "", color: "", timeout: "" };
const mutations: MutationTree<SnackbarState> = {
  SHOW_MESSAGE(state, payload) {
    state.message = payload.message;
    state.color = payload.color;
    state.timeout = payload.timeout;
  },
};
const actions: ActionTree<SnackbarState, RootState> = {
  showSnack({ commit }, payload) {
    commit("SHOW_MESSAGE", payload);
  },
};
export const snackbar = {
  namespaced: true,
  state,
  mutations,
  actions,
};
