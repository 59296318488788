import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#909090",
        secondary: "#424242",
        error: "#de1414",
        info: "#2196F3",
        success: "#2ED47A",
        warning: "#FFC107",
        yellow: "#FFC107",
      },
    },
  },
});
