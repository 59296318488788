
































































































































































































































import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import Vue from "vue";
import Top from "@/components/templates/Top.vue";

export default Vue.extend({
  
  data: () => ({
    alertType: "",
    alertMessage: "",
    favIconImageLocal: "",
    menuIconColorLocal: {
      hexa: localStorage.getItem("menuIconColor"),
    },
    menuFontColorLocal: {
      hexa: localStorage.getItem("menuFontColor"),
    },
    hoverColorLocal: {
      hexa: localStorage.getItem("hoverColor"),
    },
    menuColorLocal: {
      hexa: localStorage.getItem("menuColor"),
    },
    backgroundColorLocal: {
      hexa: localStorage.getItem("backgroundColor"),
    },
    loginTextColorLocal: {
      hexa: localStorage.getItem("loginTextColor"),
    },
    loginImageLocal: "",
    loginBottomImageLocal: "",
  }),
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
      hoverColor: "lookAndFeel/hoverColor",

      menuColor: "lookAndFeel/menuColor",
      backgroundColor: "lookAndFeel/backgroundColor",
      loginTextColor: "lookAndFeel/loginTextColor",

      loginImage: "lookAndFeel/loginImage",
      loginBottomImage: "lookAndFeel/loginBottomImage",
      favIconImage: "lookAndFeel/favIconImage",
    }),
    computedHeight() {
      return window.innerHeight / 4;
    },
  },
  components: {   
    Top
  },
  mounted() {

    this.menuIconColorLocal.hexa = this.menuIconColor.length > 7 ? this.menuIconColor : this.menuIconColor + "FF";
    this.menuFontColorLocal.hexa = this.menuFontColor.length > 7 ? this.menuFontColor : this.menuFontColor + "FF";
    this.hoverColorLocal.hexa = this.hoverColor.length > 7 ? this.hoverColor : this.hoverColor + "FF";

    this.menuColorLocal.hexa = this.menuColor.length > 7 ? this.menuColor : this.menuColor + "FF";
    this.backgroundColorLocal.hexa = this.backgroundColor.length > 7 ? this.backgroundColor : this.backgroundColor + "FF";
    this.loginTextColorLocal.hexa = this.loginTextColor.length > 7 ? this.loginTextColor : this.loginTextColor + "FF";
  },
  methods: {
    ...mapActions({
      postLookandfeel: "lookAndFeel/postLookandfeel",
    }),

    async handleFavIcon() {
      const blob = await this.favIconImageLocal;
      const favicon = document.getElementById("favicon") as HTMLLinkElement;
      if (favicon.href) favicon.href = URL.createObjectURL(blob as any);
    },

    async useDefaultColors() {
      this.menuIconColorHandler("#00C6ABFF", "#D3FBD8FF", "#DE141400", "#37474FFF", "#F2F2F2FF", "#000000FF")
    },

    async menuIconColorHandler(menuIconColor = "", menuFontColor = "", hoverColor = "", menuColor = "", backgroundColor = "", loginTextColor = "") {
      const lookandfeelObj = {
        menuIconColor: menuIconColor ? menuIconColor : this.menuIconColorLocal.hexa,
        menuFontColor: menuFontColor ? menuFontColor : this.menuFontColorLocal.hexa,
        hoverColor: hoverColor ? hoverColor : this.hoverColorLocal.hexa,

        menuColor: menuColor ? menuColor : this.menuColorLocal.hexa,
        backgroundColor: backgroundColor ? backgroundColor : this.backgroundColorLocal.hexa,
        loginTextColor: loginTextColor ? loginTextColor : this.loginTextColorLocal.hexa,

        loginImage: this.loginImageLocal,
        loginBottomImage: this.loginBottomImageLocal,
        favIconImage: this.favIconImageLocal,
      } as any;

      const req = new FormData();

      req.append("menuIconColor", lookandfeelObj.menuIconColor);
      req.append("menuFontColor", lookandfeelObj.menuFontColor);
      req.append("hoverColor", lookandfeelObj.hoverColor);

      req.append("menuColor", lookandfeelObj.menuColor);
      req.append("backgroundColor", lookandfeelObj.backgroundColor);
      req.append("loginTextColor", lookandfeelObj.loginTextColor);

      if (this.loginImageLocal){
        req.append("imageLogin", this.loginImageLocal);
      }
      
      if (this.loginBottomImageLocal){
        req.append("imageLoginBottom", this.loginBottomImageLocal);
      }

      if (this.favIconImageLocal){
        req.append("imageFavIcon", this.favIconImageLocal);
      }

      var response = await this.postLookandfeel(req);
      if (response) {
          this.alertMessage = ((i18n.t("misc.updated") as string) +
            i18n.t("misc.defaultSuccess")) as string;
          this.alertType = "green darken-1";

        } else {
          this.alertMessage = i18n.t("misc.defaultError") as string;
          this.alertType = "error";
        }
        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
        this.$emit("createdUser");

      if (!!this.favIconImageLocal) await this.handleFavIcon();

      this.$store.dispatch("lookAndFeel/setLookandfeel", lookandfeelObj);

      //si todo salio bien hago un refresh para aplicar los cambios del lookandfeel
      if (this.alertType==="green darken-1"){
        
        let a = window.location.pathname.split("/")
        let b = "/" + a[1] + "/" + a[2] + "/configuration/home" 
        
        setTimeout( () => {
          this.$router.push(b);
        }, 1200);
      }
    },
  },
});
