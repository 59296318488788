

































































































































































































































import Vue from "vue";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";

import Top from "@/components/templates/Top.vue";
import PollsStatisticsResume from "./PollsStatisticsResume.vue";

import * as action from "@/store/actions";
import { tokenHeader } from "../../../services/auth";
import axios from "axios";

export default Vue.extend({
  name: "PollsStatisticsSearch",
  data: () => ({
    alertType: "",
    alertMessage: "",
    search: "",
    valid: false,

    menu: false,
    menu2: false,
    time: null as any,
    model: 1,

    timeOptions: [] as any[],
    body: {},
    results: [],

    from: "",
    to: "",
    exten: "",

    obj: {
      summarized: false,
      poll: null,
      question: null,
      extension: "0",
      dateFromModel: false,
      dateFrom: new Date().toJSON().slice(0, 10),
      hourFrom: "00:00",
      dateToModel: false,
      dateTo: new Date().toJSON().slice(0, 10),
      hourToModel: false,
      hourTo: "23:59",
    },

    extensionsList: [] as any[],
    pollsList: [] as any[],
    questionList: [] as any[],

    itemList: [] as any[],
    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),

  components: {
    Top,
    PollsStatisticsResume
  },
  methods: {
    ...mapActions({
      getList: "crudService/getList",
      getActions: "crudService/getDetail",
      newSpeedDial: "speedDialService/newSpeedDial",
      updateSpeedDial: "speedDialService/updateSpeedDial",
    }),

    fromToRules(from: Date, to: Date) {
      return from < to ? true : i18n.t("rules.secondHour");
    },

    async updateExtenList() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push( { text: i18n.t("corpPanel.all") , value: "0" } );
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.extensionsList = arr.filter((e: any) => e.text[0] != "V" );
    },

    async updatePollsList() {

      const pollList = await this.getList(action.POLL_CONTROLLER);
      
      const arr: any[] = [];

      pollList.forEach((element: any) => {
        arr.push({ text: element.name, value: element.id })
      });

      this.pollsList = arr;
    },

    async updateQuestionList() {

      const questionList = await this.getList(`${action.QUESTION_POLL_CONTROLLER}${this.obj.poll}`);
      
      const arr: any[] = [];

      arr.push( { text: i18n.t("corpPanel.all") , value: "0" } );
      questionList.forEach((element: any) => {
        arr.push({ text: element.name, value: element.id })
      });

      this.questionList = arr;
    },

    async onSubmit() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        this.from = this.obj.dateFrom + "T" + this.obj.hourFrom;
        this.to = this.obj.dateTo + "T" + this.obj.hourTo;

        this.body = {
          from: this.obj.dateFrom + "T" + this.obj.hourFrom,
          to: this.obj.dateTo + "T" + this.obj.hourTo,
          summarized: this.obj.summarized
        }
        const headers = tokenHeader();
        
        let extensionNeeded = this.extensionsList.find(e => this.obj.extension == e.value).text;
        if (extensionNeeded == i18n.t("corpPanel.all")) extensionNeeded = 0
        
        this.exten = extensionNeeded;

        await axios
          .post(
            `${action.QUESTION_LIST}${this.obj.poll}/${this.obj.question}/${extensionNeeded}`,
            this.body,
            { withCredentials: true, headers }
          )
          .then((res) => {
            this.results = res.data;
          })
          .catch(() => {
            alert("QUESTION_LIST is not working");
          });
      }
    },
  },

  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateExtenList();
        this.updatePollsList()
      },
      immediate: true // Trigger the handler immediately on component mount
    },
    'obj.poll': {
      handler: function(newVal, oldVal) {
        this.updateQuestionList();
      }
    },
  },

  async mounted() {
    this.updateExtenList();
    this.updatePollsList();
  }
});
