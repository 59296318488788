



























































































import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import Vue from "vue";
import { Corp, User } from "@/types/conf";
import Top from "@/components/templates/Top.vue";

export default Vue.extend({
  name: "UserProfile",
  data: () => ({
    alertType: "",
    alertMessage: "",
    valid: false,
    selectedCorp: null as number | null,
    showPass: false,
    showConfirmPass: false,
    password: "",
    level: "",
    confirmPassword: "",
    name: "",
    email: "",
    selectedRolegroup: null as number | null,
    corpList: [] as Corp[],
    enabled: false,
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    color(): string {
      return ["error", "warning", "success"][Math.floor(this.progress() / 40)];
    },
  },
  components: {
  
    Top
  },
  methods: {
    ...mapActions({
      getCorpList: "crudService/getList",
      updateUserPass: "crudService/updateObject",
      getUserDetail: "crudService/getDetail",
    }),
    progress(): number {
      return Math.min(100, this.password.length * 10);
    },
    confirmPasswordRules(pass: string) {
      return pass == this.password ? true : i18n.t("loginView.confirmMsgPass");
    },
    passwordRules(pass: string) {
      return pass.length > 3 ? true : i18n.t("loginView.msgPass");
    },
    userDetailHandler(user: User) {
      this.name = user.nick;
      this.email = user.mail;
      this.password = "";
      this.confirmPassword = "";
      this.level = user.level;
      this.selectedCorp = user.corpId;
      this.selectedRolegroup = user.roleGroupId;
      this.enabled = user.enabled == 1 ? true : false;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let req = {
          id: 0,
          pass: this.password,
          mail: this.email,
          level: 0,
          enabled: 1,
          nick: "null",
          corpId: 0,
          roleGroupId: 0,
        };
        let response;

        response = await this.updateUserPass({
          action: action.UPDATE_MY_USER,
          obj: req,
        });

        if (response) {
          this.alertMessage = ((i18n.t("misc.created") as string) +
            i18n.t("misc.defaultSuccess")) as string;
          this.alertType = "green darken-1";
        } else {
          this.alertMessage = i18n.t("misc.defaultError") as string;
          this.alertType = "error";
        }
        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
        if (
          response &&
          ((this.$route.name as string).match(/\w+\/new/g) ||
            (this.$route.name as string).match(/\w+\/update/g))
        ) {
          setTimeout(() => {
            this.$router.replace({
              name: "home",
              params: {
                corp: localStorage.getItem("corp") || "",
                lang: this.$i18n.locale,
              },
            });
          }, 2000);
        }
      }
    },
    goBack() {
      const newRoute = "user";
      this.$router.replace({
        name: newRoute,
      });
    },
  },
  async mounted() {
    const user = await this.getUserDetail({
      action: action.USER_CONTROLLER,
      id: this.user.id,
    });
    this.$nextTick(() => {
      this.userDetailHandler(user);
    });
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
  },
});
