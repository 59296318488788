


















































































































import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

import * as action from "../../../store/actions"
import { tokenHeader } from "../../../services/auth";
import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import config from "@/config";

export default Vue.extend({
  name: "CDRTable",
  props: ["queueId", "queueName", "body"],

  components: {
    apexchart: VueApexCharts,
    CSV,
    CopyButton
  },

  data: () => ({
    dialog: false,
    search: "",
    loadingList: false,
    list: [] as any[],

    stateIcons: [] as { audio: string }[],
    playingSound: [] as {
      audio: HTMLAudioElement;
    }[],
  }), 

  methods: {
    headers() {
      return [
        {
          text: i18n.t("misc.date"),
          value: "timestamp",
        },
        {
          text: i18n.t("misc.origin"),
          value: "callerId"
        },
        {
          text: i18n.t("misc.duration"),
          value: "callduration",
        },
        { 
          text: i18n.t("relatorios.totalTimeWait"), 
          value: "holdtime"
        },
        {
          text: i18n.t("misc.name"),
          value: "queue",
        },
        { 
          text: i18n.t("misc.status"), 
          value: "status"
        },
        {
          text: i18n.t("configuration.extension"),
          value: "exten",
        },
        { 
          text: i18n.t("misc.audio"), 
          value: "audio",
          width: "100px"
        },
      ]
    },

    async getData() {
      const headers = tokenHeader();
      this.list = [];

      await axios
        .post(`${action.QUEUE_CALL_STATUS}${this.queueId}`,this.body ,{ withCredentials: true, headers })
        .then((res) => {
          this.list = res.data;          
        })
        .catch(() => {
          alert("QUEUE_CALL_STATUS is not working");
        });

        for (let i = 0; i < this.list.length; i++) {
          this.stateIcons.push({ audio: "mdi-play" });
        }
        this.list.forEach(() => {
          this.playingSound.push({
            audio: new Audio(),
          });
        });
        this.loadingList = false;
    },

    setPlay(item: any) {
      let index = this.list.findIndex((el: any) => el?.audio == item.audio) 

      let icon = this.stateIcons[index].audio;
      
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }
      this.stateIcons[index].audio = icon;
    },

    async playSound(val: any) {
      let index = this.list.findIndex((el: any) => el?.audio == val.audio) 
      const file = val.audio;
      const url = config.url + "cdrs/getsound/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        console.log(url)
        this.playingSound[index].audio = new Audio(url);
        this.playingSound[index].audio.addEventListener("ended", () => {
          this.stateIcons[index].audio = "mdi-play";
        });
        this.playingSound[index].audio.play();
      } else {
        alert("Cannot load");
      }
    },

    pauseSound(val: any) {
      let index = this.list.findIndex((el: any) => el?.audio == val.audio) 
      this.playingSound[index].audio.pause();
    },

    async downloadFile(item: any) {
      const file = item.audio;
      const url = config.url + "cdrs/getsound/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.audio;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },

  computed: {
    computedList() {
      let cacheList = this.list as any;

      cacheList = cacheList.map((el: any) => ({
          ...el,
          queue: this.queueName,
          exten: el.exten? el.exten.split("_")[1] : "",
          status: i18n.t(`status.${el.status}`),
          timestamp: el.timestamp ? el.timestamp.split("T")[0] + " " + el.timestamp.split("T")[1] : ""
        })
      ) 
      
      return cacheList;
    }
  },
});

