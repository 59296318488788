


































































































































import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Corp, DestinationGroup, Extension, RestrictionCorp, RestrictionExtension } from "@/types/conf";
import store from "@/store/index";

export default Vue.extend({
  name: "RestrictionsExtenDetail",
  props: ["resToUpdate", "exten", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,
    showPass: false,

    newRes: {
      pattern: "",
      corpId: 0,
      groupId: 0,
      permit: 0,
      pin: "",
      type: null,
    } as RestrictionCorp,

    permitList: [
      {
        id: 0,
        name: i18n.t("misc.no"),
      },
      {
        id: 1,
        name: i18n.t("misc.yes"),
      },
      {
        id: 2,
        name: i18n.t("misc.pin"),
      },
    ],

    patternList: [] as DestinationGroup[],

    types: [
      {
        id: 1,
        value: "VoiceMailMain",
        name: i18n.t("actions.voiceMail"),
      },
      {
        id: 2,
        value: "MeetMe",
        name: i18n.t("actions.conferenceRoom"),
      },
      {
        id: 3,
        value: "Pattern",
        name: i18n.t("misc.pattern"),
      },
      {
        id: 4,
        value: "DestinationGroup",
        name: i18n.t("configurationSup.destinationGroup"),
      },
    ],

    corpList: [] as Corp[],
    extenList: [] as Extension[],
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
      onlyTandNums: (v: string) => /^(?!.*T.*T)[0-9T]*$/.test(v) || i18n.t("rules.numberTDot")
    },
  }),
  methods: {
    ...mapActions({
      getCorpList: "crudService/getList",
      getDestinationGroup: "crudService/getList",
      newRestrictionExten: "crudService/newObject",
      updateRestrictionExten: "crudService/updateObject",
    }),

    resDetailHandler(res: RestrictionCorp) {
      // Si el patrón incluye números.
      if(/^(?!.*T.*T)[0-9T]*$/.test(res.pattern)){
        this.newRes.type = this.types[2].id;
        this.newRes.pattern = res.pattern;
      } else {
        this.newRes.type = this.types.find(e => e.name == res.pattern);
        this.newRes.pattern = null;
      }

      if(!!res.groupId) this.newRes.type = this.types[3].id;
      
      this.newRes.permit = res.permit;
      this.newRes.pin = res.pin;
      this.newRes.groupId = res.groupId;
      this.newRes.corpId = res.corpId;
    },
    pattSelect(val:any){
  
      let idPattern = typeof val == "number" ? val : val.id;

      switch (idPattern) {
        case 1: return this.types[0].value
        case 2: return this.types[1].value
        case 3: return this.types[2].value
        case 4: return this.types[3].value
        default: return null;
      }
  },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        
        let newRes = {
          CorpId: this.newRes.corpId,
          GroupId: this.newRes.type == 4 ? this.newRes.groupId : null,
          Pattern: this.newRes.type == 3 ? this.newRes.pattern : this.pattSelect(this.newRes.type),
          Permit: parseInt(this.newRes.permit),
          Pin: this.newRes.permit == 2 ? this.newRes.pin : null,
        };

        let response;
        
        if (this.isToNew) {
          response = await this.newRestrictionExten({
            action: action.RESTRICTION_CORP_CONTROLLER,
            obj: newRes,
          });
        } else {
          response = await this.updateRestrictionExten({
            action: action.RESTRICTION_CORP_CONTROLLER,
            obj: {...newRes, Id: this.resToUpdate.id },
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isToNew).alertMessage;
        this.alertType = msgCodes(response, this.isToNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch("lookAndFeel/setCorpName");
    const corpName = store.state.lookAndFeel.corpName as string;
    if (!!to.params.exten) {
      next();
    } else {
      next({
        name: "extension",
        params: { corp: corpName, lang: navigator.language.slice(0, 2) },
      });
    }
  },

  async mounted() {

    if (!this.isToNew) {
      this.resDetailHandler(this.resToUpdate);
    }

    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    this.patternList = await this.getDestinationGroup(action.DESTINATION_GROUP_CONTROLLER);
    this.patternList = this.patternList.filter((e: any) => e.name != "Entrante");
    // this.patternList = this.patternList.map(e => ({...e, name: i18n.t(`destinationGroups.${e.name}`)}))
  },
});
