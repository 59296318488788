var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#522daa"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-music-note-eighth ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pt-2 pl-2"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('misc.editing'))+" "+_vm._s(_vm.$i18n.t('configuration.queueMoh'))+" ")])])],1),_c('v-card',{staticClass:"pa-6 pb-2",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',[_c('FileSizeInfo'),_c('v-file-input',{attrs:{"accept":"audio/*","counter":"","label":_vm.$t('misc.audio'),"placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":"","show-size":1024},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.obj.Sound),callback:function ($$v) {_vm.$set(_vm.obj, "Sound", $$v)},expression:"obj.Sound"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.itemList,"items-per-page":1,"fixed-header":"","height":48 *2,"search":_vm.search,"footer-props":{ 'items-per-page-text': ("" + (_vm.$t('misc.rowsPP'))) },"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name + ".wav")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.name)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[_vm.itemList.indexOf(item)]))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('misc.play')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.name)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('misc.download')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[(_vm.canDelete)?_c('v-icon',[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('misc.delete')))])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('misc.defaultConfirmation'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t('misc.cancel')))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-card-actions',[_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('misc.cancel'))+" ")])]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":"","disabled":!_vm.obj.Sound},on:{"click":_vm.addToList}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('misc.save'))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }