











































































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import SpeedDialCorpsDetail from "./SpeedDialCorpsDetail.vue";

import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { Queue, SpeedDialCorp, View } from "@/types/conf";

export default Vue.extend({
  name: "SpeedDialCorps",
  data: () => ({
    drawer: false,
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 4 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    queueList: [] as Queue[],
    alertType: "",
    alertMessage: "",
    speedDialList: [] as SpeedDialCorp[],
    selectedItem: null as SpeedDialCorp | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    sortBy: "number",
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  components: {
    CSV,
    CopyButton,
    Top,
    SpeedDialCorpsDetail
},
  async mounted() {
    this.loadingList = true;
    this.queueList = await this.getQueues(action.QUEUE_CONTROLLER);
    this.speedDialList = await this.getSpeedDialsList(action.SPEED_DIAL_CORP_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getQueues: "crudService/getList",
      getSpeedDialsList: "crudService/getList",
      deleteSpeedDial: "crudService/deleteObject",
    }),

    getQueueName(queue: any) {

      let enqueueFinded = this.queueList.find(e => `enqueue_${e.corpId}_${e.id}` == queue.action);
      if (enqueueFinded) return "Enfilar " + enqueueFinded.name;

      let login = this.queueList.find(e => `login_${e.corpId}_${e.id}` == queue.action);
      if(login) return "Login " + login.name;

      let logout = this.queueList.find(e => `logout_${e.corpId}_${e.id}` == queue.action);
      if(logout) return "Logout " + logout.name;

    },

    computedActions(item: SpeedDialCorp) {
      let basicActions = [
        {
          text: i18n.t("sdActions.voiceMailMain"),
          value: "VoiceMailMain",
        },
        {
          text: i18n.t("sdActions.meetMe"),
          value: "MeetMe",
        },
        {
          text: i18n.t("sdActions.pickup"),
          value: "Pickup",
        },
        {
          text: i18n.t("sdActions.chanSpy"),
          value: "ChanSpy",
        },
        {
          text: i18n.t("sdActions.whisper"),
          value: "Whisper",
        },
        {
          text: i18n.t("sdActions.reminder"),
          value: "Reminder",
        },
        {
          text: i18n.t("sdActions.forward"),
          value: "Forward",
        },
      ];
      let res = basicActions.find(action => action.value === item.action);
      return res ? res.text : "";
    },
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        { text: i18n.t("misc.num"), value: "number" },
        {
          text: i18n.t("misc.action"),
          align: "start",
          value: "action",
        },
      ];
    },
    actionHandler() {
      const view = this.views.find( (v: View) => v.name == views.SPEED_DIAL_CORP );
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: SpeedDialCorp) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteSpeedDial({
        action: action.SPEED_DIAL_CORP_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.speedDialList];
        this.speedDialList = itemListUpdated.filter(
          (speedDial) => speedDial.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.speedDialList = await this.getSpeedDialsList(action.SPEED_DIAL_CORP_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    }
  }, 
});
