




































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";

export default Vue.extend({
  props: [],
  data: () => ({
    days: 0,
    order: "",
    extenList: [] as any[],
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    await this.fetchExtenList();
    this.loadingList = false;
    this.startPolling();
  },
  methods: {
    ...mapActions({
      getExtenList: 'crudService/getList',
    }),
    async fetchExtenList() {
      const response = await this.getExtenList(action.ALL_EXTENSION);
      this.extenList = response;
    },
    startPolling() {
      setInterval(async () => {
        this.loadingList = true;
        await this.fetchExtenList();
        this.loadingList = false;
      }, 120000); // 120000 ms = 2 minutos
    },
    headers() {
      return [
        {
          text: i18n.t("configuration.corp"),
          value: "name",
        },
        {
          text: i18n.t("corpPanel.all"),
          value: "totalExt",
        },
        {
          text: i18n.t("misc.enabled"),
          value: "totalEnable"
        },
        {
          text: i18n.t("misc.disabled"),
          value: "totalDisable",
        },
      ];
    },
  },
});
