






























































































import Vue from "vue";

import { Extension, Queue } from "@/types/conf";
import { ExtenStatus } from "@/types/enums";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "RamaisList",
  data: () => ({
    queueId: null,
    dialog: false,
    expanded: [] as any[],
    search: "",
    searchQueues: "",
    selectedItem: null,

    computedItems: screen.width < 600 ? 1 : 5,

    currentPage: 0,
    itemsPerPage: 20,
    resultCount: 0,
  }),

  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
      extenList: "panel/extenList",
      queueList: "panel/queueList",
      callTimes: "panel/callTimes",
    }),
  },

  methods: {
    ...mapActions({
      deleteItem: "panel/deleteQueueMember",
      addToQueue: "panel/addQueueMember",
    }),
    
    extenHeaders() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          width: 20,
        },
        {
          text: "Nº",
          value: "number",
        },
        {
          text: this.$i18n.t("loginView.userName"),
          value: "userName",
        },
        {
          text: this.$i18n.t("misc.action"),
          value: "action",
        },
        {
          text: this.$i18n.t("misc.occupied"),
          value: "onbusy",
        },
        {
          text: this.$i18n.t("misc.enabled"),
          value: "enabled",
        },
      ];
    },

    color(item: Queue | Extension | any) {
      switch (item.status) {
        case 0:
          return "#abababaa"; // Disconnected
        case 1:
          return "light-blue lighten-2"; // In use
        case 2:
          return "deep-purple lighten-1"; // Ringing
        case 3:
          return "green accent-4"; // Connected
        case 4:
          return "red darken-2"; // Hang up
        case 5:
          return "amber darken-1"; // Music on Hold
        default:
          return "#abababaa";
      }
    },
    icon(item: Queue | Extension | any) {
      switch (item.status) {
        case ExtenStatus.Online:
          return "mdi-check"; // Connected
        case ExtenStatus.Offline:
          return "mdi-close"; // Disconnected
        case ExtenStatus.MOH:
          return "mdi-audio"; // Music on Hold
        default:
          return "mdi-phone";
      }
    },
  },

  
});
