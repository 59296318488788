import { LogmonState, RootState } from "@/types/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import Logmon from "@/plugins/logmonHub";
import { AstQueue, Extension, Queue } from "@/types/conf";
import { ExtenStatus } from "@/types/enums";
import store from "@/store";
import * as action from "@/store/actions";

const state: LogmonState = {

  log: null,

  extenList: [] as Extension[],
  queueList: [] as Queue[],
  callTimes: [] as { interval: null | number; time: string }[],
  currentCallList: [] as { caller?: string; receiver?: string }[],
  channelList: [],
  callHistory: [],
  dashExtensions: []
};

const getters: GetterTree<LogmonState, RootState> = {

  log(state): any {
    return state.log;
  },


  extenList(state): Extension[] {
    return state.extenList;
  },
  queueList(state): Queue[] {
    return state.queueList;
  },
  callTimes(state): { interval: null | number; time: string }[] {
    return state.callTimes;
  },
  currentCallList(state): { caller?: string; receiver?: string }[] {
    return state.currentCallList;
  },
  channelList(state): any[] {
    return state.channelList;
  },
  callHistory(state): any[] {
    return state.callHistory;
  },
  dashExtensions(state): any[] {
    return state.dashExtensions;
  }
};

const mutations: MutationTree<LogmonState> = {

  setLog(state, payload) {
    state.log = payload;
  },



  setDashExtensions(state, payload) {
    state.dashExtensions = payload;
  },
  setExtensionList(state, payload) {
    state.extenList = payload;
  },
  setQueueList(state, payload) {
    state.queueList = payload;
  },
  setChannelList(state, payload) {
    state.channelList = payload;
  },
  setExtenStatus(state, payload) {
    let ext = state.extenList[payload.id];
    if (ext) {
      ext.status = payload.status;
    }
  },
  mapExtenUsers(state, payload) {
    state.extenList.forEach((exten: Extension, index) => {
      const user = payload.find((user: any) => user.id == exten.userId);
      state.extenList[index].userName =
        user?.name || store.state.auth.user.nick;
    });
  },
  addQueueMember(state, payload) {
    state.queueList[payload.queueIndex].objects.push(
      logmon.state.extenList[payload.extIndex]
    );
  },
  setQueueMembers(state, payload) {
    state.queueList[payload.id].objects = state.queueList[
      payload.id
    ].objects.filter((e: Extension) => e.number != payload.number);
  },
  setQueueMemberStatus(state, payload) {
    state.queueList.forEach((queue) => {
      const index = queue.objects.findIndex(
        (item: any) => item.number == payload.number
      );
      if (index > -1) {
        queue.objects[index].status = payload.status;
      }
    });
  },
  setInterval(state, payload) {
    state.callTimes[payload.id].interval = payload.interval;
  },
  clearInterval(state, payload) {
    clearInterval(state.callTimes[payload].interval as number);
    state.callTimes[payload].interval = null;
  },
  resetTimeAndStatus(state, payload) {
    state.extenList[payload].status = ExtenStatus.Online;
    state.callTimes[payload].time = "00:00";
  },
  loadCallTimes(state) {
    state.extenList.forEach((e) => {
      state.callTimes.push({ interval: null, time: "00:00" });
    });
  },
  addActiveCall(state, payload) {
    state.currentCallList.push({
      caller: payload.caller,
      receiver: payload.receiver,
    });
  },

  removeActiveCall(state, payload) {
    state.currentCallList = state.currentCallList.filter((e: any) =>
      /* !Object.values(e).includes(payload.caller.number) && !Object.values(e).includes(payload.receiver.number) */
      (Object.values(e) as Extension[]).forEach((element: Extension) => {
        return (
          element.number != payload.caller.number ||
          element.number != payload.receiver.number
        );
      })
    );
  },

  removeActiveCallTransfer(state, payload) {
    state.currentCallList = state.currentCallList.filter(
      (e: any) => !Object.values(e).includes(payload.caller)
    );
  },

  addCallToHistory(state, payload) {
    state.callHistory.push({
      dateTime: payload.dateTime,
      caller: payload.caller,
      receiver: payload.receiver,
      duration: payload.duration,
    });
    if (state.callHistory.length > 5) {
      state.callHistory.shift();
    }
  },

};

const actions: ActionTree<LogmonState, RootState> = {
  async initLogmon({ commit }) {
    // await Logmon.start();
  },

  async guardarAlgoEnUnState({ commit }, { x, y, z }) {

    if (z) {
      commit("setQueueMembers", { id: y, number: x.number });
    }

    if (x) {
        commit("setQueueMembers", { id: y, number: x.number });
    }
  },

  async stopLogmon({ commit }) {
    await Logmon.stop();
  }
  
};

export const logmon = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

Logmon.client.on("LogMon", (param1, param2) => {
  let data = JSON.parse(param1)
  store.commit("logmon/setLog", data)
})

Logmon.client.on("AllChannels", async (channels: any) => {
  
  const list = await channels.map((channel: any) => {
    const regExp = /[-_]+/;
    const number = channel.name.split(regExp);
    return {
      id: channel.id,
      number: number[1],
    };
  });
  store.commit("logmon/setChannelList", list);
  logmon.state.extenList = logmon.state.extenList.map(
    (exten: Extension, index) => {
      logmon.state.callTimes[index].time =
        list.filter((channel: any) => channel.number == exten.number).length > 0
          ? "--:--"
          : "00:00";
      return {
        ...exten,
        channelList: list.filter(
          (channel: any) => channel.number == exten.number
        ),
        status:
          list.filter((channel: any) => channel.number == exten.number).length > 0
            ? ExtenStatus.InUse
            : exten.status, 
      };
    }
  );
});

Logmon.client.on("AllExtensions", async (extensions: any) => {

  const list = await extensions.map((exten: any) => {
    return {
      number: exten.number,
      pass: exten.pass,
      userId: exten.userId,
      trunkId: exten.trunkId,
      outer: exten.outer,
      enabled: exten.enabled,
      action: exten.action,
      onbusy: exten.onbusy,
      description: exten.description,
      spy: exten.spy,
      callLimit: exten.callLimit,
      portType: exten.portType,
      vmpass: exten.vmpass,
      maxContacts: exten.maxContacts,
      callgroup: exten.callgroup,
      channelList: exten.channelList,
      // status:
      //   exten.channelList != null && exten.channelList.length > 0
      //     ? 2
      //     : statusMapper(ExtenStatus[exten.state]),
    };
  });
  store.commit("logmon/setExtensionList", list);
});


//#endregion
