














import Vue from "vue";
export default Vue.extend({
  name: "NotFound",
  methods: {
    navigate() {
      const corpName = this.$store.state.lookAndFeel.corpName;
      this.$router.replace({
        name: "home",
        params: {
          corp: localStorage.getItem("corp") || "",
          lang: this.$i18n.locale,
        },
      });
    },
  },
});
