var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
      position: 'relative',
      left: '14px',
      top: '-6px',
    }),attrs:{"icon":"","small":"","color":"#522daa"},on:{"click":_vm.getData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-ip ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-2 pl-2",staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.exten)+" ")])])],1),_c('v-card',{staticClass:"pa-6 pb-2",staticStyle:{"overflow":"hidden"}},[_vm._l((_vm.ipArray),function(ip){return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('h4',{staticStyle:{"padding-right":"8px"}},[_vm._v("IP:")]),_c('span',{staticStyle:{"padding-right":"50px"}},[_vm._v(" "+_vm._s(ip.split(":")[0])+" ")]),_c('h4',{staticStyle:{"padding-right":"8px"}},[_vm._v("PORT:")]),_vm._v(" "+_vm._s(ip.split(":")[1])+" ")])}),_c('v-divider',{staticClass:"mt-5"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.closeBtn"))+" ")])]),_c('v-spacer')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }