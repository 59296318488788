var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',[_c('Top'),_c('div',{staticClass:"ma-2"},[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.questionList,"items-per-page":10,"fixed-header":"","height":48 * 11,"search":_vm.search,"sort-by":_vm.sortBy,"footer-props":{'items-per-page-text': ("" + (_vm.$t('misc.rowsPP')))},"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent mb-10",attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":"","color":_vm.menuFontColor},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{attrs:{"data":_vm.questionList ? _vm.questionList : null,"headers":_vm.headers(),"name":"questions"}}),_c('CopyButton',{attrs:{"headers":_vm.headers(),"copyData":_vm.questionList ? _vm.questionList : null}}),_c('v-card-title',[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"outlined":"","color":"#522daa"},on:{"click":_vm.validate}},'v-btn',attrs,false),on),[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.new"))+" ")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pt-2 pl-2"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("misc.creating") + " " + _vm.$t("configuration.questions"))+" ")])])],1),_c('v-card',{staticClass:"pa-6 pb-2",attrs:{"elevation":"0"}},[_c('FileSizeInfo'),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('misc.description'),"rules":[_vm.rules.required]},model:{value:(_vm.question.name),callback:function ($$v) {_vm.$set(_vm.question, "name", $$v)},expression:"question.name"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"accept":"audio/*","counter":"","label":_vm.$t('misc.audio'),"placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":"","show-size":1024,"rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.question.sound),callback:function ($$v) {_vm.$set(_vm.question, "sound", $$v)},expression:"question.sound"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.compOrderList,"label":_vm.$t('misc.order'),"rules":[_vm.rules.required]},model:{value:(_vm.question.orderSequencial),callback:function ($$v) {_vm.$set(_vm.question, "orderSequencial", $$v)},expression:"question.orderSequencial"}})],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-card-actions',[_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.cancel"))+" ")])]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":_vm.validate}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.save"))+" ")])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.defaultConfirmation"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.sound",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.sound)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":"","color":!!_vm.menuIconColor ? _vm.menuIconColor : ''},on:{"click":function($event){return _vm.setPlay(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[_vm.questionList.indexOf(item)]))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.sound)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":"","color":!!_vm.menuIconColor ? _vm.menuIconColor : ''},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }