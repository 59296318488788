var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',[_c('Top'),_c('div',{staticClass:"ma-2"},[_c('v-row',[_c('v-col',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pa-6 pb-2",attrs:{"elevation":"4"}},[_c('FileSizeInfo'),_c('v-file-input',{attrs:{"accept":"audio/*","label":_vm.$t('misc.audio'),"placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":""},model:{value:(_vm.obj.sound),callback:function ($$v) {_vm.$set(_vm.obj, "sound", $$v)},expression:"obj.sound"}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"outlined":"","color":"#522daa","disabled":!_vm.obj.sound},on:{"click":_vm.addToList}},[_vm._v(_vm._s(_vm.$t("misc.send")))])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.mohList,"items-per-page":_vm.computedItems,"fixed-header":"","height":_vm.computedHeight,"search":_vm.search,"footer-props":{ 'items-per-page-text': ("" + (_vm.$t('misc.rowsPP'))) },"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.name)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setPlay(item)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[_vm.mohList.indexOf(item)]))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("misc.play"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.name)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[(_vm.canDelete)?_c('v-icon',[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.delete")))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name + ".wav")+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("misc.defaultConfirmation"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("misc.cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }