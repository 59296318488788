import { auth } from "../services/auth";
import { crudService } from "@/services/crudService";
import { fileGetters } from "@/services/fileGetters";
import { lookAndFeel } from "@/services/lookAndFeel";
import { snackbar } from "@/services/snackbar";
import { panel } from "@/services/panelService";
import { logmon } from "@/services/logmonService"
import { token } from "@/services/token";


import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    crudService,
    fileGetters,
    lookAndFeel,
    snackbar,
    panel,
    logmon,
    token
  },
});

export default store;
