











































import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import config from "../../config.js";
import i18n from "@/plugins/i18n";

import { CorpsSpends } from "@/types/conf";

export default Vue.extend({
  props: ["numberOfDays", "kindOfCall", "callDuracion"],
  data: () => ({
    
    days: 0,
    order: "",

    corpsList: [] as CorpsSpends[],

    totals: {
      entrantes: 0,
      duracionEnt: 0,
      salientes: 0,
      duracionSal: 0,
      costo: 0,
    } as any,

    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  
  async mounted() {
    this.loadingList = true;

    switch (this.numberOfDays) {
      case i18n.t("corpPanel.today"):
        this.days = 0;
        break;

      case i18n.t("corpPanel.yesterday"):
        this.days = 1;
        break;
    
      case i18n.t("corpPanel.oneWeek"):
        this.days = 7;
        break;
    }

    switch (this.kindOfCall) {
      case i18n.t("corpPanel.in"):
        this.order = "entrantes";
        break;

      case i18n.t("corpPanel.out"):
        this.order = "salientes";
        break;
    
      default:
        this.order = "costo";
        break;
    }

    this.corpsList = await this.getCorpsList(`${config.url}dash/days/${this.days}/${this.order}/0/group/0`); 
    
    this.totals = {
      entrantes: 0,
      duracionEnt: 0,
      salientes: 0,
      duracionSal: 0,
      costo: 0
    }
    
    this.corpsList.forEach((element: any) => {
      this.totals.entrantes += element.entrantes;
      this.totals.duracionEnt += element.duracionEnt;
      this.totals.salientes += element.salientes;
      this.totals.duracionSal += element.duracionSal;
      this.totals.costo += element.costo;
      this.totals.costo = parseFloat(this.totals.costo.toFixed(2));
    })

    this.loadingList = false;
    
    this.$emit("manageSpend", this.totals.costo);
    
    this.intervals();
  },

  watch: {
    
    async numberOfDays(newVal, oldVal) {
      
      this.loadingList = true;

      switch (newVal) {
        case i18n.t("corpPanel.today"):
          this.days = 0;
          break;

        case i18n.t("corpPanel.yesterday"):
          this.days = 1;
          break;
      
        case i18n.t("corpPanel.oneWeek"):
          this.days = 7;
          break;
      }

      this.corpsList = await this.getCorpsList(`${config.url}dash/days/${this.days}/${this.order}/0/group/0`);

      this.totals = {
        entrantes: 0,
        duracionEnt: 0,
        salientes: 0,
        duracionSal: 0,
        costo: 0
      }

      this.corpsList.forEach((element: any) => {
        this.totals.entrantes += element.entrantes;
        this.totals.duracionEnt += element.duracionEnt;
        this.totals.salientes += element.salientes;
        this.totals.duracionSal += element.duracionSal;
        this.totals.costo += element.costo;
        this.totals.costo = parseFloat(this.totals.costo.toFixed(2));
      })

      this.loadingList = false;

      this.$emit("manageSpend", this.totals.costo);
    },

    async kindOfCall(newVal, oldVal) {
      
      this.loadingList = true;

      switch (newVal) {
        case i18n.t("corpPanel.in"):
          this.order = "entrantes"
          break;

        case i18n.t("corpPanel.out"):
          this.order = "salientes"
          break;
      
        default:
          this.order = "costo"
          break;
      }

      this.corpsList = await this.getCorpsList(`${config.url}dash/days/${this.days}/${this.order}/0/group/0`);

      this.totals = {
        entrantes: 0,
        duracionEnt: 0,
        salientes: 0,
        duracionSal: 0,
        costo: 0
      }

      this.corpsList.forEach((element: any) => {
        this.totals.entrantes += element.entrantes;
        this.totals.duracionEnt += element.duracionEnt;
        this.totals.salientes += element.salientes;
        this.totals.duracionSal += element.duracionSal;
        this.totals.costo += element.costo;
        this.totals.costo = parseFloat(this.totals.costo.toFixed(2));
      })

      this.loadingList = false;

      this.$emit("manageSpend", this.totals.costo);
    }, 
  },

  methods: {
    ...mapActions({
      getCorpsList: "crudService/getList",
    }),

    intervals() {
      setInterval(async () => {
        this.loadingList = true;

        switch (this.numberOfDays) {
          case i18n.t("corpPanel.today"):
            this.days = 0;
            break;

          case i18n.t("corpPanel.yesterday"):
            this.days = 1;
            break;
        
          case i18n.t("corpPanel.oneWeek"):
            this.days = 7;
            break;
        }

        switch (this.kindOfCall) {
          case i18n.t("corpPanel.in"):
            this.order = "entrantes";
            break;

          case i18n.t("corpPanel.out"):
            this.order = "salientes";
            break;
        
          default:
            this.order = "costo";
            break;
        }

        this.corpsList = await this.getCorpsList(`${config.url}dash/days/${this.days}/${this.order}/0/group/0`);

        this.totals = {
          entrantes: 0,
          duracionEnt: 0,
          salientes: 0,
          duracionSal: 0,
          costo: 0
        }
        
        this.corpsList.forEach((element: any) => {
          this.totals.entrantes += element.entrantes;
          this.totals.duracionEnt += element.duracionEnt;
          this.totals.salientes += element.salientes;
          this.totals.duracionSal += element.duracionSal;
          this.totals.costo += element.costo;
          this.totals.costo = parseFloat(this.totals.costo.toFixed(2));
        })

        this.loadingList = false;

        this.$emit("manageSpend", this.totals.costo);
        
      }, 60000);

    },

    headers() {

      if (this.kindOfCall === i18n.t("corpPanel.in")) {
        return [
          {
            text: i18n.t("configuration.corp"),
            value: "name",
          },
          {
            text: i18n.t("panel.numberOfCalls"),
            value: "entrantes",
          },
          {
            text: i18n.t("misc.minutes"),
            value: "duracionEnt",
          },
        ];
      }

      if (this.kindOfCall === i18n.t("corpPanel.out")) {
        return [
          {
            text: i18n.t("configuration.corp"),
            value: "name",
          },
          {
            text: i18n.t("panel.numberOfCalls"),
            value: "salientes",
          },
          {
            text: i18n.t("misc.minutes"),
            value: "duracionSal",
          },
          {
            text: "R$",
            value: "costo",
          },
        ];
      }

      return [
        {
          text: i18n.t("configuration.corp"),
          value: "name",
        },
        {
          text: "R$",
          value: "costo",
        },
        {
          text: i18n.t("corpPanel.in"),
          value: "entrantes",
        },
        {
          text: i18n.t("misc.minutes"),
          value: "duracionEnt",
        },
        {
          text: i18n.t("corpPanel.out"),
          value: "salientes",
        },
        {
          text: i18n.t("misc.minutes"),
          value: "duracionSal",
        },
      ];

    },
  }
});
