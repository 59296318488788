
























































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Server } from "@/types/conf";

export default Vue.extend({
  name: "ServersDetail",
  props: ["serverToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    server: {
      name: "",
      iP: "",
      port: null,
    } as Server,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
      nameRule: (v: string) =>
        v.match(/^[A-Za-z0-9\s]+$/g) || i18n.t("rules.serNumRule"),
      IPRule: (v: string) => v.match( /^[0-9]{2,6}(.){1}[0-9]{1,3}(.){1}[0-9]{1,3}(.){1}[0-9]{1,3}$/ ) || v.includes(".com") || i18n.t("rules.validIP"),
      portRule: (v: number) =>
        (v >= 10 && v < 100000000) || i18n.t("rules.between10and99999999"),
    },
    isNew: false,
  }),
  components: {   
    Top
  },
  methods: {
    ...mapActions({
      newServer: "crudService/newObject",
      updateServer: "crudService/updateObject",
    }),

    serverDetailHandler(server: any) {
      this.server.name = server.name;
      this.server.iP = server.ip;
      this.server.port = server.port;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = {
          id: null as any,
          Name: this.server.name,
          IP: this.server.iP,
          Port: parseInt(this.server.port),
        };

        let response;
        if (this.isNew) {
          response = await this.newServer({
            action: action.SERVER_CONTROLLER,
            obj: body,
          });
        } else {
          body.id = this.serverToUpdate.id;
          response = await this.updateServer({
            action: action.SERVER_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },
    goBack() {
      const newRoute = (this.$route.name as string).split("/")[0];
      this.$router.replace({
        name: newRoute,
      });
    },
  },
  async mounted() {
    const isNew =
      this.serverToUpdate == null || this.serverToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.serverDetailHandler(this.serverToUpdate);
    }
  },
});
