import { FileState, RootState } from "@/types/store/index";
import { Dispatch, Module, ActionTree } from "vuex";
import axios from "axios";
import config from "../config.js";
import { tokenHeader } from "./auth";
import store from "@/store/index";

const actions: ActionTree<FileState, RootState> = {
  async getFile(
    { dispatch },
    {
      fileName,
      fileType,
      endpointName,
    }: { fileName: string; fileType: string; endpointName: string }
  ) {
    const url = config.url + `${endpointName}/${fileType}/${fileName}`;
    const headers = tokenHeader();
    let response;

    await axios
      .get(url, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });
      });
    if (response) {
      return response;
    }
  },
};

export const fileGetters: Module<FileState, RootState> = {
  namespaced: true,
  actions,
};
