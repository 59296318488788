














































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Corp } from "@/types/conf";


export default Vue.extend({
  name: "UserDetail",
  props: ["userToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,
    selectedCorp: -1,
    showPass: false,
    showConfirmPass: false,
    password: "",
    level: "",
    confirmPassword: "",
    name: "",
    email: "",
    selectedRolegroup: 1,
    corpList: [] as Corp[],
    enabled: true,

    editPass: false,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
      nameRules: (v: string) => (v && v.length > 3 && v.length < 17) || i18n.t("loginView.userLength"),
    },
    isNew: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      menuIconColor: "lookAndFeel/menuIconColor",
    }),

    color(): string {
      return ["error", "warning", "success"][Math.floor(this.progress() / 40)];
    },
    levels(): { text: string; value: number }[] {
      switch (this.user.level) {
        case 1: // Supervisor
          return [
            {
              text: this.$i18n.t("permissions.supervisor") as string,
              value: 1,
            },
            {
              text: this.$i18n.t("permissions.administrator") as string,
              value: 3,
            },
            { 
              text: this.$i18n.t("permissions.user") as string,
              value: 4
            },
            { 
              text: "Monitor" as string,
              value: 5
            },
          ];
        case 2: // Dueño
          return [
            {
              text: this.$i18n.t("permissions.administrator") as string,
              value: 3,
            },
            { 
              text: this.$i18n.t("permissions.user") as string, 
              value: 4
            },
            { 
              text: "Monitor" as string,
              value: 5
            },
          ];
        case 3: // Administrador
          return [
            {
              text: this.$i18n.t("permissions.administrator") as string,
              value: 3,
            },
            { 
              text: this.$i18n.t("permissions.user") as string, 
              value: 4 
            },
            { 
              text: "Monitor" as string,
              value: 5
            },
          ];
        case 4: // Usuario
          return [];
        default:
          return [];
      }
    },
  },
  components: {
    Top
  },
  methods: {
    ...mapActions({
      getRoleGroupList: "crudService/getList",
      getCorpList: "crudService/getList",
      newUser: "crudService/newObject",
      updateUser: "crudService/updateObject",
      getUserDetail: "crudService/getDetail",
    }),
    progress(): number {
      return Math.min(100, this.password.length * 10);
    },
    confirmPasswordRules(pass: string) {
      return pass == this.password ? true : i18n.t("loginView.confirmMsgPass");
    },
    passwordRules(pass: string) {
      return pass.length > 3 ? true : i18n.t("loginView.msgPass");
    },
    userDetailHandler(user: any) {
      this.name = user.nick;
      this.password = "";
      this.confirmPassword = "";
      this.email = user.mail;
      this.level = user.level;
      this.selectedCorp = user.corpId;
      this.selectedRolegroup = user.roleGroupId;
      this.enabled = user.enabled == 1 ? true : false;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let newUser = {
          id: null as any,
          level: this.level,
          enabled: this.enabled ? 1 : 0,
          nick: this.name,
          pass: this.password,
          corpId: this.user.level <= 2 ? this.selectedCorp : this.user.corpId,
          roleGroupId: this.selectedRolegroup,
          mail: this.email,
        };
        let response;
        if (this.isNew) {
          response = await this.newUser({
            action:  action.USER_CONTROLLER,
            obj: newUser,
          });
        } else {
          newUser.id = this.userToUpdate.id;
          response = await this.updateUser({
            action: action.USER_CONTROLLER,
            obj: newUser,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;
        
        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
        this.$emit("createdUser")
      }
    },
  },
  async mounted() {
    const isNew = this.userToUpdate == null || this.userToUpdate == "undefined";
    this.isNew = isNew;
    if (!isNew) {
      const user = await this.getUserDetail({
        action: action.USER_CONTROLLER,
        id: this.userToUpdate.id,
      });
      this.$nextTick(() => {
        this.userDetailHandler(user);
      });
    }
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
  },
});
