



























































































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import UserDetail from "./UserDetail.vue";

import { mapActions, mapGetters } from "vuex";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { User, View } from "@/types/conf";
import msgCodes from "@/extendedFunctions/msgCodes";

export default Vue.extend({
  name: "Users",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    userList: [] as User[],
    selectedUser: null as User | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,

    csvKey: 0
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      user: "auth/user",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
    compUserList(): User[] {
      let condition: Function;
      switch (this.user.level) {
        case 1:
          condition = (user: User) => user;
          break;
        default:
          condition = (user: User) => user.corpId === this.user.corpId;
          break;
      }
      return this.userList.filter((user) => condition(user)).map(el => ({
          ...el,
          level: el.level == "1" ? i18n.t("permissions.supervisor")
               : el.level == "2" ? i18n.t("permissions.owner")
               : el.level == "3" ? i18n.t("permissions.administrator")
               : el.level == "4" ? i18n.t("permissions.user")
               : el.level == "5" ? "Monitor" : ""
        }) as any
      )
    },
  },

  async mounted() {
    // This make the CSV rerender when the list change, so the csv headers change too.
    this.csvKey += 1;

    this.loadingList = true;
    this.userList = await this.getUserList(action.USER_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    UserDetail
  },

  methods: {
    ...mapActions({
      getUserList: "crudService/getList",
      deleteUser: "crudService/deleteObject",
    }),

    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
        },
        {
          text: i18n.t("misc.name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { 
          text: "Email",
          value: "mail"
        },
        { 
          text: i18n.t("misc.level"),
          value: "level"
        },

        { 
          text: i18n.t("configuration.corp"),
          value: "corpName"
        },
        /* { text: i18n.t("configuration.roleGroup"), value: "roleGroupName" }, */
       
      ];
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.USERS);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: User) {
      this.selectedUser = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteUser({
        action: action.USER_CONTROLLER,
        id: this.selectedUser?.id,
      });
      
      // This set if the form must persist or don't
      this.dialogDelete = response >= 400;

      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    async refreshList() {
      this.loadingList = true;
      this.userList = await this.getUserList(action.USER_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
