import { GetterTree, MutationTree, ActionTree } from "vuex";
import axios from "axios";
import * as action from "@/store/actions";
import { mainBus } from "@/main";
import store from "@/store/index";
import { LookAndFeelState, RootState } from "@/types/store/index";

const tokenHeader = () => {
  const token = localStorage.getItem("token");
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return header;
};

const state: LookAndFeelState = {
  url: "",
  corpName: null,
  menuIconColor: null,
  menuFontColor: null,
  hoverColor: null,
  loginImage: null,
  loginBottomImage: null,
  favIconImage: null,
  menuColor: null,
  backgroundColor: null,
  loginTextColor: null
};
const getters: GetterTree<LookAndFeelState, RootState> = {
  url(state) {
    return state.url;
  },
  corpName(state) {
    return state.corpName;
  },
  menuIconColor(state) {
    return state.menuIconColor;
  },
  menuFontColor(state) {
    return state.menuFontColor;
  },
  hoverColor(state) {
    return state.hoverColor;
  },

  menuColor(state) {
    return state.menuColor;
  },
  backgroundColor(state) {
    return state.backgroundColor;
  },
  loginTextColor(state) {
    return state.loginTextColor;
  },

  loginImage(state) {
    return state.loginImage;
  },
  loginBottomImage(state) {
    return state.loginBottomImage;
  },
  favIconImage(state) {
    return state.favIconImage;
  },
};
const mutations: MutationTree<LookAndFeelState> = {
  SET_CORP_URL(state, url) {
    state.url = url;
  },
  SET_CORP_NAME(state, corpName) {
    state.corpName = corpName;
  },
  SET_MENU_ICON_COLOR(state, menuIconColor) {
    state.menuIconColor = menuIconColor;
  },
  SET_MENU_FONT_COLOR(state, menuFontColor) {
    state.menuFontColor = menuFontColor;
  },
  SET_LOGIN_TEXT_COLOR(state, loginTextColor) {
    state.loginTextColor = loginTextColor;
  },
  SET_HOVER_COLOR(state, hoverColor) {
    state.hoverColor = hoverColor;
  },

  SET_MENU_COLOR(state, menuColor) {
    state.menuColor = menuColor;
  },
  SET_BACKGROUND_COLOR(state, backgroundColor) {
    state.backgroundColor = backgroundColor;
  },

  SET_LOOKANDFEEL(state, lookandfeel) {
    state.menuIconColor = lookandfeel.menuIconColor;
    state.menuFontColor = lookandfeel.menuFontColor;
    state.hoverColor = lookandfeel.hoverColor;

    state.menuColor = lookandfeel.menuColor;
    state.backgroundColor = lookandfeel.backgroundColor;
    state.loginTextColor = lookandfeel.loginTextColor;

    localStorage.setItem('menuIconColor', lookandfeel.menuIconColor);
    localStorage.setItem('menuFontColor', lookandfeel.menuFontColor);
    localStorage.setItem('hoverColor', lookandfeel.hoverColor);

    localStorage.setItem('menuColor', lookandfeel.menuColor);
    localStorage.setItem('backgroundColor', lookandfeel.backgroundColor);
    localStorage.setItem('loginTextColor', lookandfeel.loginTextColor);

    state.loginBottomImage = lookandfeel.loginBottomImage;
    state.favIconImage = lookandfeel.favIconImage;
    state.loginImage = lookandfeel.loginImage;
  },
  SET_IMAGES(state, images) {
    state.loginImage =  images["loginImage"];
    state.loginBottomImage =  images["loginBottomImage"];
    state.favIconImage = images["favIconImage"];

    const favicon = document.getElementById("favicon") as HTMLLinkElement;
   
      if (images["favIconImage"]){
        favicon.href = images["favIconImage"];

      }else{
        favicon.href ="data:image/x-icon;base64,AAABAAEAICAQYwAAAADoAgAAFgAAACgAAAAgAAAAQAAAAAEABAAAAAAAgAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAACAAAAAgIAAgAAAAIAAgACAgAAAgICAAMDAwAAAAP8AAP8AAAD//wD/AAAA/wD/AP//AAD///8A////////+Id4j////////////////4h3d4j///////////////+HERl4//////////////+IdRERV4j////////////4hxERERF4j///////////iHkRGZERl4j/////////+IcREXd3ERl4j////////4h1EReIh3ERV4j///////iHERF3j/h3ERF4j/////+IdREXeP//h3ERV4j////4hxERd4////h3ERl4j///iHkRF3j/////h3ERl4j/+IcREXeP/4iI//h3ERF4j/h3mZd4//iHeI//h3mZd4/4iHeIj/+Id3eP//iId4iP+IiIiP//h3AHeI//iIiIj/iIiIj//4dwAHeP/4iIiI/4d3d4j/+IdwAHeIiHd3eP+HcAB3j/+IdwAHd3cAB3j/h3AAB3j/+IdwAAdwAAd4//h3cAB3j/+IdwAAAAN3j///iHAAB3j//4cwAAAHiP////iHcAB3j/+IdwAHeI//////iHAAB3j//4cAB4j///////iHcAB3j/+Id3iP////////iHAAB3j/+IiI//////////iHcAB3iP/4j///////////iHAAB3j///////////////iHcAB4j///////////////iHAAB4////////////////iHd3iP////////////////h3d4////////8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==";

      }
  },
};
const actions: ActionTree<LookAndFeelState, RootState> = {
  setMenuIconColor({ commit }, menuIconColor) {
    commit("SET_MENU_ICON_COLOR", menuIconColor);
  },
  setMenuFontColor({ commit }, menuFontColor) {
    commit("SET_MENU_FONT_COLOR", menuFontColor);
  },
  setHoverColor({ commit }, hoverColor) {
    commit("SET_HOVER_COLOR", hoverColor);
  },

  setMenuColor({ commit }, menuColor) {
    commit("SET_MENU_COLOR", menuColor);
  },
  setBackgroundColor({ commit }, backgroundColor) {
    commit("SET_BACKGROUND_COLOR", backgroundColor);
  },
  setLoginTextColor({ commit }, loginTextColor) {
    commit("SET_LOGIN_TEXT_COLOR", loginTextColor);
  },

  setLookandfeel({ commit }, lookandfeel) {
    commit("SET_LOOKANDFEEL", lookandfeel);
  },
  async setImages({ state, commit }) {
    let result: any; 
    await axios
      .get(action.LOOK_AND_FEEL + state.corpName)
      .then(async (response) => {
        result = response.data;
      })
      .then(() => {
        commit("SET_IMAGES", result);
      })
      .catch((error) => {
        console.warn(error);
      });
  },

  async checkLookandfeel({ dispatch, state }) {
    if (state.corpName === null || state.corpName === undefined) {
      await dispatch("setCorpName");
    }
    dispatch("getLookandfeel");
  },
  async setCorpName({ commit }) {
    let arr = window.location.href.split("/");
    let corpName = arr.length > 5 ? arr[3] : arr[4];
    commit("SET_CORP_NAME", corpName);
  },
  
  async getLookandfeel({ state, dispatch }) {
    let response;
    await axios
      .get(action.LOOK_AND_FEEL + state.corpName)
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });
      });

    if (response) {
      dispatch("setLookandfeel", response).then(() => {
        dispatch("setImages");
      });
      mainBus.$emit(action.RELOAD_LOOK_AND_FEEL);
    }
  },
  async postLookandfeel({ dispatch }, obj) {
    let response;
    let status;
    const headers = tokenHeader();

    await axios
      .post(action.POST_LOOK_AND_FEEL, obj, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });

        status = error.status;
      });

    if (!!status && status < 300) {
      return true;
    } else {
      return false;
    }
  },
};

export const lookAndFeel = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
