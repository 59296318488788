<template>
  <div class="py-4 ma-auto" >
    <apexchart
      ref="realTimeBarChart"
      type="bar"
      height="237"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";

import config from "../../config.js";

  export default Vue.extend({
    props: ["kindOfCall", "numberOfDays"],
    components: {
      apexchart: VueApexCharts,
    },

    data: () => ({

      cutArray: 6,
      type: i18n.t("corpPanel.all"),
      days: 1,
      list: {},

      series: [ { data: [] } ],
      chartOptions: {
        chart: {
          offsetY: -15,
          type: 'bar',
          // El toolbar muestra u oculta el botón de descargar en svg o pdf.
          toolbar: {
            tools: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "center"
            },
          }
        },
        colors: ['#4fc3f7', '#ffb300', '#00c853', '#90a4ae', '#7e57c2', '#d32f2f'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#000'],
            fontSize: '12px',
            fontWeight: "100"
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: false
          }
        },
        
        xaxis: {
          categories: [],
          labels: {
            show: true
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
        },
        yaxis: {
          labels: {
            show: false
          }
        },

        toolbar: {
          tools: false
        },
        
        legend: {
          show: false,
          position: 'right',
          offsetX: 0,
          offsetY: 0,
          formatter: function(val, opt) {
            // return val + ": " + this.numberSeries[v.seriesIndex]
            return ""
          }
        },
        
        tooltip: {
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      },
    }),

    async mounted() {

      switch (this.numberOfDays) {
        case i18n.t("corpPanel.today"):
          this.days = 0;
          break;

        case i18n.t("corpPanel.yesterday"):
          this.days = 1;
          break;
      
        case i18n.t("corpPanel.oneWeek"):
          this.days = 7;
          break;
      }

      this.handleUpdate()
      this.intervals();
    },

    watch: {
      async kindOfCall(newVal, oldVal) {

        switch (newVal) {
          case i18n.t("corpPanel.in"):
            this.type = newVal;
            break;

          case i18n.t("corpPanel.out"):
            this.type = newVal;
            break;
        
          default:
            this.type = "";
            break;
        }
        
        await this.handleUpdate()
      },

      async numberOfDays(newVal, oldVal) {

        switch (this.numberOfDays) {
          case i18n.t("corpPanel.today"):
            this.days = 0;
            break;

          case i18n.t("corpPanel.yesterday"):
            this.days = 1;
            break;
        
          case i18n.t("corpPanel.oneWeek"):
            this.days = 7;
            break;
        }

        await this.handleUpdate()
      } 
    },

    methods: {
      ...mapActions({
        getData: "crudService/getList",
      }),

      async getSerie() {
        let response =  await this.getData(`${config.url}dash/kpi/days/${this.days}`);
        let data = response[0];
        let sortedArray = [];

        delete data.totalEntrantes;
        delete data.totalSalientes;
        delete data.totalMinEntrantes;
        delete data.totalMinSalientes;

        Object.keys(data).forEach(element => sortedArray.push([element, data[element]]) );

        sortedArray.sort((a, b) => b[1] - a[1]);

        sortedArray = sortedArray.filter(item => item[1]);

        let callInArray = [];
        let callOutArray = [];

        sortedArray.forEach(element => element[0].substring(0, 6) === "totalE" ? callInArray.push(element) : callOutArray.push(element) );

        this.list = {};

        if (this.type === i18n.t("corpPanel.in")) {
          callInArray.forEach(element => {
            element[0] = element[0].substring(5)
            element[0] = i18n.t(`corpPanel.${element[0]}`)
            this.list[element[0]]=element[1];
          })
        }

        if (this.type === i18n.t("corpPanel.out")) {
          callOutArray.forEach(element => {
            element[0] = i18n.t(`corpPanel.${element[0].slice(5)}`)
            this.list[element[0]]=element[1] ;
          })
        }

        if (this.type !== i18n.t("corpPanel.in") && this.type !== i18n.t("corpPanel.out")) {
          sortedArray.forEach(element => {
            element[0] = element[0].slice(5);
            if (element[0].charAt(0) === "E") element[0] = i18n.t("corpPanel.in") + " " + i18n.t(`corpPanel.${element[0]}`)
            if (element[0].charAt(0) === "S") element[0] = i18n.t("corpPanel.out") + " " + i18n.t(`corpPanel.${element[0]}`)
            
            this.list[element[0]]=element[1];
          })
        }
      },

      updateChart(keys, values) {
        this.$refs.realTimeBarChart.updateOptions({ xaxis: { categories: keys} })
        this.$refs.realTimeBarChart.updateSeries([ { data: values } ]);
      },

      async handleUpdate() {

        await this.getSerie();

        let keys = [];
        let keyCache = Object.keys(this.list);
        
        let values = [];
        let valueCache = Object.values(this.list);

        if (keyCache.length >= this.cutArray) {
          let firstKeys = keyCache.slice(0, this.cutArray);
          keys = [...firstKeys, "Others"];
        } else {
          keys = keyCache;
        }

        if (valueCache.length >= this.cutArray) {
          let firstValues = valueCache.slice(0, this.cutArray);
          let lastValue = valueCache.slice(this.cutArray).reduce((acc, current) => acc + current, 0);

          values = [...firstValues, lastValue];
        } else {
          values = valueCache;
        }

        this.updateChart(keys,values);
      },

      intervals() {
        setInterval(async () => {
          await this.handleUpdate()
        }, 60000);

      },
    }
  })
</script>