




























































































































































































































































import Vue from "vue";

import ThemeSwitcher from "@/components/templates/ThemeSwitcher.vue";
import LanguageSwitcher from "@/components/templates/LanguageSwitcher.vue";
import Top from "@/components/templates/Top.vue";
import LineChart from "@/components/stats/LineChart.vue";
import StatCard from "@/components/stats/StatCard.vue";

import { mapActions, mapGetters } from "vuex";
import store from "@/store/index";
import { ExtenStatus } from "@/types/enums";
import { Extension } from "@/types/conf";
import i18n from "@/plugins/i18n";
import RamaisList from "./Dashboard/RamaisList.vue";
import ActiveCalls from "./panel/ActiveCalls.vue";
import ListView from "./panel/ListView.vue";

import config from "../config.js"
import CallPeriods from "./Dashboard/CallPeriods.vue";
import ExtensionUsed from "./Dashboard/Admin/ExtensionUsed.vue";
import GeneralView from "./Dashboard/GeneralView.vue";
import QueueUsed from "./Dashboard/Admin/QueueUsed.vue";

export default Vue.extend({
  name: "Home",
  data() {

    return {
      inCalls: 0,
      outCalls: 0,
      spend: 0,

      fav: true,
      menu: false,
      message: false,
      hints: true,
      loaded: false,
      headers: [
        { text: i18n.t("misc.date"), width: "40%" },
        { text: i18n.t("misc.call"), width: "30%" },
        { text: i18n.t("misc.duration"), width: "30%" },
      ],
    };
  },
  components: {
    LineChart,
    StatCard,
    ThemeSwitcher,
    LanguageSwitcher,
    Top,
    RamaisList,
    ActiveCalls,
    ListView,
    CallPeriods,
    GeneralView,
    ExtensionUsed,
    QueueUsed
},
  computed: {
    ...mapGetters({
      currentCallList: "panel/currentCallList",
      callHistory: "panel/callHistory",
      extenList: "panel/extenList",
      menuFontColor: "lookAndFeel/menuFontColor",
      user: "auth/user",
      exchange: "auth/exchange",
    }),
    currentCalls(): number {
      return 0 || this.currentCallList.length;
    },
    onHoldCalls(): number {
      return this.extenList.filter(
        (exten: Extension) => exten.status === ExtenStatus.MOH
      ).length;
    },
    registered(): number {
      return this.extenList.filter((exten: Extension) => exten.status !== ExtenStatus.Offline).length;
    },
    unregistered(): number {
      return this.extenList.filter(
        (exten: Extension) => exten.status === ExtenStatus.Offline
      ).length;
    },
  },
  methods: {
    ...mapActions({
      getCorpsList: "crudService/getList",
    }),
  },
  async mounted() {
    store
      .dispatch("lookAndFeel/getLookandfeel")
      .then(() => {
        this.loaded = true;
      });

    let response1 =  await this.getCorpsList(`${config.url}dash/kpi/days/${0}`);
    this.inCalls = response1[0].totalEntrantes;
    this.outCalls = response1[0].totalSalientes;

    let response2 =  await this.getCorpsList(`${config.url}dash/days/0/costo/0/group/0`);
    this.spend = response2.reduce((accumulator: any, element: any) => accumulator + element.costo, 0);
    this.spend = parseFloat(this.spend.toFixed(2));

    setInterval(async () => {
      let response1 =  await this.getCorpsList(`${config.url}dash/kpi/days/${0}`);
      this.inCalls = response1[0].totalEntrantes;
      this.outCalls = response1[0].totalSalientes;

      let response2 =  await this.getCorpsList(`${config.url}dash/days/0/costo/0/group/0`);
      this.spend = response2.reduce((accumulator: any, element: any) => accumulator + element.costo, 0);
      this.spend = parseFloat(this.spend.toFixed(2));

    }, 60000)
  },
});
