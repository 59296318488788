






















































































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import IVRStatisticsResume from "./IVRStatisticsResume.vue";

import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { mapActions, mapGetters } from "vuex";
import { tokenHeader } from "@/services/auth";
import axios from "axios";

export default Vue.extend({
  name: "IVRStatisticsSearch",
  
  data: () => ({
    selectedIvr: "",
    dateFromModel: false,
    dateFrom: new Date().toJSON().slice(0, 10),
    hourFrom: "00:00",
    dateToModel: false,
    dateTo: new Date().toJSON().slice(0, 10),
    hourToModel: false,
    hourTo: "23:59",

    ivrsList: [] as any,

    results: [] as any,
    body: {} as any,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
  }),
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateIVRsList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },
  components: {
    Top,
    IVRStatisticsResume
  },
  
  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      getExtensionsList: "crudService/getList",
    }),
    dateRules() {
      return this.dateFrom < this.dateTo ? true : i18n.t("rules.dateError");
    },

    async updateIVRsList() {
      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push( { text: i18n.t("corpPanel.all") , value: "0" } );
      for (const key in actionList.ivrs) {
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }
      
      this.ivrsList = arr;
    },

    async onSubmit() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        this.body = {
          from: this.dateFrom + "T" + this.hourFrom,
          to: this.dateTo + "T" + this.hourTo,
        };

        let selectedIvr = this.selectedIvr != "0" ? this.selectedIvr.slice(3) : 0;

        const headers = tokenHeader();

        await axios
          .post(`${action.IVR_STATISTICS}${selectedIvr}`, this.body, { withCredentials: true, headers })
          .then((res) => {
            this.results = res.data;
          })
          .catch(() => {
            alert("IVR_STATISTICS is not working");
          });

      }
    },
  },
});
