import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import pt from "@/locales/pt.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
 
  return {en, es, pt};
}


function autoselectLocale(){

  var ln = navigator.language;
  /*Validar que no se encuentre en la pagina correspondiente a su idioma*/
  
  if (ln == 'en-EN') {
      return 'en' 
  } else if (ln == 'es-ES' || ln=='es-AR') {
      return 'es'
  } else if (ln == 'pt-BR'){
      return 'pt'
  } else{
    return 'pt' 
  }

}
export default new VueI18n({
  

  locale: autoselectLocale(),
  fallbackLocale: autoselectLocale(),
  messages: loadLocaleMessages(),


});
