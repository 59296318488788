







































































































































































































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Time, TimeOption } from "@/types/conf";

export default Vue.extend({
  name: "TimeDetail",
  props: ["timeToUpdate", "isToEdit", "isToNew"],
  components: {
    Top,
  },
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    menu: false,
    menu2: false,
    time: null as any,
    model: 1,

    newTimemain: {
      id: "",
      name: "",
      default: "",
    } as Time,

    newTimeOption: {
      from: null as any,
      to: null as any,
      days: [] as any[],
      action: "",
    } as TimeOption,
    timeOptions: [] as TimeOption[],
    actionList: [] as any[],
    idsTOtoDelete: [] as any[],
    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  computed: {
    computedActions(): { text: string; value: string; disabled: boolean; }[] {
      return this.actionList.map((item) => {
        return {
          text:  item.text,        
          value: item.value,  
          disabled: this.exceptionList.includes(item.text),
        };
      });
    },
  },
  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      getAllTimeOptions: "crudService/getList",
      newTimeMain: "crudService/newObject",
      updateTimeMain: "crudService/updateObject",
    }),

    nameRules(t: string) {
      return t.match(/^[a-zA-Z0-9_+*.@ -]*$/)
        ? true
        : i18n.t("rules.alphanumAndSpecial");
    },

    fromToRules(from: number, to: number) {
      return from < to || (from == null && to == null)
        ? true
        : i18n.t("rules.secondHour");
    },

    getActionText(actionValue: string) {

      const filteredArray = this.computedActions.filter(e => e.value == actionValue);
      
      return filteredArray.length > 0 ? filteredArray[0].text : "";
    },

    manageDays(day: string) {

      let isAlready = this.newTimeOption.days.includes(day);

      if (isAlready) {
        this.newTimeOption.days = this.newTimeOption.days.filter((e: any) => e != day)
      } else {
        this.newTimeOption.days.push(day);
      }
      
    },
    joinedDays(item: TimeOption) {

      if (typeof item.days != "string") {
 
        let daysArray = item.days[0].split("-");
        let translatedDays = [] as any;

        daysArray.forEach((e: any) => translatedDays.push( i18n.t("days." + e) ))

        return translatedDays.join(", ");
      } 
      else {
        return item.days;
      } 
    },

    addToOptions() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        let newDays = this.newTimeOption.days.join("-");

        // repeated days validation
        let daysInUse: any = [];
        this.timeOptions.forEach(e => daysInUse.push(e.days[0].split("-")))
        daysInUse = daysInUse.flat(Infinity);

        const dayAlreadyUsed = daysInUse.some((e: any) => this.newTimeOption.days.includes(e))

        //repeated hour validation

        if(dayAlreadyUsed) {
          
          let hoursInUse: any = [];
          this.timeOptions.forEach(e => hoursInUse.push({from: e.from, to: e.to}));

          let hourAlreadyUsed = false;

          let newFrom = this.newTimeOption.from;
          let newTo = this.newTimeOption.to;

          hoursInUse.forEach((e: any) => {
            
            let isInUse = 
              newFrom <= e.from && newTo >= e.from || 
              newFrom <= e.from && newTo >= e.to || 
              newFrom >= e.from && newFrom <= e.to ||
              newFrom >= e.from && newTo <= e.to

            if (isInUse) hourAlreadyUsed = true; 
          });

          if(hourAlreadyUsed) {
            this.$store.dispatch("snackbar/showSnack", {
              message: i18n.t('misc.dataExist'),
              color: "error",
              timeout: 3000,
            });

            return;
          };
        }

        //set
        this.timeOptions.push({ ...this.newTimeOption });
        this.timeOptions[this.timeOptions.length - 1].days = [newDays];

        //reset
        // this.newTimeOption.from = null;
        // this.newTimeOption.to = null;
        // this.newTimeOption.action = "";
      }
    },

    removeOption(item: TimeOption) {
      this.timeOptions.includes(item)
        ? this.timeOptions.splice(this.timeOptions.indexOf(item, 0), 1)
        : null;
    },

    deleteOption(item: TimeOption) {
      this.removeOption(item);
      this.idsTOtoDelete.push(item.id);
    },
    addButtonDisabled() {
      return this.newTimeOption.days.length != 0 &&
        this.newTimeOption.from != null &&
        this.newTimeOption.to != null &&
        this.newTimeOption.action != ""
        ? true
        : false;
    },

    timeMainDetailHandler(time: Time) {
      this.newTimemain.id = time.id;
      this.newTimemain.name = time.name;
      this.newTimemain.default = time.default;
    },

    async validate() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let time = {
          id: null as any,
          name: this.newTimemain.name,
          default: this.newTimemain.default,
          options: this.timeOptions as any,
          idsTOtoDelete: null as any,
        };
        
        if (!!this.newTimemain.default) {
          if (actionList.queues[this.newTimemain.default]) time.default = actionList.queues[this.newTimemain.default]
          else time.default = this.newTimemain.default
        }

        time.options.forEach((element: any) => {
          element.days = element.days[0];
        })

        let response;
        if (this.isNew) {
          response = await this.newTimeMain({
            action: action.TIME_MAIN_CONTROLLER,
            obj: time,
          });
        } else {
          time.id = this.timeToUpdate.id;
          time.idsTOtoDelete = this.idsTOtoDelete;

          response = await this.updateTimeMain({
            action: action.TIME_MAIN_CONTROLLER,
            obj: time,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400
        
        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }

      this.$emit("refreshList", this.dialog)

    },
    async timeOptionsDetailHandler(time: TimeOption) {
      const arr = await this.getAllTimeOptions(action.TIME_OPTION_CONTROLLER);
      for (let timeOpt of arr) {
        if (timeOpt.timeId === time.id) {

          

          const obj = {
            id: timeOpt.id,
            timeId: timeOpt.timeId,
            from: timeOpt.from,
            to: timeOpt.to,
            days: timeOpt.days.split(", "),
            action: timeOpt.action,
          };
          this.timeOptions.push(obj);
        }
      }
    },

    async updateActionList() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateActionList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },
  async mounted() {
    const isNew = this.timeToUpdate == null || this.timeToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.timeMainDetailHandler(this.timeToUpdate);
      this.timeOptionsDetailHandler(this.timeToUpdate);
    }    
  },
});
