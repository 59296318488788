




















































































































































































































































































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import i18n from "@/plugins/i18n";
import { Extension, Queue } from "@/types/conf";
import { ExtenStatus } from "@/types/enums";
import { mapActions, mapGetters } from "vuex";
import RamaisList from "../Dashboard/RamaisList.vue";

export default Vue.extend({
  name: "ListView",
  data: () => ({
    queueId: null,
    dialog: false,
    expanded: [] as any[],
    search: "",
    searchQueues: "",
    selectedItem: null,

    loadingList: false,
    currentPage: 0,
    itemsPerPage: 20,
    resultCount: 0,
  }),
  components: {
    Top,
    RamaisList
},
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
      extenList: "panel/extenList",
      queueList: "panel/queueList",
      callTimes: "panel/callTimes",
    }),
  },
  methods: {
    ...mapActions({
      deleteItem: "panel/deleteQueueMember",
      addToQueue: "panel/addQueueMember",
    }),

    queueHeaders() {
      return [
        {
          text: this.$i18n.t("misc.name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$i18n.t("misc.strategy"),
          align: "start",
          value: "strategy",
        },
        {
          text: this.$i18n.t("misc.timeout") + " (s)",
          align: "start",
          value: "timeout",
        },
        { text: "Expand", value: "data-table-expand" },
      ];
    },
    extenHeaders() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          width: "10%",
        },
        {
          text: "Nº",
          align: "start",
          value: "number",
        },
        {
          text: this.$i18n.t("loginView.userName"),
          align: "start",
          value: "userName",
        },
        {
          text: this.$i18n.t("misc.callTime"),
          align: "start",
          value: "time",
        },
        {
          text: "Status",
          value: "status",
          align: "left",
          width: "10%",
        },
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
      ];
    },

    color(item: Queue | Extension | any) {
      switch (item.status) {
        case 0:
          return "#abababaa"; // Disconnected
        case 1:
          return "light-blue lighten-2"; // In use
        case 2:
          return "deep-purple lighten-1"; // Ringing
        case 3:
          return "green accent-4"; // Connected
        case 4:
          return "red darken-2"; // Hang up
        case 5:
          return "amber darken-1"; // Music on Hold
        default:
          return "#abababaa";
      }
    },
    icon(item: Queue | Extension | any) {
      switch (item.status) {
        case ExtenStatus.Online:
          return "mdi-check"; // Connected
        case ExtenStatus.Offline:
          return "mdi-close"; // Disconnected
        case ExtenStatus.MOH:
          return "mdi-audio"; // Music on Hold
        default:
          return "mdi-phone";
      }
    },
    filteredQueue(item: Queue) {
      return {
        name: item.name,
        strategy: item.strategy,
        timeout: item.timeout,
      };
    },
  },
});
