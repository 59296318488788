


























































































import PermissionCheckbox from "@/components/templates/PermissionCheckbox.vue";
import i18n from "@/plugins/i18n";
import Vue from "vue";
import * as action from "@/store/actions";
import { mapGetters, mapActions } from "vuex";
import { RoleGroup } from "@/types/conf";

class View {
  name;
  actions = [] as string[];
  constructor(name: string) {
    this.name = name;
    this.actions = ["read"];
  }
}

export default Vue.extend({
  name: "RoleGroup",
  props: ["roleGroupToUpdate"],
  data: () => ({
    alertType: "",
    alertMessage: "",

    isNew: false,
    valid: false,
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    name: "",
    mini: false,
    roleGroup: {
      roleGroupId: null as any,
      roleGroupName: "",
      views: [] as View[],
    } as RoleGroup,
    views: {
      home: {
        name: i18n.t("configuration.home"),
        visible: false,
        icon: "mdi-home",
        create: false,
        id: "home",
        update: false,
        delete: false,
      },
      statistics: {
        name: i18n.t("configuration.statistics"),
        visible: false,
        icon: "mdi-equalizer",
        create: false,
        id: "statistics",
        update: false,
        delete: false,
      },
      roleGroup: {
        name: i18n.t("configuration.roleGroup"),
        visible: false,
        icon: "mdi-account-group",
        create: false,
        id: "roleGroup",

        update: false,
        delete: false,
      },
      panel: {
        name: i18n.t("configuration.panel"),
        visible: false,
        icon: "mdi-application",
        create: false,
        id: "panel",

        update: false,
        delete: false,
      },
      ivrStats: {
        name: i18n.t("configuration.ivrStats"),
        visible: false,
        icon: "mdi-format-list-bulleted",
        create: false,
        id: "cdr",

        update: false,
        delete: false,
      },
      configuration: {
        name: i18n.t("configuration.config"),
        visible: false,
        create: false,
        id: "configuration",
        update: false,
        delete: false,
      },
      configurationView: {
        exten: {
          name: i18n.t("configuration.extension"),
          visible: false,
          icon: "mdi-source-branch",
          create: false,
          id: "exten",
          update: false,
          delete: false,
        },
        corp: {
          name: i18n.t("configuration.corp"),
          visible: false,
          icon: "mdi-domain",
          create: false,
          id: "corp",
          update: false,
          delete: false,
        },

        ura: {
          name: i18n.t("configuration.ivr"),
          visible: false,
          icon: "mdi-deskphone",
          create: false,
          id: "ura",
          update: false,
          delete: false,
        },

        row: {
          name: i18n.t("configuration.queue"),
          visible: false,
          icon: "mdi-human-queue",
          create: false,
          id: "row",
          update: false,
          delete: false,
        },

        speedDial: {
          name: i18n.t("configuration.speedDialCorp"),
          visible: false,
          icon: "mdi-phone-dial-outline",
          create: false,
          id: "speedDial",
          update: false,
          delete: false,
        },

        time: {
          name: i18n.t("configuration.time"),
          visible: false,
          icon: "mdi-calendar",
          create: false,
          id: "time",
          update: false,
          delete: false,
        },

        users: {
          name: i18n.t("configuration.user"),
          visible: false,
          icon: "mdi-account-supervisor",
          create: false,
          id: "users",

          update: false,
          delete: false,
        },

        restriction: {
          name: i18n.t("configurationSup.restrictionCorp"),
          visible: false,
          icon: "mdi-close-network",
          create: false,
          id: "restriction",

          update: false,
          delete: false,
        },

        trunk: {
          name: i18n.t("configuration.trunk"),
          visible: false,
          icon: "mdi-file-tree",
          create: false,
          id: "trunk",

          update: false,
          delete: false,
        },

        lookAndFeel: {
          name: i18n.t("configuration.lookAndFeel"),
          visible: false,
          icon: "mdi-looks",
          create: false,
          id: "lookAndFeel",

          update: false,
          delete: false,
        },

        integration: {
          name: i18n.t("configuration.integration"),
          visible: false,
          icon: "mdi-source-merge",
          create: false,
          id: "integration",

          update: false,
          delete: false,
        },

        voiceMail: {
          name: i18n.t("configuration.voiceMail"),
          visible: false,
          icon: "mdi-voicemail",
          create: false,
          id: "voiceMail",

          update: false,
          delete: false,
        },

        dialer: {
          name: i18n.t("configuration.dialer"),
          visible: false,
          icon: "mdi-dialpad",
          create: false,
          id: "dialer",

          update: false,
          delete: false,
        },

        moh: {
          name: i18n.t("configuration.mohCorp"),
          visible: false,
          icon: "mdi-phone-in-talk",
          create: false,
          id: "moh",

          update: false,
          delete: false,
        },
      },
    } as any,
    roleGroupList: [] as RoleGroup[],
  }),

  components: {
    PermissionCheckbox,
  },
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
  async mounted() {
    this.roleGroupList = await this.getRoleGroupList(
      action.ROLE_GROUP_CONTROLLER
    );
    const isNew =
      this.roleGroupToUpdate == null || this.roleGroupToUpdate == "undefined";
    this.isNew = isNew;

    if (!isNew) {
      this.roleGroup = await this.getRoleGroupDetail({
        id: this.roleGroupToUpdate.roleGroupId,
        action: action.ROLE_GROUP_CONTROLLER,
      });
      this.$nextTick(() => {
        this.roleGroupDetailHandler();
      });
    }
  },

  methods: {
    ...mapActions({
      getRoleGroupList: "crudService/getList",
      updateRoleGroup: "crudService/updateObject",
      getRoleGroupDetail: "crudService/getDetail",
      newRoleGroup: "crudService/newObject",
    }),
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let response = false;

        let roleGroup = {
          roleGroupName: this.name,
          views: this.viewActionHandler(),
          roleGroupId: null as any,
        };
        if (this.isNew) {
          response = await this.newRoleGroup({
            action: action.ROLE_GROUP_CONTROLLER,
            obj: roleGroup,
          });
        } else {
          roleGroup.roleGroupId = this.roleGroupToUpdate.roleGroupId;
          response = await this.updateRoleGroup({
            action: action.ROLE_GROUP_CONTROLLER,
            obj: roleGroup,
          });
        }
        if (response) {
          this.alertType = "green darken-1";
          this.alertMessage = (((this.isNew
            ? (i18n.t("misc.created") as string)
            : i18n.t("misc.updated")) as string) +
            i18n.t("misc.defaultSuccess")) as string;
        } else {
          this.alertType = "error";
          this.alertMessage = i18n.t("misc.defaultError") as string;
        }
        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
        if (
          response &&
          ((this.$route.name as string).match(/\w+\/new/g) ||
            (this.$route.name as string).match(/\w+\/update/g))
        ) {
          setTimeout(() => {
            const newRoute = (this.$route.name as string).split("/")[0];
            this.$router.replace({
              name: newRoute,
            });
          }, 2000);
        }
      }
    },
    roleGroupDetailHandler() {
      this.name = this.roleGroup.roleGroupName;

      this.roleGroup.views?.forEach((view: View) => {
        let updatedView = this.views[view.name];
        let isConfigView = false;
        if (updatedView == null) {
          isConfigView = true;
          updatedView = this.views.configurationView[view.name];
        }
        updatedView.visible = view.actions.includes("read");
        updatedView.create = view.actions.includes("create");
        updatedView.update = view.actions.includes("update");
        updatedView.delete = view.actions.includes("delete");

        if (isConfigView) {
          this.views.configurationView[view.name] = updatedView;
        } else {
          this.views[view.name] = updatedView;
        }
      });
    },
    checkDuplicate(val: string) {
      let result;
      let roleGroupList = [...this.roleGroupList];
      if (!this.isNew) {
        roleGroupList = roleGroupList.filter((role) => {
          role.roleGroupName != this.roleGroupToUpdate.roleGroupName;
        });
      }
      roleGroupList.forEach((roleGroup) => {
        if (val == roleGroup.roleGroupName) {
          result = `Name "${val}" already exist`;
          return result;
        }
      });

      return true;
    },
    viewActionHandler() {
      let viewList = [] as View[];

      Object.values(this.views).forEach((view: any) => {
        if (view.visible) {
          const newView = new View(view.id);
          if (view.create) {
            newView.actions.push("create");
          }
          if (view.update) {
            newView.actions.push("update");
          }
          if (view.delete) {
            newView.actions.push("delete");
          }
          viewList.push(newView);
        }
      });
      Object.values(this.views.configurationView).forEach((view: any) => {
        if (view.visible) {
          const newView = new View(view.id);
          if (view.create) {
            newView.actions.push("create");
          }
          if (view.update) {
            newView.actions.push("update");
          }
          if (view.delete) {
            newView.actions.push("delete");
          }
          viewList.push(newView);
        }
      });

      return viewList;
    },
    goBack() {
      const newRoute = (this.$route.name as string).split("/")[0];
      this.$router.replace({
        name: newRoute,
      });
    },
  },
});
