




import Vue from "vue";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  props: {
    text: {
      default: i18n.t("rules.mohFile"),
    },
  },
});
