import { ActionTree, GetterTree, MutationTree } from "vuex";
import axios from "axios";
import * as action from "@/store/actions";
import router from "@/router/index";
import { mainBus } from "../main";
import { AuthState, RootState } from "@/types/store/index";
import { Corp } from "@/types/conf";

const state: AuthState = {
  corp: {} as Corp,
  exchange: "pt_BR",
  user: null,
  token: null,
  expiration: null,
  views: null,
};

const getters: GetterTree<AuthState, RootState> = {
  corp(state) {
    return state.corp;
  },
  exchange(state) {
    return state.exchange;
  },
  user(state) {
    return state.user;
  },
  views(state) {
    return state.views;
  },
  token(state) {
    return state.token;
  },
};

const mutations: MutationTree<AuthState> = {
  SET_CORP(state, corp) {
    state.corp = corp;
  },
  SET_EXCHANGE(state, exchange) {
    state.exchange = exchange;
  },
  SET_USER(state, user) {
    state.user = user;
    state.views = user.views;
  },
  SET_TOKEN(state, data) {
    state.token = data.token;
    state.expiration = data.expiration;
  },
  LOG_OUT(state) {
    (state.token = null), (state.expiration = null), (state.user = null);
  },
};

const actions: ActionTree<AuthState, RootState> = {
  updateUser({ dispatch }, user) {
    return dispatch("setUser", user);
  },

  async checkTokenStatus({ dispatch, commit }) {
    const token = localStorage.getItem("token");
    const expiration = localStorage.getItem("expiration");
    if (
      window.location.href.split("/")[3] !== "login" &&
      (token === null || expiration === null)
    ) {
      return dispatch("signOut");
    } else if (token !== null || expiration !== null) {
      return dispatch("getUser");
    }
  },

  setExchange({commit}, exchangeString) {
    if(exchangeString.includes("BR")) commit("SET_EXCHANGE", "R$");
    if(exchangeString.includes("AR")) commit("SET_EXCHANGE", "$");
    if(exchangeString.includes("EN")) commit("SET_EXCHANGE", "U$D");
    if(exchangeString.includes("IS")) commit("SET_EXCHANGE", "U$D");
  },

  async signIn({ dispatch }, credentials) {
    let status = 0;
    await axios
      .post(action.LOGIN, credentials, {})
      .then((res) => {
        dispatch("setToken", res.data);
        status = res.status;
      })
      .catch((res) => {
        dispatch("signOut");
        if (res.response) {
          status = res.response.status;
          return res.response.status;
        } else {
          status = 500;
          return 500;
        }
      });

    if (status >= 400) {
      return status;
    }
    //se guarda el usuario en la store
    //aca se consulta por datos de ser necesario con un GET
    return dispatch("getUser");
  },

  async getUser({ dispatch }) {
    let status = 0;
    const headers = tokenHeader();
    await axios
      .get(action.GET_USER, { headers })
      .then((res) => {
        dispatch("setUser", res.data);
        status = res.status;
      })
      .catch((res) => {
        dispatch("signOut");
        if (res.response) {
          status = res.response.status;
          return res.response.status;
        } else {
          status = 500;
          return status;
        }
      });
    
    return status;
  },

  async setUser({ commit }, user) {
    if (user) {
      mainBus.$emit(action.USER_LOADED, user);
      await commit("SET_USER", user);
      localStorage.setItem("userLevel", user.level)
    }
    return status;
  },

  signOut({ commit, rootState }) {
    localStorage.clear();
    
    commit("LOG_OUT");
    const corpParam = rootState.lookAndFeel.url || "supervisor";
    router.replace({
      name: "login",
      params: {
        corp: corpParam,
      },
    });

  },

  async setToken({ commit }, data) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("expiration", data.expiration);
    commit("SET_TOKEN", data.token);
  },
  getPermissions({ commit, dispatch }) {},
};

export const auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export const tokenHeader = () => {
  const token = localStorage.getItem("token");
  const header = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  return header;
};
