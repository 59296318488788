











































































































import Vue from "vue";

import LanguageSwitcher from "../templates/LanguageSwitcher.vue";
import ThemeSwitcher from "@/components/templates/ThemeSwitcher.vue";

import { mapGetters, mapActions } from "vuex";

export const mainBus = new Vue();

export default Vue.extend({
  data: () => ({
    fav: true,
      menu: false,
      message: false,
      hints: true,
      darkMode: false
  }),
  
  methods: { 
    ...mapActions({
      signOut: "auth/signOut",
      getCorpList: "crudService/getList",
    }),
    
    navigateTo(navTo: string) {
      if (navTo != this.$route.name) {

        this.$router.replace({
          name: navTo,
          params: {
            corp: localStorage.getItem("corp") || "",
            lang: this.$i18n.locale,
          },
        });
      }
    },
    async handleSignOut() {
      await this.signOut();

      // Esto apaga el panel. El método Stop no está funcionando.
      location.reload();
    },
  },
  computed:{
    ...mapGetters({
      user: "auth/user",
      corp: "auth/corp",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    })
  },
  
  components: {
    LanguageSwitcher,
    ThemeSwitcher,
  }
});
