































































































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import SIPRoutesDetail from "./SIPRoutesDetail.vue";

import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { Corp, SIPRoute, Trunk, View } from "@/types/conf";

export default Vue.extend({
  name: "SIPRoutes",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    corpList: [] as Corp[],
    trunkList: [] as Trunk[],
    sipRouteList: [] as SIPRoute[],
    selectedItem: null as SIPRoute | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),

  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),

    computedSipRoutesList() {
      let sipRoutesCache: SIPRoute[] = this.sipRouteList;

      sipRoutesCache = sipRoutesCache.map(el => ({
          ...el,
          corpId: this.findCorp(el.corpId),
          trunkId: this.findTrunk(el.trunkId),
          enabled: el.enabled ? i18n.t('misc.yes') : i18n.t('misc.no'),
        })
      ) as any;
      
      return sipRoutesCache;
    }
  },

  async mounted() {
    this.loadingList = true;
    this.trunkList = await this.getList(action.TRUNK_CONTROLLER);
    this.corpList = await this.getList(action.CORP_CONTROLLER);
    this.sipRouteList = await this.getList(action.SIP_ROUTES_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    SIPRoutesDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getList: "crudService/getList",
      deleteSIProutes: "crudService/deleteObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
        },
        {
          text: i18n.t("configuration.corp"),
          value: "corpId"
        },
        {
          text: i18n.t("misc.trunk"),
          value: "trunkId"
        },
        {
          text: i18n.t("misc.pattern"),
          align: "start",
          value: "pattern",
        },
        {
          text: i18n.t("misc.traduction"),
          align: "start",
          value: "translation",
        },
        {
          text: i18n.t("misc.comment"),
          value: "comment"
        },
        {
          text: i18n.t("misc.enabled"),
          align: "start",
          value: "enabled",
        },
        
      ];
    },

    findCorp(corpId: number | null): string {
      return this.corpList.find(e => e.id == corpId)!.name;
    },

    findTrunk(trunkId: number | null) {
      return this.trunkList.find(e => e.id == trunkId)!.number;
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.SIP_ROUTES); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: SIPRoute) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteSIProutes({
        action: action.SIP_ROUTES_DELETE_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.sipRouteList];
        this.sipRouteList = itemListUpdated.filter(
          (siproutes) => siproutes.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.sipRouteList = await this.getList(action.SIP_ROUTES_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
