export const getElapsedTime = (startTime: Date) => {
  const endTime = new Date();
  const elapsedTime = endTime.getTime() - startTime.getTime(); // in milliseconds

  // Convert elapsedTime to seconds
  let timeDiff = elapsedTime / 1000;

  // Extract integer seconds that do not form a minute using %
  let seconds = Math.floor(timeDiff % 60);
  // Pad seconds with a zero (if necessary) and convert to string
  let secondsAsString = seconds < 10 ? "0" + seconds : seconds;
  // Convert time difference from seconds to minutes using %
  timeDiff = Math.floor(timeDiff / 60);
  // Extract integer minutes that don't form an hour using %
  let minutes = timeDiff % 60;
  // Pad minutes with a zero (if necessary) and convert to string
  let minutesAsString = minutes < 10 ? "0" + minutes : minutes;
  // Convert time difference from minutes to hours
  timeDiff = Math.floor(timeDiff / 60);
  // Extract integer hours that don't form a day using %
  let hours = timeDiff % 24;
  // Convert time difference from hours to days
  timeDiff = Math.floor(timeDiff / 24);
  // The rest of timeDiff is number of days
  let days = timeDiff;
  let totalHours = hours + days * 24; // add days to hours
  let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours;
  if (totalHoursAsString === "00") {
    return minutesAsString + ":" + secondsAsString;
  } else {
    return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
  }
};
