





















































































import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { Corp, DestinationGroup, RestrictionCorp, View } from "@/types/conf";
import Top from "@/components/templates/Top.vue";
import RestrictionsCorpDetail from "./RestrictionsCorpDetail.vue";

export default Vue.extend({
  name: "RestrictionsCorp",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 3 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    restrictionList: [] as RestrictionCorp[],
    corpList: [] as Corp[],
    destList: [] as DestinationGroup[],
    selectedItem: null as RestrictionCorp | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),

    computedRestrictionList() {
      let restrictionCache: RestrictionCorp[] = this.restrictionList;

      restrictionCache = restrictionCache.map(el => ({
          ...el,
          pattern: el.pattern == "DestinationGroup" ? this.translateDestinationGroup(el) : this.translatePattern(el.pattern),
        })
      )
      
      return restrictionCache;
    }
  },
  async mounted() {
    this.loadingList = true;
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    this.destList = await this.getDestinationGroup(
      action.DESTINATION_GROUP_CONTROLLER
    );

    const arrDestIds = [
      ...this.destList.map((el) => {
        return { id: el.id, name: el.name };
      }),
    ];

    const arrCorpIds = [
      ...this.corpList.map((el) => {
        return { id: el.id, name: el.name };
      }),
    ];

    this.restrictionList = await this.getRestrictionList(
      action.RESTRICTION_CORP_CONTROLLER
    );

    for (let index = 0; index < this.restrictionList.length; index++) {
      for (let j = 0; j < arrDestIds.length; j++) {
        if (
          arrDestIds[j].id === parseInt(this.restrictionList[index].pattern)
        ) {
          this.restrictionList[index].patternName = arrDestIds[j].name as any;
        }
      }
      for (let j = 0; j < arrCorpIds.length; j++) {
        if (arrCorpIds[j].id === this.restrictionList[index].corpId) {
          this.restrictionList[index].corpIdName = arrCorpIds[j].name;
        }
      }

      this.restrictionList[index].permitText =
        this.restrictionList[index].permit == 1
          ? (i18n.t("misc.yes") as string)
          : this.restrictionList[index].permit == 0
          ? (i18n.t("misc.no") as string)
          : (i18n.t("misc.pin") as string);
      this.restrictionList[index].pin =
        this.restrictionList[index].pin == "0"
          ? null
          : this.restrictionList[index].pin;
    }
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    RestrictionsCorpDetail
},

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getDestinationGroup: "crudService/getList",
      getRestrictionList: "crudService/getList",
      getCorpList: "crudService/getList",
      deleteRestriction: "crudService/deleteObject",
    }),
    headers() {
      return [      
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
        {
          text: i18n.t("misc.pattern"),
          align: "start",
          value: "pattern",
        },
        {
          text: i18n.t("configuration.corp"),
          align: "start",
          value: "corpIdName",
        },
        {
          text: i18n.t("misc.allow"),
          align: "start",
          value: "permitText",
        },
        {
          text: "Pin",
          align: "start",
          value: "pin",
        }
      ];
    },

    translatePattern(item: any) {
      if (/\d/.test(item) || item == "T") return item;
      else return i18n.t(`sdActions.${item}`)
    },
    
    translateDestinationGroup(item: any) {
      const pattern = this.destList.find(e => e.id == item.groupId)?.name; 
      return i18n.t(`destinationGroups.${pattern}`)
    },

    actionHandler() {
      const view = this.views.find(
        (v: View) => v.name == views.RESTRICTION_CORP
      ); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: RestrictionCorp) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteRestriction({
        action: action.RESTRICTION_CORP_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.restrictionList];
        this.restrictionList = itemListUpdated.filter(
          (res) => res.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
      this.destList = await this.getDestinationGroup(
        action.DESTINATION_GROUP_CONTROLLER
      );

      const arrDestIds = [
        ...this.destList.map((el) => {
          return { id: el.id, name: el.name };
        }),
      ];

      const arrCorpIds = [
        ...this.corpList.map((el) => {
          return { id: el.id, name: el.name };
        }),
      ];

      this.restrictionList = await this.getRestrictionList(
        action.RESTRICTION_CORP_CONTROLLER
      );

      for (let index = 0; index < this.restrictionList.length; index++) {
        for (let j = 0; j < arrDestIds.length; j++) {
          if (
            arrDestIds[j].id === parseInt(this.restrictionList[index].pattern)
          ) {
            this.restrictionList[index].patternName = arrDestIds[j].name as any;
          }
        }
        for (let j = 0; j < arrCorpIds.length; j++) {
          if (arrCorpIds[j].id === this.restrictionList[index].corpId) {
            this.restrictionList[index].corpIdName = arrCorpIds[j].name;
          }
        }

        this.restrictionList[index].permitText =
          this.restrictionList[index].permit == 1
            ? (i18n.t("misc.yes") as string)
            : this.restrictionList[index].permit == 0
            ? (i18n.t("misc.no") as string)
            : (i18n.t("misc.pin") as string);
        this.restrictionList[index].pin =
          this.restrictionList[index].pin == "0"
            ? null
            : this.restrictionList[index].pin;
      }
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
