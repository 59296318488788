var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#522daa"},on:{"click":_vm.getData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-phone-dial ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"overflow":"hidden"}},[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers(),"items":_vm.computedList,"search":_vm.search,"footer-props":{ 'items-per-page-text': ("" + (_vm.$t('misc.rowsPP'))) },"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{attrs:{"data":_vm.computedList,"headers":_vm.headers(),"name":("cdrs_summarized_" + (new Date().toLocaleString(_vm.$i18n.locale)))}}),_c('CopyButton',{attrs:{"headers":_vm.headers(),"copyData":_vm.computedList}})],1)]},proxy:true},{key:"item.audio",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.audio)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setPlay(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-play ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.play")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.audio)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.download")))])])]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.closeBtn"))+" ")])]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }