













import i18n from "@/plugins/i18n";
import Vue from "vue";

export default Vue.extend({
  name: "CSV",
  props: {
    data: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      labels: [] as { title: string }[],
      exceptions: [
        i18n.t("misc.actions"),
        "Features",
        "",
        undefined,
        i18n.t("misc.audio"),
        i18n.t("rules.invalidOption"),
        i18n.t("misc.options"),
      ],
    };
  },
  methods: {
    downloadAsCSV() {
      this.loading = true;
      // Convert JSON to CSV
      const replacer = (key: any, value: any) =>
        value === null || value === "" ? "" : value;
      let header: any = this.labels;

      // Header to array
      header = Object.keys(header).map((key) => {
        return {
          text: header[key].title,
          value: key,
        };
      });

      const csv = [
        header.map((el: any) => `"${el.text}"`).join(","), ...this.data.map((row: any) =>
          header
            .map((obj: any) => JSON.stringify(row[obj.value], replacer))
            .join(",")
        ),
      ].join("\r\n");

      // Download CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.name + ".csv";
      a.click();
      URL.revokeObjectURL(url);

      this.loading = false;
    },
  },
  mounted() {    
    if (!!this.headers && !!this.data && !!this.name) {
      this.headers
        .filter((el: any) => !this.exceptions.includes(el.text))
        .forEach((el: any) => {
          this.labels[el.value] = {
            title: el.text,
          };
        });
    }
  },
});
