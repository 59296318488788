


































































































































































import { mapActions, mapGetters } from "vuex";
import config from "../../../config.js";

import i18n from "@/plugins/i18n";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import IVRsDetail from "./IVRsDetail.vue";

import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { IVR, Corp, View } from "@/types/conf";
import msgCodes from "@/extendedFunctions/msgCodes";

export default Vue.extend({
  name: "",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    alertType: "",
    alertMessage: "",
    alert2: false,
    alertType2: "",
    ivrList: [] as IVR[],
    corpList: [] as Corp[],

    actionList: [] as any,

    stateIcons: [] as { sound: string; soundIncorrect: string }[],
    playingSound: [] as {
      sound: HTMLAudioElement;
      soundIncorrect: HTMLAudioElement;
    }[],
    selectedItem: null as IVR | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  
  computed: {
    ...mapGetters({
      views: "auth/views",
      menuIconColor: "lookAndFeel/menuIconColor",
    }),

    computedIvrList() {
      let trunkCache: IVR[] = this.ivrList;

      trunkCache = trunkCache.map(el => ({
          ...el,
          option0: el.option0 && this.translateAction(el.option0),
          option1: el.option1 && this.translateAction(el.option1),
          option2: el.option2 && this.translateAction(el.option2),
          option3: el.option3 && this.translateAction(el.option3),
          option4: el.option4 && this.translateAction(el.option4),
          option5: el.option5 && this.translateAction(el.option5),
          option6: el.option6 && this.translateAction(el.option6),
          option7: el.option7 && this.translateAction(el.option7),
          option8: el.option8 && this.translateAction(el.option8),
          option9: el.option9 && this.translateAction(el.option9),
        })
      )
      
      return trunkCache;
    }
  },

  async mounted() {
    this.loadingList = true;

    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    this.ivrList = await this.getIVRList(action.IVR_CONTROLLER);
    await this.updateActionList();

    this.actionHandler();

    this.ivrList.forEach(() => {
      this.stateIcons.push({ sound: "mdi-play", soundIncorrect: "mdi-play" });
      this.playingSound.push({ sound: new Audio(), soundIncorrect: new Audio() });
    });

    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    IVRsDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      getIVRList: "crudService/getList",
      getCorpList: "crudService/getList",
      deleteIVR: "crudService/deleteObjectWithData",
    }),

    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
        },
        {
          text: i18n.t("misc.name"),
          value: "name",
        },
        {
          text: i18n.t("misc.audio"),
          value: "sound",
        },
        {
          text: i18n.t("rules.invalidOption"),
          value: "soundIncorrect",
        },
        {
          text: i18n.t("misc.options"),
          value: "options",
        },
      ];
    },

    csvHeaders() {
      return [
        {
          text: i18n.t("misc.name"),
          value: "name",
        },
        {
          text: i18n.t("misc.default"),
          value: "option0",
        },
        {
          text: i18n.t("misc.option") + " 1",
          value: "option1",
        },
        {
          text: i18n.t("misc.option") + " 2",
          value: "option2",
        },
        {
          text: i18n.t("misc.option") + " 3",
          value: "option3",
        },
        {
          text: i18n.t("misc.option") + " 4",
          value: "option4",
        },
        {
          text: i18n.t("misc.option") + " 5",
          value: "option5",
        },
        {
          text: i18n.t("misc.option") + " 6",
          value: "option6",
        },
        {
          text: i18n.t("misc.option") + " 7",
          value: "option7",
        },
        {
          text: i18n.t("misc.option") + " 8",
          value: "option8",
        },
        {
          text: i18n.t("misc.option") + " 9",
          value: "option9",
        },
      ];
    },

    translateAction(item: string) {
      return this.actionList.find((e: any) => e.value == item).text
    },

    async updateActionList() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.IVR);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    
    deleteItem(item: IVR) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    setPlay(item: IVR) {
      let index = this.ivrList.indexOf(item);
      let icon;
      icon = this.stateIcons[index].sound;

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }
      this.stateIcons[index].sound = icon;
    },

    async playSound(val: IVR) {
      const index = this.ivrList.indexOf(val);
      const file = val.sound;
      const url = config.url + "ivr/sounds/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index].sound = new Audio(url);
        this.playingSound[index].sound.addEventListener("ended", () => {
          this.stateIcons[index].sound = "mdi-play";
        });
        this.playingSound[index].sound.play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },

    pauseSound(val: IVR) {
      const index = this.ivrList.indexOf(val);
      this.playingSound[index].sound.pause();
      this.reloadList();
    },

    async downloadFile(item: IVR) {
      const file = item.sound;
      const url = config.url + "ivr/sounds/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    setPlay2(item: IVR) {
      let index = this.ivrList.indexOf(item);
      let icon;
      icon = this.stateIcons[index].soundIncorrect;

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound2(item);
      } else {
        icon = "mdi-play";
        this.pauseSound2(item);
      }
      this.stateIcons[index].soundIncorrect = icon;
    },

    async playSound2(val: IVR) {
      const index = this.ivrList.indexOf(val);
      const file = val.soundIncorrect;
      const url = config.url + "ivr/sounds/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index].soundIncorrect = new Audio(url);
        this.playingSound[index].soundIncorrect.addEventListener(
          "ended",
          () => {
            this.stateIcons[index].soundIncorrect = "mdi-play";
          }
        );
        this.playingSound[index].soundIncorrect.play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },

    pauseSound2(val: IVR) {
      const index = this.ivrList.indexOf(val);
      this.playingSound[index].soundIncorrect.pause();
      this.reloadList();
    },

    async downloadFile2(item: IVR) {
      const file = item.soundIncorrect;
      const url = config.url + "ivr/sounds/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.soundIncorrect;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    reloadList() {
      let itemListUpdated = [...this.ivrList];
      this.ivrList = itemListUpdated.filter((row) => row.id);
    },
    
    async deleteItemConfirm() {

      const response = await this.deleteIVR({
        action: action.IVR_CONTROLLER,
        id: this.selectedItem?.id,
      });

      // This set if the form must persist or don't
      this.dialogDelete = response.status >= 400;
      
      this.alertMessage = msgCodes(response.status, false).alertMessage;
      this.alertType = msgCodes(response.status, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage + " " + response.data,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.ivrList = await this.getIVRList(action.IVR_CONTROLLER);
      this.actionHandler();

      for (let i = 0; i < this.ivrList.length; i++) {
        this.stateIcons.push({ sound: "mdi-play", soundIncorrect: "mdi-play" });
      }
      this.ivrList.forEach(() => {
        this.playingSound.push({
          sound: new Audio(),
          soundIncorrect: new Audio(),
        });
      });
      this.loadingList = false;
    },
  },
});
