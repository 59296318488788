






































































import Vue from "vue";
import i18n from "@/plugins/i18n";

import CopyButton from "@/components/templates/CopyButton.vue";
import CSV from "@/components/templates/CSV.vue";
import Top from "@/components/templates/Top.vue";

import { mapActions, mapGetters } from "vuex";
import Panel from "@/plugins/panelHub";

export default Vue.extend({
  name: "",
  data: () => ({
    itemList: [] as any[],
    search: "",
    loadingList: false,
    sortBy: "status",
    sortDesc: true,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    CSV,
    CopyButton,
    Top
  },
  async created() {
    Panel.client.on("AllContacts", async (data: any) => {
      this.itemList = this.itemList.map((item) => {
        const index = data.findIndex(
          (i: any) => i.endpoint.split("_")[1] == item.number
        );
        return {
          ...item,
          ip: index > -1 ? data[index].viaAddr : "",
          port: index > -1 ? data[index].viaPort : "",
        };
      });
    });
  },
  async mounted() {
    this.loadingList = true;
    // Load extensions
    this.itemList = await this.$store.getters["panel/extenList"];
    this.reload();

    this.loadingList = false;
  },
  methods: {
    async reload() {
      this.loadingList = true;
      await Panel.client.invoke("GetAllContacts", this.user.corpId);
      this.loadingList = false;
    },
    headers() {
      return [
        {
          text: i18n.t("configuration.extension"),
          align: "start",
          value: "number",
        },
        {
          text: i18n.t("rules.ipOrDomain"),
          align: "start",
          value: "ip",
        },
        {
          text: i18n.t("misc.port"),
          align: "start",
          value: "port",
        },
        {
          text: i18n.t("misc.status"),
          align: "start",
          value: "status",
        },
        {
          text: this.$i18n.t("loginView.userName"),
          align: "start",
          value: "userName",
        },
      ];
    },
    getColor(status: number) {
      switch (status) {
        case 0:
          return !!this.$vuetify.theme.dark ? "#f1f5f144" : "#1e1e1e88"; // Desconectado
        case 1:
          return "light-blue lighten-2"; // Atendido / IN USE
        case 2:
          return "green darken-3"; // Sonando
        case 3:
          return "green accent-4"; // Conectado
        case 4:
          return "red darken-2"; // Cortó llamada
        case 5:
          return "amber darken-1"; // Music on Hold
        default:
          return !!this.$vuetify.theme.dark ? "#f1f5f144" : "#1e1e1e88";
      }
    },
    handleStatus(status: number) {
      switch (status) {
        case 0:
          return this.$i18n.t("misc.offline"); // Desconectado
        case 1:
          return this.$i18n.t("misc.ringing"); // Atendido / IN USE
        case 2:
          return this.$i18n.t("misc.inuse"); // Sonando
        case 3:
          return this.$i18n.t("misc.online"); // Conectado
        case 4:
          return this.$i18n.t("misc.hangup"); // Cortó llamada
        case 5:
          return this.$i18n.t("misc.moh"); // Music on Hold
        default:
          return this.$i18n.t("misc.offline");
      }
    },
  },
});
