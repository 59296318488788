var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isToNew)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"outlined":"","color":"#522daa"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.new"))+" ")]):_vm._e(),(_vm.isToEdit)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":"","color":'blue'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pt-2 pl-2"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.isToEdit ? _vm.$t("misc.editing") : _vm.$t("misc.new"))+" "+_vm._s(_vm.$i18n.t("configurationSup.time"))+" ")])])],1),_c('v-card',{staticClass:"pa-6 pb-2",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',[_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('misc.name'),"rules":[_vm.rules.required, _vm.nameRules]},model:{value:(_vm.newTimemain.name),callback:function ($$v) {_vm.$set(_vm.newTimemain, "name", $$v)},expression:"newTimemain.name"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"filled":"","items":_vm.computedActions,"label":_vm.$t('misc.default'),"rules":[_vm.rules.required]},model:{value:(_vm.newTimemain.default),callback:function ($$v) {_vm.$set(_vm.newTimemain, "default", $$v)},expression:"newTimemain.default"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"280px","min-width":"280px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"filled":"","label":_vm.$t('misc.from'),"prepend-icon":"mdi-clock-time-four-outline","readonly":"","clearable":""},model:{value:(_vm.newTimeOption.from),callback:function ($$v) {_vm.$set(_vm.newTimeOption, "from", $$v)},expression:"newTimeOption.from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c('v-time-picker',{attrs:{"full-width":"","rules":[_vm.fromToRules(_vm.newTimeOption.from, _vm.newTimeOption.to)]},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.newTimeOption.from)}},model:{value:(_vm.newTimeOption.from),callback:function ($$v) {_vm.$set(_vm.newTimeOption, "from", $$v)},expression:"newTimeOption.from"}}):_vm._e()],1)],1),_c('v-col',[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"filled":"","label":_vm.$t('misc.to'),"prepend-icon":"mdi-clock-time-four-outline","readonly":"","clearable":"","rules":!!_vm.newTimeOption.from
                    ? [_vm.fromToRules(_vm.newTimeOption.from, _vm.newTimeOption.to)]
                    : []},model:{value:(_vm.newTimeOption.to),callback:function ($$v) {_vm.$set(_vm.newTimeOption, "to", $$v)},expression:"newTimeOption.to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.newTimeOption.to)}},model:{value:(_vm.newTimeOption.to),callback:function ($$v) {_vm.$set(_vm.newTimeOption, "to", $$v)},expression:"newTimeOption.to"}}):_vm._e()],1)],1),_c('v-col',[_c('v-autocomplete',{attrs:{"filled":"","items":_vm.computedActions,"label":_vm.$t('misc.action'),"rules":!!_vm.newTimeOption.from ? [_vm.rules.required] : []},model:{value:(_vm.newTimeOption.action),callback:function ($$v) {_vm.$set(_vm.newTimeOption, "action", $$v)},expression:"newTimeOption.action"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',[_c('v-slide-group',{attrs:{"multiple":""}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Mon');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.monday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Tue');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.tuesday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Wed');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.wednesday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Thu');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.thursday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Fri');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.friday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Sat');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.saturday"))+" ")])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"red white--text","depressed":"","rounded":""},on:{"click":function($event){toggle();
                    _vm.manageDays('Sun');}}},[_vm._v(" "+_vm._s(_vm.$t("misc.sunday"))+" ")])]}}])})],1)],1)],1),_c('v-col',[_c('v-btn',{staticStyle:{"font-size":"1em"},attrs:{"color":"#522daa","text":"","disabled":!_vm.addButtonDisabled()},on:{"click":_vm.addToOptions}},[_vm._v(" "+_vm._s(_vm.$t("misc.add"))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","offset":"3"}},[(!_vm.newTimeOption.days.length && !_vm.timeOptions.length)?_c('v-alert',{attrs:{"dense":"","text":"","type":"error"}},[_vm._v(_vm._s(_vm.$t("rules.oneDayMin")))]):_vm._e()],1)],1),(_vm.timeOptions.length)?_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[(_vm.timeOptions.length)?_c('v-list',[_c('v-list-group',{attrs:{"permanent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.timeOptions),function(item,i){return _c('v-list-item',{key:i,attrs:{"two-line":""},on:{"click":function($event){_vm.isNew ? _vm.removeOption(item) : _vm.deleteOption(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.joinedDays(item))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.from)+" - "+_vm._s(item.to)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getActionText(item.action))+" ")])],1)],1)}),1)],1):_vm._e()],1):_vm._e(),_c('v-divider',{staticClass:"mt-5"}),_c('v-card-actions',[_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":function($event){_vm.dialog=false}}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("misc.cancel"))+" ")])]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"15px","font-size":"1em"},attrs:{"color":"#522daa","text":""},on:{"click":_vm.validate}},[_c('strong',[_vm._v(" "+_vm._s(!_vm.isNew ? _vm.$t("misc.save") : _vm.$t("misc.send"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }