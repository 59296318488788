















































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import config from "../../../config.js";
import * as action from "@/store/actions";
import { MediaServer } from "@/types/conf";

export default Vue.extend({
  name: "MediaServerDetail",
  props: ["mediaServerToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    newMS: {
      number: "",
      sound: null,
      enabled: null,
    } as MediaServer,
    fileSound: "",
    playingSound: [] as any[],
    stateIcons: [] as string[],
    iconsetting: true,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
  components: {   
    Top
  },
  methods: {
    ...mapActions({
      newMediaServer: "crudService/newObject",
      updateMediaServer: "crudService/updateObject",
      getFile: "fileGetters/getFile",
    }),

    blobMaker(wavString: string) {
      let len = wavString.length;
      let buf = new ArrayBuffer(len);
      let view = new Uint8Array(buf);
      for (let i = 0; i < len; i++) {
        view[i] = wavString.charCodeAt(i) & 0xff;
      }
      return [view];
    },
    numRule(v: string) {
      return v.match(/^[0-9]{2,14}$/) ? true : i18n.t("rules.msNumRule");
    },

    async mediaServerDetailHandler(mediaServer: MediaServer) {
      this.newMS.number = mediaServer.number;
      this.newMS.sound = !!this.mediaServerToUpdate.sound
        ? new File([...(await this.fileSound)], mediaServer.sound, {
            type: "audio/wav",
          })
        : null;
      this.newMS.enabled = mediaServer.enabled == i18n.t('misc.yes');
      this.playingSound[0] = !!this.mediaServerToUpdate.sound
        ? new Audio(
            URL.createObjectURL(
              new Blob(this.blobMaker(await this.fileSound), {
                type: "audio/x-wav",
              })
            )
          )
        : null;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();
        !!this.newMS.number
          ? body.append("Number", this.newMS.number.toString())
          : null;
        !!this.newMS.sound
          ? body.append(
              "Sound",
              !!this.fileSound
                ? new Blob(this.blobMaker(await this.fileSound), {
                    type: "audio/x-wav",
                  })
                : this.newMS.sound
            )
          : null;
        body.append("Enabled", (this.newMS.enabled ? 1 : 0).toString());

        let response;
        if (this.isNew) {
          response = await this.newMediaServer({
            action: action.MEDIA_SERVER_CONTROLLER,
            obj: body,
          });
        } else {
          body.append("id", this.mediaServerToUpdate.id);
          response = await this.updateMediaServer({
            action: action.MEDIA_SERVER_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },
    
    setPlay(item: MediaServer) {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[0];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound();
      }

      this.stateIcons[0] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound(val: MediaServer) {
      const file = val.sound;
      const url = config.url + "mediaserver/sounds/" + file.name;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound.push(new Audio(url));
        this.playingSound[0].addEventListener("ended", () => {
          this.stateIcons[0] = "mdi-play";
        });
        this.playingSound[0].play();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound() {
      this.playingSound[0].pause();
    },
    resetInput() {
      this.newMS.sound = this.mediaServerToUpdate.sound;
    },
  },
  async mounted() {
    const isNew =
      this.mediaServerToUpdate == null ||
      this.mediaServerToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.fileSound = !!this.mediaServerToUpdate.sound
        ? await this.getFile({
            fileName: this.mediaServerToUpdate.sound,
            fileType: "sounds",
            endpointName: "mediaserver",
          })
        : null;
      this.mediaServerDetailHandler(this.mediaServerToUpdate);
    }
    this.stateIcons.push("mdi-play");
  },
});
