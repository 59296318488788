<template>
  <div class="py-4 ma-auto">
    <apexchart
      ref="realTimeBarChart"
      type="bar"
      :height="user.level != 1 ? 294 : 235"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapActions, mapGetters } from 'vuex';

import i18n from '@/plugins/i18n';
import config from '../../config.js';

export default {
  name: 'CallPeriods',
  components: {
    apexchart: VueApexCharts,
  },

  data: () => ({
    lastSevenDaysEnt: [],
    restDaysEnt: [],

    lastSevenDaysSal: [],
    restDaysSal: [],

    series: [
      // {
      //   name: 'Hoy a 7 días',
      //   type: 'line',
      //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
      // },
      // {
      //   name: 'De 7 a 14 días',
      //   type: 'area',
      //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
      // }
    ],
  }),

  async mounted() {
    await this.getSerie();

    // this.$refs.realTimeBarChart.updateOptions({ xaxis: { categories: keyCache} })
    this.$refs.realTimeBarChart.updateSeries([
      {
        name: i18n.t('panel.SevenToFourteenIn'),
        type: 'line',
        data: this.lastSevenDaysEnt,
      },
      {
        name: i18n.t('panel.ZeroToSevenIn'),
        type: 'area',
        data: this.restDaysEnt,
      },
      {
        name: i18n.t('panel.SevenToFourteenOut'),
        type: 'line',
        data: this.lastSevenDaysSal,
      },
      {
        name: i18n.t('panel.ZeroToSevenOut'),
        type: 'area',
        data: this.restDaysSal,
      },
    ]);

    await this.intervals();
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    chartOptions() {
      return {
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
            tools: false,
          },
        },
        stroke: {
          width: [5, 0, 5, 0],
          curve: 'smooth',
        },
        dataLabels: {
          enabled: true,

          style: {
            colors: ['#737A9C'],
          },
        },

        // plotOptions: {
        //   bar: {
        //     columnWidth: '50%'
        //   }
        // },

        fill: {
          opacity: [1, 0.25, 1, 0.25],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },

        // markers: {
        //   size: 0
        // },

        xaxis: {
          categories: ['1', '2', '3', '4', '5', '6', '7'],
          min: 1,
          max: 7,
        },

        min: 0,

        theme: {
          mode: !this.$vuetify.theme.dark ? 'light' : 'dark',
          palette: 'palette1',
        },
      };
    },
  },

  methods: {
    ...mapActions({
      getCorpsList: 'crudService/getList',
    }),

    async getSerie() {
      let data = await this.getCorpsList(`${config.url}dash/points/14`);

      let dataEnt = data.map((element) => element.entrantes);
      let dataSal = data.map((element) => element.salientes);

      let middleIndex = data.length / 2;

      this.lastSevenDaysEnt = dataEnt.slice(0, middleIndex);
      this.restDaysEnt = dataEnt.slice(middleIndex);

      this.lastSevenDaysSal = dataSal.slice(0, middleIndex);
      this.restDaysSal = dataSal.slice(middleIndex);
    },

    intervals() {
      setInterval(() => {
        this.getSerie();

        // this.$refs.realTimeBarChart.updateOptions({ xaxis: { categories: keyCache} })
        this.$refs.realTimeBarChart.updateSeries([
          {
            name: i18n.t('panel.SevenToFourteenIn'),
            type: 'line',
            data: this.lastSevenDaysEnt,
          },
          {
            name: i18n.t('panel.ZeroToSevenIn'),
            type: 'area',
            data: this.restDaysEnt,
          },
          {
            name: i18n.t('panel.SevenToFourteenOut'),
            type: 'line',
            data: this.lastSevenDaysSal,
          },
          {
            name: i18n.t('panel.ZeroToSevenOut'),
            type: 'area',
            data: this.restDaysSal,
          },
        ]);
      }, 60000);
    },
  },
};
</script>

<style>
svg {
  background-color: transparent !important;
}
</style>
