
















































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import i18n from "@/plugins/i18n";
import ResumeIVRsChart from "./ResumeIVRsChart.vue";

export default Vue.extend({
  name: "IVRStatisticsResume",
  props: ["list", "from", "to"],

  data() {
    return {
      search: "",
      stateIcons: [] as string[],
      playingSound: [] as HTMLAudioElement[],
      loadingList: false,
    };
  },

  components: {
    CSV,
    CopyButton,
    ResumeIVRsChart
},
  
  methods: {
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
        },
        {
          text: this.$i18n.t("misc.name"),
          value: "nombre"
        },
        { 
          text: "Moda",
          value: "moda"
        },
        { 
          text: "Media",
          value: "media"
        },
        { 
          text: i18n.t("misc.response"),
          value: "cantidad"
        },
        
      ]
    },
  },
});
