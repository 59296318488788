<template>
  <v-hover v-slot="{ hover }">
    <v-list-item 
      :style="hover ? computedHover : null" 
      :class="{ active: isActive }" 
      @click="handleClick"
    >
      <v-list-item-icon class="iconContainer">
        <v-icon :color="$vuetify.theme.dark ? ' #fff ' : menuIconColorLocal">
          <slot name="icon"></slot>
        </v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle 
        class="font-weight-bold" 
        :style="$vuetify.theme.dark ? 'color:white' : 'color:' + menuFontColorLocal"
      >
        <slot></slot>
      </v-list-item-subtitle>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    menuIconColorLocal: String,
    menuFontColorLocal: String,
    computedHover: String,
    vuetifyThemeDark: Boolean
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
.iconContainer {
  display: flex;
  align-items: center;
}
</style>
