





























































































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import DestinationGroupDetail from "./DestinationGroupDetail.vue";

import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { DestinationGroup, View } from "@/types/conf";



export default Vue.extend({
  name: "",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 6 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    destinationGroupList: [] as DestinationGroup[],
    selectedItem: null as DestinationGroup | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.destinationGroupList = await this.getDestinationGroupList(action.DESTINATION_GROUP_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    DestinationGroupDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getDestinationGroupList: "crudService/getList",
      deleteDestinationGroup: "crudService/deleteObject",
    }),

    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
        { text: i18n.t("misc.name"), value: "name" }
        
      ];
    },

    actionHandler() {
      const view = this.views.find(
        (v: View) => v.name == views.DESTINATION_GROUP
      ); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    

    deleteItem(item: DestinationGroup) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteDestinationGroup({
        action: action.DESTINATION_GROUP_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.destinationGroupList];
        this.destinationGroupList = itemListUpdated.filter(
          (destinationGroup) => destinationGroup.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }

      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    

    async refreshList() {
      this.loadingList = true;
      this.destinationGroupList = await this.getDestinationGroupList(action.DESTINATION_GROUP_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
