
























































































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";

import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { DestinationGroup, Extension, RestrictionExtension, View } from "@/types/conf";
import store from "@/store/index";
import RestrictionsExtenDetail from "./RestrictionsExtenDetail.vue";

export default Vue.extend({
  name: "RestrictionsExten",
  props: ["extenFeat"],
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItemsQ: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    extenList: [] as Extension[],
    destList: [] as DestinationGroup[],
    restrictionList: [] as RestrictionExtension[],
    selectedItem: null as RestrictionExtension | null,
    selectedId: null as number | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  components: {
    CSV,
    CopyButton,
    Top,
    RestrictionsExtenDetail
},
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
    computedItems(): RestrictionExtension[] {

      if (this.extenFeat) {
        const arr = this.restrictionList
          .filter((el) => el.extenId === this.selectedId)
          .map((el) => {
            const dg = this.destList.find((dest) => dest.id === el.groupId);
            return {
              ...el,
              patternIdName: !el.pattern ? `${dg?.name}` : el.pattern,
              extenId: this.translateExtension(el)
            };
          });
        return arr.map(el => ({
          ...el,
          patternIdName: el.pattern == "DestinationGroup" ? this.translateDestinationGroup(el) : this.translatePattern(el.pattern)
        }));

      } else {

        const arr = this.restrictionList
          .map((el) => {
            const dg = this.destList.find((dest) => dest.id === el.groupId);
            return {
              ...el,
              patternIdName: !el.pattern ? `${dg?.name}` : el.pattern,
              extenId: this.translateExtension(el)
            };
          });
        return arr.map(el => ({
          ...el,
          patternIdName: el.pattern == "DestinationGroup" ? this.translateDestinationGroup(el) : this.translatePattern(el.pattern)
        }));
      }
    },
  },

  async mounted() {
    await this.refreshList();
  },
  methods: {
    ...mapActions({
      getExtensionsList: "crudService/getList",
      getDestinationGroup: "crudService/getList",
      getRestrictionList: "crudService/getList",
      deleteRestriction: "crudService/deleteObject",
    }),
    
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: i18n.t("misc.destination"),
          value: "patternIdName",
        },
        {
          text: i18n.t("configuration.extension"),
          value: "extenId",
        },
        {
          text: i18n.t("misc.allow"),
          value: "permitText",
        },
        
      ];
    },

    translateExtension(item: any): any {
      return this.extenList.find(e => e.id == item.extenId)!.number;
    },

    translateDestinationGroup(item: any) {
      const pattern = this.destList.find(e => e.id == item.groupId)?.name; 
      return i18n.t(`destinationGroups.${pattern}`)
    },

    translatePattern(item: any) {
      if (/\d/.test(item) || item == "T") return item;
      else return i18n.t(`sdActions.${item}`)
    },
    
    actionHandler() {
      const view = this.views.find(
        (v: View) => v.name == views.RESTRICTION_EXTEN
      ); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: RestrictionExtension) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteRestriction({
        action: action.RESTRICTION_EXTENSIONS_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.restrictionList];
        this.restrictionList = itemListUpdated.filter(
          (res) => res.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    navigateTo(res: any) {
      if (res === "back") {
        this.$router.replace({
          name: "extension",
        });
      }
    },

    async refreshList() {
      this.loadingList = true;
      this.destList = await this.getDestinationGroup(action.DESTINATION_GROUP_CONTROLLER);
      this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);
      this.restrictionList = await this.getRestrictionList(action.RESTRICTION_EXTENSIONS_CONTROLLER);

      for (let index = 0; index < this.restrictionList.length; index++) {
        this.restrictionList[index].permitText =
          this.restrictionList[index].permit == 1
            ? (i18n.t("misc.yes") as string)
            : this.restrictionList[index].permit == 0
            ? (i18n.t("misc.no") as string)
            : (i18n.t("misc.pin") as string);
      }
      this.selectedId = this.extenFeat ? this.extenFeat.id : null;
      this.actionHandler();
      
      this.loadingList = false;
    },
  },
});
