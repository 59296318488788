import config from "../config.js";
export const AST_QUEUES = config.url + "astqueue";

// Request
export const GET_USER = config.url + "myuser";
export const LOGIN = config.url + "login";

// Bus
export const RELOAD_LOOK_AND_FEEL = "reloadLookAndFeel";
export const USER_LOADED = "userLoaded";

// CDR
export const GET_CDR_LIST = config.url + "cdrs";

// Corp
export const CORP_CONTROLLER = config.url + "corp";
export const GET_ACTIONS = config.url + "corp/actions";

// Destination Groups
export const DESTINATION_GROUP_CONTROLLER = config.url + "destinationgroup";

// Dialer
export const DIALER_CONTROLLER = config.url + "discador";

// Extensions
export const EXTENSION_CONTROLLER = config.url + "exten";
export const EXTENSION_IP = config.url + "exten/ip/";
export const ALL_EXTENSION = config.url + "extenPanel";

// Follow
export const FOLLOW_CONTROLLER = config.url + "follows";

// Integrations
export const INTEGRATION_CONTROLLER = config.url + "integration";

// Ivr
export const IVR_CONTROLLER = config.url + "ivr";
export const GET_SOUND_IVR = config.url + "ivr/sounds/";
export const IVR_STATISTICS = config.url + "ivr/answers/filter/"
export const IVR_STATISTICS_CHART = config.url + "ivr/answers/options/"

// Look and Feel
export const LOOK_AND_FEEL = config.url + "lookandfeel/";
//export const LOOK_AND_FEEL_IMAGES = config.url + "lookandfeel/";
export const POST_LOOK_AND_FEEL = config.url + "lookandfeel";

// Media Server
export const MEDIA_SERVER_CONTROLLER = config.url + "mediaserver";

// MOHCorp
export const MOH_CORP_CONTROLLER = config.url + "mohcorp";
export const MOH_CORP_GET_CONTROLLER = config.url + "mohcorp/corp/";

// MOHQueue
export const MOH_QUEUE_CONTROLLER = config.url + "mohqueue";

// Poll
export const POLL_CONTROLLER = config.url + "poll";

// Question
export const QUESTION_CONTROLLER = config.url + "question";
export const QUESTION_POLL_CONTROLLER = config.url + "questions/poll/";
export const QUESTION_LIST = config.url + "pollanswers/filter/"
export const POLL_CHART = config.url + "pollanswers/options/"

// Queues
export const QUEUE_CONTROLLER = config.url + "queue";
export const GET_SOUND_QUEUE = config.url + "queue/sounds/";
export const GET_STRATEGY_LIST = config.url + "queue/strategies/";

export const GET_LIST_STATISTICS_QUEUE = config.url + "queue/getliststatisticsqueue";
export const GET_EXTEN_IN_USE = config.url + "dash/limits/extens";
export const GET_QUEUE_IN_USE = config.url + "dash/limits/queues";
export const QUEUE_CALL_STATUS = config.url + "queuecallstatus/"

// Restrictions Corp
export const RESTRICTION_CORP_CONTROLLER = config.url + "restrictioncorp";

// Restrictions Extensions
export const RESTRICTION_EXTENSIONS_CONTROLLER =
  config.url + "restrictionexten";

// Role group
export const ROLE_GROUP_CONTROLLER = config.url + "rolegroup";

// Server
export const SERVER_CONTROLLER = config.url + "server"; 

// Sip routes
export const SIP_ROUTES_CONTROLLER = config.url + "siproute/all";
export const SIP_ROUTES_NEW_CONTROLLER = config.url + "siproute/new";
export const SIP_ROUTES_UPDATE_CONTROLLER = config.url + "siproute/update";
export const SIP_ROUTES_DELETE_CONTROLLER = config.url + "siproute/delete";

// SpeedDial Corps
export const SPEED_DIAL_CORP_CONTROLLER = config.url + "speeddialcorps";

// SpeedDial Extensions
export const SPEED_DIAL_EXTENSIONS_CONTROLLER = config.url + "speeddialextens";

// Time Main
export const TIME_MAIN_CONTROLLER = config.url + "timemain";

// Time Option
export const TIME_OPTION_CONTROLLER = config.url + "timeoption";

// Trunk
export const TRUNK_CONTROLLER = config.url + "trunk";
export const UPDATE_TRUNK_ACTION = config.url + "trunk/action";

// Users
export const USER_CONTROLLER = config.url + "user";
export const UPDATE_MY_USER = config.url + "user/updatepass";

// VoiceMail
export const VOICEMAIL_CONTROLLER = config.url + "voicemail";

// Token
export const POST_TOKEN = config.url + "astqueue/apply";

// Token
export const GET_EXCHANGE = config.url + "getExchange";

// UserConfig
export const GET_USER_LANG = config.url + "user/getLang";
export const GET_USER_THEME = config.url + "user/getTheme";
export const SET_USER_LANG = config.url + "user/setLang";
export const SET_USER_THEME = config.url + "user/setTheme";
