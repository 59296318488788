export enum ExtenStatus {
  Unreachable = 0,
  UNAVAILABLE = 0,
  Offline = 0,
  offline = 0,
  UNKNOWN = 0,
  INVALID = 0,
  Ringing = 1,
  ringing = 1,
  RINGING = 1,
  RINGINUSE = 1,
  InUse = 2,
  inUse = 2,
  INUSE = 2,
  BUSY = 2,
  Online = 3,
  online = 3,
  NotInUse = 3,
  NOT_INUSE = 3,
  Hangup = 4,
  hangup = 4,
  ONHOLD = 5,
  moh = 5,
  MOH = 5,
}
