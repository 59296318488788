
















import Vue from "vue";
import SnackbarQueue from "./SnackbarQueue.vue";
export default Vue.extend({
  data() {
    return {
      items: [] as {
        id: string;
        color: string;
        message: string;
      }[],
    };
  },
  components: {
    SnackbarQueue,
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.items.push({
          id: this.uniqueId("item_"),
          color: state.snackbar.color,
          message: state.snackbar.message,
        });
      }
    });
  },
  methods: {
    uniqueId: (prefix: string | number) =>
      `${prefix}_` + Math.random().toString(36).substr(2, 9),

    removeItem(id: string | number) {
      const index = this.items.findIndex((item) => item.id === id);

      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
  },
});
