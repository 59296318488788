import i18n from "@/plugins/i18n";
import Login from "@/views/Login.vue";
import NotFound from "@/views/misc/NotFound.vue";
import { routes } from "./routes";
import store from "@/store/index";
import Vue, { CreateElement } from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

const corp = store.state.lookAndFeel.url;

const fullRoutes: RouteConfig[] = [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      window.location.href = "/login/supervisor"
    }
  },
  {
    path: "/login/:corp?",
    name: "login",
    component: Login,

    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") !== null) {
        return next({
          name: "home",
          params: { corp: corp, lang: i18n.locale },
        });
      }
      next();
    },
  },
  {
    path: "/:corp/:lang",
    component: {
      render(c: CreateElement) {
        return c("router-view");
      },
    },
    children: routes,
  },
  {
    // will match everything
    path: "*",
    name: "notFound",
    component: NotFound,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: fullRoutes,
});

export default router;
