<template>
  <div class="py-4 ma-auto">
    <apexchart
      ref="realTimeLineChart"
      type="area"
      :height="204"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";

export default {
  name: "chart",
  components: {
    apexchart: VueApexCharts,
  },
  props: ["currentCalls", "onlineAgents"],
  data() {
    return {
      data1: [],
      data2: [],
      data3: [],
      series: [
        // {
        //   name: this.$i18n.t("misc.activeCalls"),
        //   data: [],
        // },
        {
          name: this.$i18n.t("misc.onlineAgents"),
          data: [],
        },
        {
          name: this.$i18n.t("misc.offlineAgents"),
          data: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentCallList: "panel/currentCallList",
      extenList: "panel/extenList",
    }),
    
    chartOptions() {

      let myTickAmount;

      if (this.extenList.length > 1000) {
        myTickAmount = this.extenList.length / 100;
      } else if (this.extenList.length > 500) {
        myTickAmount = this.extenList.length / 50;
      } else if (this.extenList.length > 250) {
        myTickAmount = this.extenList.length / 25;
      } else if (this.extenList.length > 100) {
        myTickAmount = this.extenList.length / 10;
      } else if (this.extenList.length > 90) {
        myTickAmount = this.extenList.length / 9;
      } else if (this.extenList.length > 80) {
        myTickAmount = this.extenList.length / 8;
      } else if (this.extenList.length > 70) {
        myTickAmount = this.extenList.length / 7;
      } else if (this.extenList.length > 60) {
        myTickAmount = this.extenList.length / 6;
      } else if (this.extenList.length > 50) {
        myTickAmount = this.extenList.length / 5;
      } else if (this.extenList.length > 40) {
        myTickAmount = this.extenList.length / 4;
      } else if (this.extenList.length > 30) {
        myTickAmount = this.extenList.length / 3;
      } else if (this.extenList.length > 20) {
        myTickAmount = this.extenList.length / 2;
      } else if (this.extenList.length > 10) {
        myTickAmount = this.extenList.length / 1;
      }
        
      return {
        colors: ["#00c853", "#90a4ae44", "#4fc3f7"],
        chart: {
          offsetY: -20,
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          foreColor: "#ababab",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "stepline",
          dashArray: [0, 0, 8],
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          tickPlacement: "on",
          range: 60000,
          labels: {
            show: true,
            format: "hh:mm:ss",
          },
        },
        yaxis: {
          max: this.extenList.length + 1,
          min: 0,
          tickAmount: myTickAmount,
          labels: {
            show: true,
          },
        },
        legend: {
          position: "bottom",
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      };
    },
  },
  async mounted() {
    await this.intervals();
  },
  methods: {
    getNewSeries() {
      let newDate = new Date();
      this.data1.push({
        x: newDate.getTime() - newDate.getTimezoneOffset() * 60000,
        y: this.currentCalls,
      });
      this.data2.push({
        x: newDate.getTime() - newDate.getTimezoneOffset() * 60000,
        y: this.onlineAgents,
      });
      this.data3.push({
        x: newDate.getTime() - newDate.getTimezoneOffset() * 60000,
        y: this.extenList.length - this.onlineAgents,
      });
    },
    intervals() {
      setInterval(() => {
        this.getNewSeries();
        if (this.$refs.realTimeLineChart != null) {
          this.$refs.realTimeLineChart.updateSeries([
            // {
            //   name: this.$i18n.t("misc.activeCalls"),
            //   data: this.data1,
            // },
            {
              name: this.$i18n.t("misc.onlineAgents"),
              data: this.data2,
            },
            {
              name: this.$i18n.t("misc.offlineAgents"),
              data: this.data3,
            },
          ]);
        }
      }, 1000);

      // every 60 seconds, we reset the data to prevent memory leaks
      setInterval(() => {
        if (this.$refs.realTimeLineChart != null) {
          this.$refs.realTimeLineChart.updateSeries([
            // {
            //   name: this.$i18n.t("misc.activeCalls"),
            //   data: [],
            // },
            {
              name: this.$i18n.t("misc.onlineAgents"),
              data: [],
            },
            {
              name: this.$i18n.t("misc.offlineAgents"),
              data: [],
            },
          ]);
        }
      }, 60000);
    },
  },
};
</script>