import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import i18n from "./plugins/i18n";
import "@/plugins/apexcharts";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.config.productionTip = false;

Vue.config.devtools = true;

export const mainBus = new Vue();

router.beforeEach((to, from, next) => {
  let localStorageLocale = localStorage.getItem("locale");
  if (localStorageLocale !== null) {
    i18n.locale = localStorageLocale;
  } else if (i18n.locale == "" || i18n.locale == null) {
    let language = to.params.lang;
    let userLang = navigator.language;
    userLang = userLang.slice(0, 2);
    if (!language) {
      switch (userLang) {
        case "en":
          language = "en";
          break;
        case "es":
          language = "es";
          break;
        case "pt":
          language = "pt";
          break;
        default:
          language = "en";
      }
    }

    i18n.locale = language;
  }
  store.dispatch("lookAndFeel/checkLookandfeel");
  next();
});

store
  .dispatch("auth/checkTokenStatus")
  .then(() => {
    store.dispatch("lookAndFeel/checkLookandfeel");
  })
  .finally(() => {
    new Vue({
      store,
      router,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  });
