

















































import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import ResumeChart from "./ResumeChart.vue";
import CDRTable from "./CDRTable.vue";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: "QueuesStatisticsResume",
  props: ["list", "body"],

  data() {
    return {
      search: "",
      queueList: [] as any[],
      totals: [] as any[],
      stateIcons: [] as string[],
      playingSound: [] as HTMLAudioElement[],
      loadingList: false,
    };
  },

  components: {
    CSV,
    CopyButton,
    ResumeChart,
    CDRTable
  },

  watch: {
    list(newVal) {
      this.queueList = newVal;
    }
  },

  computed: {
    computedQueueList() {
      
      // Crear un objeto de combinación
      const combinedObject: any = {};

      // Iterar sobre el primer array y agregar objetos al objeto de combinación
      this.queueList.forEach(obj => {
        if (!combinedObject[obj.queue]) {
          combinedObject[obj.queue] = { queue: obj.queue };
        }
        Object.assign(combinedObject[obj.queue], obj);
      });

      // Iterar sobre el segundo array y agregar objetos al objeto de combinación
      this.totals.forEach(obj => {
        if (!combinedObject[obj.queue]) {
          combinedObject[obj.queue] = { queue: obj.queue };
        }
        Object.assign(combinedObject[obj.queue], obj);
      });

      // Convertir el objeto de combinación en un array de objetos
      let objectArray = Object.values(combinedObject);
      return objectArray.filter((e: any) => !!e.name)
    }
  },
  
  methods: {
    headers() {
      return [
        {
          text: i18n.t("misc.action"),
          value: "queue",
          width: "90px"
        },
        {
          text: i18n.t("misc.name"),
          value: "name"
        },
        { 
          text: i18n.t("relatorios.totalCalls"), 
          value: "totalCalls"
        },
        {
          text: i18n.t("relatorios.totalTimeCalls"),
          value: "callduration",
        },
        { 
          text: i18n.t("relatorios.totalTimeWait"), 
          value: "holdtime"
        },
        {
          text: i18n.t("relatorios.midTimeCalls"),
          value: "avgcallduration",
        },
        { 
          text: i18n.t("relatorios.midTimeWait"), 
          value: "avgholdtime"
        },
        {
          text: i18n.t("relatorios.maxTimeCalls"),
          value: "maxcallduration",
        },
        { 
          text: i18n.t("relatorios.maxTimeWait"), 
          value: "maxholdtime"
        },
        { 
          text: i18n.t("corpPanel.SAnswer"), 
          value: "attended"
        },
        { 
          text: i18n.t("corpPanel.SNoanswer"), 
          value: "neglected"
        },
        
      ]
    },

    // Esta función se invoca en ResumeChart
    addTotals(attended: number, neglected: number, queue: string) {
      this.totals = [...this.totals, {queue, attended, neglected, totalCalls: attended + neglected}];
    }
  },
});
