export const CDR = "cdr";
export const CORP = "corp";
export const DESTINATION_GROUP = "destinationGroup";
export const DIALER = "dialer";
export const EXTENSION = "extension";
export const FOLLOW = "follow";
export const HOME = "home";
export const INTEGRATION = "integration";
export const IVR = "ivr";
export const LOOKANDFEEL = "lookAndFeel";
export const MEDIA_SERVERS = "mediaServer";
export const MOHCORP = "mohCorp";
export const MOHQUEUE = "mohQueue";
export const PANEL = "panel";
export const POLLS = "polls";
export const QUEUE = "queue";
export const RESTRICTION_CORP = "restrictionCorp";
export const RESTRICTION_EXTEN = "restrictionExten";
export const ROLEGROUP = "roleGroup";
export const SERVERS = "server";
export const SIP_ROUTES = "sipRoutes";
export const SPEED_DIAL_CORP = "speedDialCorp";
export const SPEED_DIAL_EXTEN = "speedDialExten";
export const STATISTICS = "statistics";
export const TIME = "time";
export const TRUNK = "trunk";
export const USERS = "user";
export const VOICE_MAIL = "voiceMail";
