import { ActionTree } from "vuex";
import axios from "axios";
import * as action from "@/store/actions";
import store from "@/store/index";
import { TokenState, RootState } from "@/types/store/index";

const tokenHeader = () => {
  const token = localStorage.getItem("token");
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return header;
};

const actions: ActionTree<TokenState, RootState> = {
  async postToken({ dispatch }, obj) {
    let response;
    let status;
    const headers = tokenHeader();

    await axios
      .post(action.POST_TOKEN, obj, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });

        status = error.status;
      });

    return status && status < 300;
  }
}

export const token = {
  namespaced: true,
  actions,
};