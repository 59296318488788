










































































import Vue from 'vue';
import Top from '@/components/templates/Top.vue';
import { mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import Panel from '@/plugins/panelHub';
import { ActiveCall } from '@/types/store';

export default Vue.extend({
  name: 'ActiveCalls',
  data: () => ({
    computedItems: screen.width < 600 ? 1 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,
    // callDurations: {} as any,
    search: '',
  }),
  components: {
    Top,
  },
  computed: {
    ...mapGetters({
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      activeCalls: 'panel/getActiveCalls',
    }),
    filteredActiveCalls() {
      const searchTerm = this.search.toLowerCase();
      return this.activeCalls.filter(
        (call: ActiveCall) =>
          call.dialed.toLowerCase().includes(searchTerm) ||
          call.caller.toLowerCase().includes(searchTerm) ||
          call.destination.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    async handleHangup(call: any) {
      await Panel.client.invoke('HangupCall', call.caller, call.receiver);
    },
    convertToLocalTime() {
      // @ts-ignore
      this.localTime = moment.utc(this.utcTime).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');
    },
    headers() {
      return [
        // {
        //   text: i18n.t('misc.actions'),
        //   value: 'actions',
        //   sortable: false,
        //   width: '100px',
        // },
        {
          text: i18n.t('misc.destinationType'),
          value: 'profile',
        },
        {
          text: i18n.t('misc.date'),
          value: 'time',
        },
        // {
        //   text: i18n.t('misc.duration'),
        //   value: 'callDuration',
        //   sorteable: false
        // },
        {
          text: i18n.t('misc.origin'),
          value: 'caller',
        },
        {
          text: i18n.t('misc.trunk'),
          value: 'dialed',
        },
        {
          text: i18n.t('misc.destination'),
          value: 'destination',
        },
      ];
    },

    // getCallDuration(call: ActiveCall): string {
    //   console.log(call);
      
    //   console.log(call.time);
      
    //   const startTime = new Date(call.time);
    //   const now = new Date();
    //   const elapsedTime = now.getTime() - startTime.getTime();
    //   const seconds = Math.floor((elapsedTime / 1000) % 60);
    //   const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
    //   const hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);

    //   // Formatear la duración de la llamada en HH:MM:SS
    //   return `${hours
    //     .toString()
    //     .padStart(2, '0')}:${minutes
    //     .toString()
    //     .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // },

    // updateCallDurations() {
    //   setInterval(() => {
    //     this.activeCalls.forEach((call: ActiveCall) => {
    //       Vue.set(this.callDurations, call.callId, this.getCallDuration(call));
    //     });
    //   }, 1000);
    // },
  },

  // mounted() {
  //   this.updateCallDurations(); // Iniciar la actualización de duraciones de llamadas
  // },
});
