








































































































import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import FileSizeInfo from "@/components/templates/FileSizeInfo.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import config from "../../../config.js";
import * as action from "@/store/actions";

import i18n from "@/plugins/i18n";
import { Poll } from "@/types/conf";

export default Vue.extend({
  name: "PollsDetail",
  props: ["pollToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    isNew: false,
    alertType: "",
    alertMessage: "",
    valid: false,

    poll: {
      name: "",
      sound: null,
      description: "",
    } as Poll,
    fileSound: "",
    playingSound: [] as any[],
    stateIcons: [] as string[],
    iconsetting: true,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
  }),

  components: {
    FileSizeInfo,
    Top,
  },
  methods: {
    ...mapActions({
      newPoll: "crudService/newObject",
      updatePoll: "crudService/updateObject",
      getFile: "fileGetters/getFile",
    }),
    nameRules(v: string) {
      return v.match(/^[a-zA-Z0-9_ ]*$/) ? true : i18n.t("rules.alphanum");
    },
    blobMaker(wavString: string) {
      let len = wavString.length;
      let buf = new ArrayBuffer(len);
      let view = new Uint8Array(buf);
      for (let i = 0; i < len; i++) {
        view[i] = wavString.charCodeAt(i) & 0xff;
      }
      return [view];
    },
    async pollDetailHandler(poll: Poll) {
      this.poll.name = poll.name;
      this.poll.description = poll.description;
      this.poll.sound = poll.sound
      this.playingSound[0] = !!poll.sound
        ? new Audio(
            URL.createObjectURL(
              new Blob(this.blobMaker(await this.fileSound), {
                type: "audio/x-wav",
              })
            )
          )
        : null;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();

        body.append("Name", this.poll.name);

        // !!this.poll.sound
        //   ? body.append( "Sound", !!this.fileSound ? new Blob(this.blobMaker(await this.fileSound), { type: "audio/x-wav" }) : this.poll.sound )
        //   : null;

        body.append("Sound", this.poll.sound)

        body.append("Description", this.poll.description);

        let response;
        if (this.isNew) {
          response = await this.newPoll({
            action: action.POLL_CONTROLLER,
            obj: body,
          });
        } else {
          body.append("id", this.pollToUpdate.id);
          response = await this.updatePoll({
            action: action.POLL_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }

      this.$emit("refreshList", this.dialog)
    },
    
    setPlay(item: Poll) {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[0];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound();
      }

      this.stateIcons[0] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound(val: Poll) {
      this.playingSound[0].addEventListener("ended", () => {
        this.stateIcons[0] = "mdi-play";
      });
      this.playingSound[0].play();
    },
    pauseSound() {
      this.playingSound[0].pause();
    },
    resetInput() {
      this.poll.sound = this.pollToUpdate.sound;
    },
  },
  async mounted() {
    const isNew = this.pollToUpdate == null || this.pollToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.fileSound = !!this.pollToUpdate.sound
        ? await this.getFile({
            fileName: this.pollToUpdate.sound,
            fileType: "sounds",
            endpointName: "poll",
          })
        : null;
      this.pollDetailHandler(this.pollToUpdate);
    }

    this.stateIcons.push("mdi-play");
  },
});
