var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('Top'),_c('div',{staticClass:"ma-2"},[_c('v-data-table',{staticClass:"pa-2 elevation-4",attrs:{"no-data-text":_vm.$t('misc.noData'),"no-results-text":_vm.$t('misc.noData'),"headers":_vm.headers,"items":_vm.computedTrunkList,"items-per-page":_vm.computedItems,"fixed-header":"","height":_vm.computedHeight,"search":_vm.search,"footer-props":{ 'items-per-page-text': ("" + (_vm.$t('misc.rowsPP'))) },"loading-text":_vm.$t('misc.loadingText'),"loading":_vm.loadingList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent mb-10",attrs:{"flat":""}},[_c('v-text-field',{staticStyle:{"max-width":"35em"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('misc.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('CSV',{key:_vm.csvKey,attrs:{"data":_vm.computedTrunkList,"headers":_vm.headers,"name":"extens"}}),_c('CopyButton',{key:_vm.csvKey,attrs:{"headers":_vm.headers,"copyData":_vm.extenList}}),_c('ExtensionsDetail',{attrs:{"isToNew":"true"},on:{"refreshList":_vm.refreshList}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.defaultConfirmation"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$i18n.t("misc.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},(_vm.user.level != 4)?{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t("misc.action"))+": "+_vm._s(item.action ? _vm.$t(("actions." + (item.action))) : "")+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t("misc.occupied"))+": "+_vm._s(item.onbusy ? _vm.$t(("actions." + (item.onbusy))) : "")+" ")]),_c('li',[_vm._v(_vm._s(_vm.$t("misc.external"))+": "+_vm._s(item.outer ? item.outer : ""))])])])]}}:null,{key:"item.features",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"deep-purple"},on:{"click":function($event){return _vm.navigateTo(item, 's')}}},on),[_c('v-icon',[_vm._v("mdi-phone-dial")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("configuration.speedDialCorp")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"deep-purple"},on:{"click":function($event){return _vm.navigateTo(item, 'r')}}},on),[_c('v-icon',[_vm._v("mdi-account-cancel-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("configurationSup.restrictionCorp")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"deep-purple"},on:{"click":function($event){return _vm.navigateTo(item, 'f')}}},on),[_c('v-icon',[_vm._v("mdi-book-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("actions.followMe")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ExtensionsDetail',{attrs:{"isToEdit":"true","extenToUpdate":item},on:{"refreshList":_vm.refreshList}}),(_vm.canDelete && _vm.user.level != 4)?_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}},(_vm.user.level == 4)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.actionList.find(function (e) { return e.value == item.action; }).text)+" ")]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }