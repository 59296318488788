


















































































































































































import { mapActions, mapGetters } from 'vuex';
import config from '../../../config.js';

import i18n from '@/plugins/i18n';
import FileSizeInfo from '@/components/templates/FileSizeInfo.vue';
import * as views from '@/plugins/utils/View';
import * as crud from '@/plugins/utils/Crud';
import * as action from '@/store/actions';
import Vue from 'vue';
import { MOH, View } from '@/types/conf';

export default Vue.extend({
  name: '',
  props: ['queueToUpdate'],
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    alertType: '',
    alertMessage: '',
    itemList: [] as MOH[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as MOH | null,
    search: '',
    dialog: false,
    dialogDelete: false,
    valid: false,
    loadingList: false,

    obj: {
      Sound: null as any,
    },
  }),
  computed: {
    ...mapGetters({
      views: 'auth/views',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.loadList();
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    FileSizeInfo,
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getMOHQList: 'crudService/getList',
      getMOHQDetail: 'crudService/getDetail',
      deleteMOHQ: 'crudService/deleteObject',
      newMOHQ: 'crudService/newObject',
    }),
    
    headers() {
      return [
        {
          text: i18n.t('misc.name'),
          align: 'start',
          value: 'name',
        },
        {
          text: i18n.t('misc.actions'),
          value: 'actions',
          sortable: false,
          align: 'left',
          width: '30%',
        },
      ];
    },

    async loadList() {
      this.itemList = await this.getMOHQList(action.MOH_QUEUE_CONTROLLER + "/id/" + this.queueToUpdate.id);
      for (let i = 0; i < this.itemList.length; i++) {
        this.stateIcons.push('mdi-play');
      }
      this.playingSound.length = this.itemList.length;
    },

    async downloadFile(item: MOH) {
      
      const file = item.name; 

      var url = config.url + 'mohQueue/getsound/'+file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = item.name as any;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    setPlay(item: MOH) {
      let index = this.itemList.indexOf(item);
      let icon;
      icon = this.stateIcons[index];

      if (icon === 'mdi-play') {
        icon = 'mdi-pause';
        this.playSound(item);
      } else {
        icon = 'mdi-play';
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },

    async playSound(val: MOH) {
      const index = this.itemList.indexOf(val);
      const file = val.name;
      const url = config.url + 'mohQueue/getsound/' + file;
      const token = localStorage.getItem('token');
      const result = await fetch(url, {
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (result) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener('ended', () => {
          this.stateIcons[index] = 'mdi-play';
        });
        this.playingSound[index].play();
      } else {
        alert('Cannot load');
      }
    },

    pauseSound(val: MOH) {
      const index = this.itemList.indexOf(val);
      this.playingSound[index].pause();
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.MOHQUEUE);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: MOH) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteMOHQ({
        action: action.MOH_QUEUE_CONTROLLER,
        id: this.selectedItem?.name,
      });
      this.closeDelete();
      if (response) {
        this.alertType = 'green darken-1';
        this.alertMessage = ((i18n.t('misc.deleted') as string) +
          i18n.t('misc.defaultSuccess')) as string;
        let itemListUpdated = [...this.itemList];
        this.itemList = itemListUpdated.filter(
          (moh) => moh.name != this.selectedItem?.name
        );
      } else {
        this.alertType = 'error';
        this.alertMessage = i18n.t('misc.defaultError') as string;
      }
      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async addToList() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();
        body.append('sound', this.obj.Sound);
        body.append('qId', this.queueToUpdate.id);
        body.append('cId', this.queueToUpdate.corpId);

        let response = await this.newMOHQ({
          action: action.MOH_QUEUE_CONTROLLER,
          obj: body,
        });

        if (response) {
          this.alertMessage = ((i18n.t('misc.created') as string) +
            i18n.t('misc.defaultSuccess')) as string;
          this.alertType = 'green darken-1';
        } else {
          this.alertMessage = i18n.t('misc.defaultError') as string;
          this.alertType = 'error';
        }
        this.$store.dispatch('snackbar/showSnack', {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.dialog = false;
        this.loadList();
      }
    },
  },
});
