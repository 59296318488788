


















































































































































































































import Vue from 'vue';

import CSV from '@/components/templates/CSV.vue';
import CopyButton from '@/components/templates/CopyButton.vue';
import Top from '@/components/templates/Top.vue';
import CorpDetail from './CorpDetail.vue';

import { mapActions, mapGetters } from 'vuex';

import i18n from '@/plugins/i18n';
import * as views from '@/plugins/utils/View';
import * as crud from '@/plugins/utils/Crud';
import * as action from '@/store/actions';
import { Corp, View } from '@/types/conf';
import { getCookie, setCookie } from '@/plugins/utils/cookieManager';
import msgCodes from '@/extendedFunctions/msgCodes';

export default Vue.extend({
  name: 'Corps',
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 1 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: '',
    alertMessage: '',
    corpList: [] as Corp[],
    selectedCorp: null as Corp | null,
    checkbox: [] as any[],
    search: '',
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: 'auth/views',
      user: 'auth/user',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
    }),

    computedCorpList() {
      let corpCache: Corp[] = this.corpList;

      corpCache = corpCache.map((el) => ({
        ...el,
        enabled: el.enabled === 1 ? i18n.t('misc.yes') : i18n.t('misc.no'),
        monEnabled:
          el.monEnabled === 1 ? i18n.t('misc.yes') : i18n.t('misc.no'),
      }));

      return corpCache;
    },
  },
  async mounted() {
    this.loadingList = true;
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);

    // Marca en el chekbox el cambio de corpo a travez de la cookie, por ahora no lo usamos
    if (this.user.level === 1) {
      this.checkbox = this.corpList.filter((item) => {
        return item.id === this.user.corpId;
      });
      const cookie = getCookie('selectedCorpId');

      this.checkbox = [
        this.corpList.find((corp) => corp.id == parseInt(cookie || '0')),
      ];
    }
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CopyButton,
    CSV,
    Top,
    CorpDetail,
  },

  methods: {
    ...mapActions({
      getCorpList: 'crudService/getList',
      deleteCorp: 'crudService/deleteObject',
    }),
    headers() {
      return [
        {
          text: i18n.t('misc.actions'),
          value: 'actions',
          sortable: false,
          width: '100px',
        },
        {
          text: 'ID',
          value: 'id',
          width: '70px',
        },
        {
          text: i18n.t('misc.name'),
          value: 'name',
        },
        {
          text: i18n.t('misc.enabled'),
          value: 'enabled',
        },
        {
          text: i18n.t('corp.extLen'),
          value: 'extLen',
        },
        {
          text: i18n.t('corp.extMax'),
          value: 'extMax',
        },
        {
          text: i18n.t('corp.queueMax'),
          value: 'queMax',
        },
        {
          text: i18n.t('corp.monEnabled'),
          value: 'monEnabled',
        },
        {
          text: i18n.t('corp.allowCustom'),
          value: 'allowCustom',
        },
        {
          text: i18n.t('misc.maxContacts'),
          value: 'maxContacts',
        },
        {
          text: i18n.t('misc.user'),
          value: 'usersInCorp',
        },
      ];
    },
    csvHeaders() {
      return [
        {
          text: i18n.t('misc.actions'),
          value: 'actions',
          sortable: false,
          width: '100px',
        },
        {
          text: 'ID',
          value: 'id',
          width: '70px',
        },
        {
          text: i18n.t('misc.name'),
          value: 'name',
        },
        {
          text: i18n.t('misc.enabled'),
          value: 'enabled',
        },
        {
          text: i18n.t('corp.extLen'),
          value: 'extLen',
        },
        {
          text: i18n.t('corp.extMax'),
          value: 'extMax',
        },
        {
          text: i18n.t('corp.queueMax'),
          value: 'queMax',
        },
        {
          text: i18n.t('corp.monEnabled'),
          value: 'monEnabled',
        },
        {
          text: i18n.t('corp.monPath'),
          value: 'monPath',
        },
        {
          text: i18n.t('corp.allowCustom'),
          value: 'allowCustom',
        },
        {
          text: i18n.t('misc.maxContacts'),
          value: 'maxContacts',
        },
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.CORP);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    editItem(item: Corp) {
      this.navigateTo(item);
    },

    deleteItem(item: Corp) {
      this.selectedCorp = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteCorp({
        action: action.CORP_CONTROLLER,
        id: this.selectedCorp?.id,
      });
      
      // This set if the form must persist or don't
      this.dialogDelete = response >= 400;

      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    handleCorpSelect(e: any) {
      // If selected
      if (e.value) {
        setCookie('selectedCorpId', e.item.id.toString(), 1);
        setCookie('selectedCorpName', e.item.name.toString(), 1);

        this.$router.replace({ name: 'home' });
      }

      this.$store.dispatch('lookAndFeel/checkLookandfeel');
    },
    navigateTo(corp: any = false) {
      if (corp === 'users') {
        this.$router.replace({
          name: 'user',
        });
      } else {
        if (corp) {
          this.$router.replace({
            name: 'corp/update',
            params: { corpToUpdate: corp },
          });
        } else {
          this.$router.replace({
            name: 'corp/new',
          });
        }
      }
    },

    async refreshList() {
      this.loadingList = true;
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);

      // Marca en el chekbox el cambio de corpo a travez de la cookie, por ahora no lo usamos
      if (this.user.level === 1) {
        this.checkbox = this.corpList.filter((item) => {
          return item.id === this.user.corpId;
        });
        const cookie = getCookie('selectedCorpId');

        this.checkbox = [
          this.corpList.find((corp) => corp.id == parseInt(cookie || '0')),
        ];
      }
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
