import i18n from "@/plugins/i18n";

export default function msgCodes(response: number, isNew?: boolean): any {
  switch (response) {

    // It´s easier catch errors here than chatch them in every component.
    case 0:
      return {
        alertMessage: i18n.t('misc.defaultError'),
        alertType: "error"
      }
    case undefined:
      return {
        alertMessage: i18n.t('misc.defaultError'),
        alertType: "error"
      }
    case null:
      return {
        alertMessage: i18n.t('misc.defaultError'),
        alertType: "error"
      }
    // Studied cases.
    case 400:
      return {
        alertMessage: i18n.t('misc.defaultError'),
        alertType: "error"
      }
    case 405:
      return {
        alertMessage: '405 Method not allowed, please contact support',
        alertType: "error"
      }
    case 406:
      return {
        alertMessage: i18n.t('misc.dataExist'),
        alertType: "error"
      }
    case 500:
      return {
        alertMessage: i18n.t('misc.defaultError'),
        alertType: "error"
      }
    case 520:
      return {
        alertMessage: i18n.t("statusCodes.inTrunk"),
        alertType: "error"
      }
    case 521:
      return {
        alertMessage: i18n.t("statusCodes.inQueue"),
        alertType: "error"
      }
    case 522:
      return {
        alertMessage: i18n.t("statusCodes.inTime"),
        alertType: "error"
      }
    case 523:
      return {
        alertMessage: i18n.t("statusCodes.inIvr"),
        alertType: "error"
      }
    case 525:
      return {
        alertMessage: i18n.t("statusCodes.inSipRoute"),
        alertType: "error"
      }
    case 530:
      return {
        alertMessage: i18n.t('statusCodes.inCorp'),
        alertType: "error"
      }
    case 540:
      return {
        alertMessage: i18n.t("statusCodes.urlAlredyUsed"),
        alertType: "error"
      }
    case 550:
      return {
        alertMessage: i18n.t("statusCodes.maxExten"),
        alertType: "error"
      }
    case 551:
      return {
        alertMessage: i18n.t("statusCodes.extenExist"),
        alertType: "error"
      }   
    


    // Success.
    default:
      return {
        alertMessage: isNew ? i18n.t('misc.created') : i18n.t('misc.updated') as string + i18n.t('misc.defaultSuccess') as string,
        alertType: "green darken-1"
      }
  }
}
