import { render, staticRenderFns } from "./NavGroup.vue?vue&type=template&id=5a38dd1d&scoped=true&"
import script from "./NavGroup.vue?vue&type=script&lang=ts&"
export * from "./NavGroup.vue?vue&type=script&lang=ts&"
import style0 from "./NavGroup.vue?vue&type=style&index=0&id=5a38dd1d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a38dd1d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VIcon,VListGroup,VListItemIcon,VListItemSubtitle,VVirtualScroll})
