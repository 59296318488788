









































import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

import * as action from "../../../store/actions"
import { tokenHeader } from "../../../services/auth";

export default Vue.extend({
  name: "ResumeChart",
  props: ["queueName", "body", "queue"],

  components: {
    apexchart: VueApexCharts
  },

  data() {
    return {
      dialog: false,
      series: [0, 0],
      chartOptions: {
        chart: {
          type: 'pie',
        },
        labels: [this.$i18n.t("corpPanel.SAnswer"), this.$i18n.t("corpPanel.SNoanswer")],
      }
    }
  },

  watch: {
    body() {
      this.getData();
    }
  },

  methods: {
    async getData() {
      const headers = tokenHeader();
      this.body.queue = this.queue.split("_")[1];

      await axios
        .post(action.GET_LIST_STATISTICS_QUEUE, this.body, { withCredentials: true, headers })
        .then((res) => {
          let attended = 0;
          let neglected = 0;
          
          let attendedCalls = res.data.sP2.filter((e: any) => e.estado == "Atendidas");
          if (attendedCalls.length) attended = attendedCalls[0].callcount;

          let neglectedCalls = res.data.sP2.filter((e: any) => e.estado == "Desatendidas");
          if (neglectedCalls.length) neglected =  neglectedCalls[0].callcount;
          
          this.series = [ attended, neglected ];
          this.$emit("addTotals", attended, neglected, res.data.sP2[0].queue)
        })
        .catch(() => {
          alert("GET_LIST_STATISTICS_QUEUE is not working");
        });
    }
  },

  async mounted() {
    await this.getData();
  }
    
});

